import React from "react";
import { useNavigate } from "react-router-dom";

import "../styles/header.css";
import logo from "../img/psiquedata_logo_trans.png";
import img_avatar from "../img/avatar.png";


const Header = () => {

  const navigate = useNavigate();
  const cerrarSesion = () => {
    navigate("/");
  };

  return (
    <div className="div-header">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="navbar-left">
        <ul className="nav-links">
          <li>
            <i className="material-symbols-outlined">calendar_month</i>
            <a href="/calendario">Agenda</a>
          </li>
          <li>
            <i className="material-symbols-outlined">patient_list</i>
            <a href="/pacientes">Pacientes</a>
          </li>
          <li>
            <i className="material-symbols-outlined">pending_actions</i>
            <a href="/pendientes">Pendientes</a>
          </li>
          <li>
            <i className="material-symbols-outlined">admin_panel_settings</i>
            <a href="/administracion">Administración</a>
          </li>
          <li>
            <i className="material-symbols-outlined">exit_to_app</i>
            <a href="/"> Salir </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;