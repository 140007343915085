
import React, { useEffect, useState } from "react";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import { useParams, useNavigate } from "react-router-dom";

import PsicologoService from "../../services/PsicologoService";
import PacientesService from "../../services/PacientesService";

const AddUsuarioLoginPx = () => {

    const routeParams = useParams();
    const navigate = useNavigate();

    const [psicologos, setPsicologos] = useState([]);
    const [psicologo, setPsicologo] = useState('');
    const [nombre, setNombre] = useState('');
    const [ap1, setAp1] = useState('');
    const [ap2, setAp2] = useState('');
    const [edad, setEdad] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [tel, setTel] = useState('');
    const [correo, setCorreo] = useState('');
    const [contrasena, setContrasena] = useState('');
    const [rol, setRol] = useState('');
    const [estatus, setEstatus] = useState('');
    const [nacion, setNacion] = useState('');


    const getData = async () => {
        const data_psi = await PsicologoService.getPsicologos();
        console.log('data_psi: ' + data_psi);
        setPsicologos(data_psi);
    }

    useEffect((
    ) => {
        getData();
    }, []);

    const handleNacion = (event) => {
        setNacion(event.target.value);
    };

    const DisplayDataNacion = () => {
        console.log("jeje")
        if(nacion === 'mexicana'){
        /*  SÍ ES MEXICANO */
        return (
            <td>
                <tr className="add-px-tr">
                    <td className="add-px-colum" colspan="2">
                        <label className="label-ciudad" htmlFor="input-ciudad">Ciudad:</label>
                    </td>
                    <td className="add-px-colum">
                        <label className="label-edo" htmlFor="select-edo">Estado:</label>
                    </td>
                </tr>
                <tr>
                    <td className="add-px-colum" colspan="2">
                        <input type="text" id="input-ciudad" placeholder="Ciudad, localidad..."></input>
                    </td>
                    <td className="add-px-colum">
                        <select id="select-edo">
                            <option value="a">Aguascalientes</option>
                            <option value="b">Baja California</option>
                            <option value="bs">Baja California Sur</option>
                            <option value="c">Campeche</option>
                            <option value="ch">Chiapas</option>
                            <option value="chi">Chihuahua</option>
                            <option value="co">Coahuila</option>
                            <option value="col">Colima</option>
                            <option value="cd">Ciudad de México</option>
                            <option value="d">Durango</option>
                            <option value="em">Estado de México</option>
                            <option value="g">Guanajuato</option>
                            <option value="gu">Guerrero</option>
                            <option value="h">Hidalgo</option>
                            <option value="j">Jalisco</option>
                            <option value="m" selected>Michoacán</option>
                            <option value="mo">Morelos</option>
                            <option value="n">Nayarit</option>
                            <option value="nl">Nuevo León</option>
                            <option value="o">Oaxaca</option>
                            <option value="p">Puebla</option>
                            <option value="q">Querétaro</option>
                            <option value="qr">Quintana Roo</option>
                            <option value="sn">San Luis Potosí</option>
                            <option value="s">Sinaloa</option>
                            <option value="so">Sonora</option>
                            <option value="t">Tabasco</option>
                            <option value="ta">Tamaulipas</option>
                            <option value="tl">Tlaxcala</option>
                            <option value="v">Veracruz</option>
                            <option value="y">Yucatán</option>
                            <option value="z">Zacatecas</option>
                        </select>
                    </td>
                </tr>
            </td>
        )
    } else if(nacion === 'extranjera'){
        /*  SÍ ES EXTRANJERO   */
        return (
            <td>
                <tr className="add-px-tr">
                    <td className="add-px-colum" colspan="2">
                        <label className="label-ciudad" htmlFor="input-ciudad">Ciudad:</label>
                    </td>
                    <td className="add-px-colum">
                        <label className="label-edo" htmlFor="select-edo">Estado:</label>
                    </td>
                </tr>
                <tr>
                    <td className="add-px-colum" colspan="2">
                        <input type="text" id="input-ciudad" placeholder="Ciudad, localidad..."></input>
                    </td>
                    <td className="add-px-colum">
                        <input type="text" id="input-estado" placeholder="Estado..."></input>
                    </td>
                </tr>
                <tr>
                    <td className="add-px-colum">
                        <label className="label-pais" htmlFor="select-pais">País:</label>
                    </td>
                </tr>
                <tr>
                    <td className="add-px-colum">
                        <select name="pais">
                            <option value="AF">Afganistán</option>
                            <option value="AL">Albania</option>
                            <option value="DE">Alemania</option>
                            <option value="AD">Andorra</option>
                            <option value="AO">Angola</option>
                            <option value="AI">Anguilla</option>
                            <option value="AQ">Antártida</option>
                            <option value="AG">Antigua y Barbuda</option>
                            <option value="AN">Antillas Holandesas</option>
                            <option value="SA">Arabia Saudí</option>
                            <option value="DZ">Argelia</option>
                            <option value="AR">Argentina</option>
                            <option value="AM">Armenia</option>
                            <option value="AW">Aruba</option>
                            <option value="AU">Australia</option>
                            <option value="AT">Austria</option>
                            <option value="AZ">Azerbaiyán</option>
                            <option value="BS">Bahamas</option>
                            <option value="BH">Bahrein</option>
                            <option value="BD">Bangladesh</option>
                            <option value="BB">Barbados</option>
                            <option value="BE">Bélgica</option>
                            <option value="BZ">Belice</option>
                            <option value="BJ">Benin</option>
                            <option value="BM">Bermudas</option>
                            <option value="BY">Bielorrusia</option>
                            <option value="MM">Birmania</option>
                            <option value="BO">Bolivia</option>
                            <option value="BA">Bosnia y Herzegovina</option>
                            <option value="BW">Botswana</option>
                            <option value="BR">Brasil</option>
                            <option value="BN">Brunei</option>
                            <option value="BG">Bulgaria</option>
                            <option value="BF">Burkina Faso</option>
                            <option value="BI">Burundi</option>
                            <option value="BT">Bután</option>
                            <option value="CV">Cabo Verde</option>
                            <option value="KH">Camboya</option>
                            <option value="CM">Camerún</option>
                            <option value="CA">Canadá</option>
                            <option value="TD">Chad</option>
                            <option value="CL">Chile</option>
                            <option value="CN">China</option>
                            <option value="CY">Chipre</option>
                            <option value="VA">Ciudad del Vaticano (Santa Sede)</option>
                            <option value="CO">Colombia</option>
                            <option value="KM">Comores</option>
                            <option value="CG">Congo</option>
                            <option value="CD">Congo, República Democrática del</option>
                            <option value="KR">Corea</option>
                            <option value="KP">Corea del Norte</option>
                            <option value="CI">Costa de Marfíl</option>
                            <option value="CR">Costa Rica</option>
                            <option value="HR">Croacia (Hrvatska)</option>
                            <option value="CU">Cuba</option>
                            <option value="DK">Dinamarca</option>
                            <option value="DJ">Djibouti</option>
                            <option value="DM">Dominica</option>
                            <option value="EC">Ecuador</option>
                            <option value="EG">Egipto</option>
                            <option value="SV">El Salvador</option>
                            <option value="AE">Emiratos Árabes Unidos</option>
                            <option value="ER">Eritrea</option>
                            <option value="SI">Eslovenia</option>
                            <option value="ES">España</option>
                            <option value="US" selected>Estados Unidos</option>
                            <option value="EE">Estonia</option>
                            <option value="ET">Etiopía</option>
                            <option value="FJ">Fiji</option>
                            <option value="PH">Filipinas</option>
                            <option value="FI">Finlandia</option>
                            <option value="FR">Francia</option>
                            <option value="GA">Gabón</option>
                            <option value="GM">Gambia</option>
                            <option value="GE">Georgia</option>
                            <option value="GH">Ghana</option>
                            <option value="GI">Gibraltar</option>
                            <option value="GD">Granada</option>
                            <option value="GR">Grecia</option>
                            <option value="GL">Groenlandia</option>
                            <option value="GP">Guadalupe</option>
                            <option value="GU">Guam</option>
                            <option value="GT">Guatemala</option>
                            <option value="GY">Guayana</option>
                            <option value="GF">Guayana Francesa</option>
                            <option value="GN">Guinea</option>
                            <option value="GQ">Guinea Ecuatorial</option>
                            <option value="GW">Guinea-Bissau</option>
                            <option value="HT">Haití</option>
                            <option value="HN">Honduras</option>
                            <option value="HU">Hungría</option>
                            <option value="IN">India</option>
                            <option value="ID">Indonesia</option>
                            <option value="IQ">Irak</option>
                            <option value="IR">Irán</option>
                            <option value="IE">Irlanda</option>
                            <option value="BV">Isla Bouvet</option>
                            <option value="CX">Isla de Christmas</option>
                            <option value="IS">Islandia</option>
                            <option value="KY">Islas Caimán</option>
                            <option value="CK">Islas Cook</option>
                            <option value="CC">Islas de Cocos o Keeling</option>
                            <option value="FO">Islas Faroe</option>
                            <option value="HM">Islas Heard y McDonald</option>
                            <option value="FK">Islas Malvinas</option>
                            <option value="MP">Islas Marianas del Norte</option>
                            <option value="MH">Islas Marshall</option>
                            <option value="UM">Islas menores de Estados Unidos</option>
                            <option value="PW">Islas Palau</option>
                            <option value="SB">Islas Salomón</option>
                            <option value="SJ">Islas Svalbard y Jan Mayen</option>
                            <option value="TK">Islas Tokelau</option>
                            <option value="TC">Islas Turks y Caicos</option>
                            <option value="VI">Islas Vírgenes (EEUU)</option>
                            <option value="VG">Islas Vírgenes (Reino Unido)</option>
                            <option value="WF">Islas Wallis y Futuna</option>
                            <option value="IL">Israel</option>
                            <option value="IT">Italia</option>
                            <option value="JM">Jamaica</option>
                            <option value="JP">Japón</option>
                            <option value="JO">Jordania</option>
                            <option value="KZ">Kazajistán</option>
                            <option value="KE">Kenia</option>
                            <option value="KG">Kirguizistán</option>
                            <option value="KI">Kiribati</option>
                            <option value="KW">Kuwait</option>
                            <option value="LA">Laos</option>
                            <option value="LS">Lesotho</option>
                            <option value="LV">Letonia</option>
                            <option value="LB">Líbano</option>
                            <option value="LR">Liberia</option>
                            <option value="LY">Libia</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="LT">Lituania</option>
                            <option value="LU">Luxemburgo</option>
                            <option value="MK">Macedonia, Ex-República Yugoslava de</option>
                            <option value="MG">Madagascar</option>
                            <option value="MY">Malasia</option>
                            <option value="MW">Malawi</option>
                            <option value="MV">Maldivas</option>
                            <option value="ML">Malí</option>
                            <option value="MT">Malta</option>
                            <option value="MA">Marruecos</option>
                            <option value="MQ">Martinica</option>
                            <option value="MU">Mauricio</option>
                            <option value="MR">Mauritania</option>
                            <option value="YT">Mayotte</option>
                            <option value="MX">México</option>
                            <option value="FM">Micronesia</option>
                            <option value="MD">Moldavia</option>
                            <option value="MC">Mónaco</option>
                            <option value="MN">Mongolia</option>
                            <option value="MS">Montserrat</option>
                            <option value="MZ">Mozambique</option>
                            <option value="NA">Namibia</option>
                            <option value="NR">Nauru</option>
                            <option value="NP">Nepal</option>
                            <option value="NI">Nicaragua</option>
                            <option value="NE">Níger</option>
                            <option value="NG">Nigeria</option>
                            <option value="NU">Niue</option>
                            <option value="NF">Norfolk</option>
                            <option value="NO">Noruega</option>
                            <option value="NC">Nueva Caledonia</option>
                            <option value="NZ">Nueva Zelanda</option>
                            <option value="OM">Omán</option>
                            <option value="NL">Países Bajos</option>
                            <option value="PA">Panamá</option>
                            <option value="PG">Papúa Nueva Guinea</option>
                            <option value="PK">Paquistán</option>
                            <option value="PY">Paraguay</option>
                            <option value="PE">Perú</option>
                            <option value="PN">Pitcairn</option>
                            <option value="PF">Polinesia Francesa</option>
                            <option value="PL">Polonia</option>
                            <option value="PT">Portugal</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="QA">Qatar</option>
                            <option value="UK">Reino Unido</option>
                            <option value="CF">República Centroafricana</option>
                            <option value="CZ">República Checa</option>
                            <option value="ZA">República de Sudáfrica</option>
                            <option value="DO">República Dominicana</option>
                            <option value="SK">República Eslovaca</option>
                            <option value="RE">Reunión</option>
                            <option value="RW">Ruanda</option>
                            <option value="RO">Rumania</option>
                            <option value="RU">Rusia</option>
                            <option value="EH">Sahara Occidental</option>
                            <option value="KN">Saint Kitts y Nevis</option>
                            <option value="WS">Samoa</option>
                            <option value="AS">Samoa Americana</option>
                            <option value="SM">San Marino</option>
                            <option value="VC">San Vicente y Granadinas</option>
                            <option value="SH">Santa Helena</option>
                            <option value="LC">Santa Lucía</option>
                            <option value="ST">Santo Tomé y Príncipe</option>
                            <option value="SN">Senegal</option>
                            <option value="SC">Seychelles</option>
                            <option value="SL">Sierra Leona</option>
                            <option value="SG">Singapur</option>
                            <option value="SY">Siria</option>
                            <option value="SO">Somalia</option>
                            <option value="LK">Sri Lanka</option>
                            <option value="PM">St Pierre y Miquelon</option>
                            <option value="SZ">Suazilandia</option>
                            <option value="SD">Sudán</option>
                            <option value="SE">Suecia</option>
                            <option value="CH">Suiza</option>
                            <option value="SR">Surinam</option>
                            <option value="TH">Tailandia</option>
                            <option value="TW">Taiwán</option>
                            <option value="TZ">Tanzania</option>
                            <option value="TJ">Tayikistán</option>
                            <option value="TF">Territorios franceses del Sur</option>
                            <option value="TP">Timor Oriental</option>
                            <option value="TG">Togo</option>
                            <option value="TO">Tonga</option>
                            <option value="TT">Trinidad y Tobago</option>
                            <option value="TN">Túnez</option>
                            <option value="TM">Turkmenistán</option>
                            <option value="TR">Turquía</option>
                            <option value="TV">Tuvalu</option>
                            <option value="UA">Ucrania</option>
                            <option value="UG">Uganda</option>
                            <option value="UY">Uruguay</option>
                            <option value="UZ">Uzbekistán</option>
                            <option value="VU">Vanuatu</option>
                            <option value="VE">Venezuela</option>
                            <option value="VN">Vietnam</option>
                            <option value="YE">Yemen</option>
                            <option value="YU">Yugoslavia</option>
                            <option value="ZM">Zambia</option>
                            <option value="ZW">Zimbabue</option>
                        </select>
                    </td>
                </tr>
            </td>
        )
    }
    };


    /* Datos Usuario */

    const handlePsicologo = (event) => {
        setPsicologo(event.target.value);
    }
    const handleNombre = (event) => {
        setNombre(event.target.value);
    }
    const handleAp1 = (event) => {
        setAp1(event.target.value);
    }
    const handleAp2 = (event) => {
        setAp2(event.target.value);
    }
    const handleEdad = (event) => {
        setEdad(event.target.value);
    }
    const handleCiudad = (event) => {
        setCiudad(event.target.value);
    }
    const handleTel = (event) => {
        setTel(event.target.value);
    }
    const handleCorreo = (event) => {
        setCorreo(event.target.value);
    }
    const handleContrasena = (event) => {
        setContrasena(event.target.value);
    }
    const handleRol = (event) => {
        setRol(event.target.value);
    }
    const handleEstatus = (event) => {
        setEstatus(event.target.value);
    }


    const handleSubmit = async () => {
        let usuario = {
            nombre: nombre,
            ap1: ap1,
            ap2: ap2,
            edad: edad,
            ciudad: ciudad,
            tel: tel,
            correo: correo,
            contrasena: contrasena,
            rol: rol,
            estatus: estatus,
        }
        let paciente = {
            fk_psicologo: parseInt(psicologo),
        }
    }


    return (
        <Layout>
            <Content>
                <div className="div-datos-addPx">
                    <div className="div-addPx-header">
                        <h1>Bienvenido: ingresa tus datos</h1>
                        <br></br>
                        <p>* Es importante que todos los campos estén contestados.</p>
                        <br></br>
                    </div>
                    <div className="div-table-addPx">
                        <table className="table-addPx">
                            <tr>
                                <td className="add-px-colum">
                                    <label className="label-psicologo" htmlFor="select-psicologo">Psicólogo:</label>
                                </td>
                                <td className="add-px-colum">
                                    <select id="select-psicologo" onChange={handlePsicologo}>
                                        <option disabled selected> Elige un psicólogo </option>
                                        {psicologos.map((psicologo) => {
                                            <option value={psicologo.id_psicologo}>{psicologo.nombre} {psicologo.ap1} {psicologo.ap2}</option>
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="add-px-colum">
                                    <label className="label-nom-usu" htmlFor="input-nom-usu">Usuario:</label>
                                </td>
                                <td className="add-px-colum" colspan="2">
                                    <input type="text" id="input-nom-usu" placeholder="Nombre de usuario"></input>
                                </td>
                            </tr>
                            <tr className="add-px-tr">
                                <td className="add-px-colum" colspan="2">
                                    <label className="label-correo" htmlFor="input-correo">Correo:</label>
                                </td>
                                <td className="add-px-colum">
                                    <label className="label-contrasena" htmlFor="input-contrasena">Contraseña:</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="add-px-colum" colspan="2">
                                    <input type="email" id="input-correo" placeholder="Correo electrónico"></input>
                                </td>
                                <td className="add-px-colum">
                                    <input type="pass" id="input-contrasena" placeholder="Contraseña"></input>
                                </td>
                            </tr>
                            <tr className="add-px-tr">
                                <td className="add-px-colum" colspan="3">
                                    <label className="label-paciente" htmlFor="input-nombre">Paciente:</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="add-px-colum">
                                    <input type="text" id="input-nombre" placeholder="Nombre"></input>
                                </td>
                                <td className="add-px-colum">
                                    <input type="text" id="input-ap1" placeholder="Apellido paterno"></input>
                                </td>
                                <td className="add-px-colum">
                                    <input type="text" id="input-ap2" placeholder="Apellido materno"></input>
                                </td>
                            </tr>
                            <tr className="add-px-tr">
                                <td className="add-px-colum">
                                    <label className="label-edad" htmlFor="input-edad">Edad:</label>
                                </td>
                                <td className="add-px-colum">
                                    <label className="label-tel" htmlFor="input-tel">Teléfono:</label>
                                </td>
                                <td className="add-px-colum">
                                    <label className="label-sexo" htmlFor="input-sexo">Sexo:</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="add-px-colum">
                                    <input type="number" id="input-edad"></input>
                                </td>
                                <td className="add-px-colum">
                                    <input type="tel" id="input-tel"></input>
                                </td>
                                <td className="add-px-colum">
                                    <select id="select-sexo">
                                        <option value="elige" selected>Elige una opción</option>
                                        <option value="m">Masculino</option>
                                        <option value="f">Femenino</option>
                                    </select>
                                </td>
                            </tr>
                            <tr className="add-px-tr">
                                <td className="add-px-colum" colspan="3">
                                    <label className="label-ciudad" htmlFor="input-ciudad">Nacionalidad:</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="add-px-colum" colspan="3">
                                    <div class="radio-container">
                                        <label for="mexicano">
                                            <input type="radio" id="mexicano" name="nacionalidad" value="mexicana" onChange={handleNacion}/> Mexicana
                                        </label>
                                        <label for="extranjero">
                                            <input type="radio" id="extranjero" name="nacionalidad" value="extranjera" onChange={handleNacion} /> Extranjera
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {DisplayDataNacion()}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="div-autoregistro-botones">
                        <div className="div-addPx-botones">
                            <div className="addPx-btn-enviar">
                                <button value="enviar" id="btn-enviar-addPx">Enviar</button>
                            </div>
                            <div className="addPx-btn-cancelar">
                                <button value="cancelar" id="btn-cancelar-addPx">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default AddUsuarioLoginPx;