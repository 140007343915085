import React from "react";
import { useNavigate } from "react-router-dom";

import "../styles/Footer.css";
import logo from "../img/psiquedata_logo_trans.png";
import img_blog from "../img/sticky_note.svg";
import img_video from "../img/play_circle.svg";
import img_person from "../img/person.svg";


const Footer = () => {

  const navigate = useNavigate();

  const cerrarSesion = () => {
    navigate("/");
  };

  return (
    <div className="div-footer">
      <div className="lista_footer">
        <ul className="links_footer">
          <li><a href="#"><img src={img_person} alt="img_person" /> ¿Quién soy? </a></li>
          <li><a href="#"><img src={img_blog} alt="img_blog" /> Blog </a></li>
          <li><a href="#"><img src={img_video} alt="img_video" /> Videos </a></li>
        </ul>
      </div>
      <div className="div-footer-lbl">
        <label className="footer-lbl-contacto">
          Consultorio psicológico.
          Fray Bartolomé de las Casas, # 15. Santiago Tangamandapio, Mich.
          CP 59920
        </label>
      </div>
    </div>
  );
};

export default Footer;