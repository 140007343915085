
import axios from "axios";

const EXPEDIENTES_API_BASE_URL = "/server/expedientes";
const VEXPEDIENTES_API_BASE_URL = "/server/vexpedientes";

// const EXPEDIENTES_API_BASE_URL = "http://localhost:3002/expedientes";
// const VEXPEDIENTES_API_BASE_URL = "http://localhost:3002/vexpedientes";


class ExpedientesService {

    async getExpediente(id_paciente) {
        const response = await axios.get(`${VEXPEDIENTES_API_BASE_URL}Individual?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('ES getExpediente: ', error);
            });
        const expediente = response.data;
        console.log("getExpediente ES -------");
        console.log(expediente);
        return expediente;
    }

    editarExpediente(expediente) {
        return axios.put(EXPEDIENTES_API_BASE_URL, expediente);
    }

    async createExpediente(expediente) {
        return await axios.post(EXPEDIENTES_API_BASE_URL, expediente);
    }

    async getUltimoExpediente() {
        const response = await axios.get(EXPEDIENTES_API_BASE_URL);
        const expedientes = response.data;
        const ultimoExpediente = expedientes.slice(-1)[0];
        return ultimoExpediente;
    }

}

export default new ExpedientesService();