import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { parse, format } from 'date-fns';
import { useNavigate } from "react-router-dom";

import Layout from "../../containers/Layout";
import Content from "../../components/Content";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import listPlugin from '@fullcalendar/list';

import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";
import CitasService from "../../services/CitasService";

import "../../styles/Pacientes.css";
import "../../styles/Calendario.css";
import "../../styles/Administracion.css";

import img_edit from "../../img/edit_note.svg";
import img_delete from "../../img/delete.svg";
import img_clear from "../../img/clear_all.svg";
import img_photo from "../../img/photo_camera.svg";
import img_avatar from "../../img/avatar.png";
import img_feed from "../../img/feed.svg";
import img_feed_black from "../../img/feed_black.svg";
import img_person from "../../img/person_add.svg";
import img_note from "../../img/note_add.svg";


const Administracion = () => {
    const [pacientes, setPacientes] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [selectedValue, setSelectedValue] = useState(""); // Estado para el valor del select
    const [inputValue, setInputValue] = useState("");

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);

    function refreshPage() {
        window.location.reload(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    const navigate = useNavigate();

    const getData = async () => {
        const data = await PacientesService.getPacientes();
        console.log(data);
        setPacientes(data);
        //document.getElementById().click();
    };

    useEffect(() => {
        getData();
    }, [])

    const cargarInformacion = async (id_paciente) => {
        const data = await PacientesService.getInfoPaciente(id_paciente);
        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }
        setInformacion(data[0]);
        console.log(data[0]);
    };

    const DisplayData = pacientes.map((info) => {
        return (
            <tr className="table-line-bottom">
                <td id="td_1">
                    <button onClick={() => cargarInformacion(info.id_paciente)} id="liga_px">
                        {info.nombre} {info.ap1} {info.ap2}
                    </button>
                </td>

                {/* entorno local   
                <td id="td_2">
                    <a href={`/editpaciente/${info.id_paciente}`}>
                        <img id="img_edit" src={img_edit} alt="edit_px" />
                    </a>
                </td>
                <td id="td_3">
                    <a href={`/eliminar/${info.id_paciente}`}>
                        <img id="img_exped" src={img_delete} alt="img_exped"></img>
                    </a>
                </td>
                <td id="td_4">
                    <a href={`/informe/${info.id_paciente}`}>
                        <img id="img_informe" src={img_feed_black} alt="img_informe"></img>
                    </a>
                </td>
                */}

                {/* entorno web   */}
                <td id="td_2">
                    <a href={`#/editpaciente/${info.id_paciente}`}>
                        <img id="img_edit" src={img_edit} alt="edit_px" />
                    </a>
                </td>
                <td id="td_3">
                    <a href={`#/eliminar/${info.id_paciente}`}>
                        <img id="img_exped" src={img_delete} alt="img_exped"></img>
                    </a>
                </td>
                <td id="td_4">
                    <a href={`#/informe/${info.id_paciente}`}>
                        <img id="img_informe" src={img_feed_black} alt="img_informe"></img>
                    </a>
                </td>

            </tr>
        )
    });

    const eliminarPaciente = async () => {
        if (informacion.id_paciente === undefined) {
            Toast.fire("Primero debes seleccionar un paciente", "", "error");
        } else {
            Swal.fire({
                title: "Eliminar cita",
                text: "¿Esta seguro de eliminar el paciente?",
                showCancelButton: true,
                showConfirmButton: false,
                showDenyButton: true,
                denyButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isDenied) {
                    eliminarDatos(informacion.id_paciente);
                }
            });
        }
    };

    const eliminarDatos = async (id_paciente) => {
        const respuesta = await PacientesService.eliminarPacienteL(id_paciente);
        if (respuesta.status === 200) {
            Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    refreshPage();
                }
            });
        } else {
            Toast.fire("Hubo un problema al eliminar la cita!", "", "error");
        }
    };

    const agregarPaciente = () => {
        //navigate('/AddPacienteExpress');        // entorno local
        navigate('/AddPacienteExpress');        // entorno web
    };

    const editarPaciente = () => {
        //navigate(`/editpaciente/${informacion.id_paciente}`);       // entorno local
        navigate(`/editpaciente/${informacion.id_paciente}`);       // entorno web
    }

    const agregarPsicologo = () => {
        //navigate('/AddPsicologo');      // entorno local
        navigate('/AddPsicologo');      // entorno web
    };

    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value);
        console.log(data);
        setPacientes(data);
    };

    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes();
            console.log(data);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value);
            console.log(data);
            setPacientes(data);
        }
    };

    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes();
        console.log(data);
        setPacientes(data);
    };

    /*   entorno local
    const btnCitas = () => {
        navigate('/citas');
    }
    const btnSesiones = () => {
        navigate('/sesiones');
    }
    const btnExpedientes = () => {
        navigate('/expedientes');
    }
    const btnFamiliares = () => {
        navigate('/familiares');
    }
    const btnCanalizaciones = () => {
        navigate('/canalizaciones');
    }
    const btnNotificaciones = () => {
        navigate('/notificaciones');
    }
        */

    /*   entorno web    */
    const btnCitas = () => {
        navigate('/citas');
    }
    const btnSesiones = () => {
        navigate('/sesiones');
    }
    const btnExpedientes = () => {
        navigate('#/expedientes');
    }
    const btnFamiliares = () => {
        navigate('/familiares');
    }
    const btnCanalizaciones = () => {
        navigate('/canalizaciones');
    }
    const btnNotificaciones = () => {
        navigate('/notificaciones');
    }



    return (
        <Layout>
            <Content>
                <div className="px_div_1_admin">
                    <div className="px_div_2_admin">
                        <div className="div_sesion_admin">
                            <div className="px_sesion_admin">
                                <label id="lbl-enSesion">En sesión: </label>
                                <label>{usuario.nombre} {usuario.ap1} {usuario.ap2} </label>
                            </div>
                            <div className="div_lbl_admin">
                                <label>Administración</label>
                            </div>
                        </div>

                        <div className="tablas_administracion">
                            <div className="tabla_item">
                                <div className="label-bottom-admin">
                                    <label className="lbl_item">Pacientes</label>
                                    <button id="px_btn_nuevo_admin" onClick={agregarPaciente}>
                                        <img src={img_person} />
                                        Paciente
                                    </button>
                                </div>
                                <table id="tabla_pacientes">
                                    <tr><td>Pacientes activos:</td>
                                        <td>47</td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Pacientes</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                    </tr>
                                    <tr><td>Pacientes inactivos:</td>
                                        <td>12</td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Pacientes</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                        <td></td>
                                    </tr>
                                    <tr><td>Pacientes canalizados:</td>
                                        <td>16</td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Psicólogo</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Pacientes</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="tabla_item">
                                <div className="label-bottom-admin">
                                    <label className="lbl_item">Psicólogos</label>
                                    <button id="px_btn_nuevo_admin" onClick={agregarPsicologo}>
                                        <img src={img_person} />
                                        Psicólogo
                                    </button>
                                </div>
                                <table id="tabla_psicologos">
                                    <tr><td>Psicologos activos:</td>
                                        <td>4</td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Psicólogos</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                    </tr>
                                    <tr><td>Psicólogos inactivos:</td>
                                        <td>1</td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Psicólogos</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                        <td></td>
                                    </tr>

                                </table>
                            </div>
                            <div className="tabla_item">
                                <div className="label-bottom-admin">
                                    <label className="lbl_item">Colegas</label>
                                    <button id="px_btn_nuevo_admin" onClick={agregarPaciente}>
                                        <img src={img_person} />
                                        Colega
                                    </button>
                                </div>
                                <table id="tabla_colegas">
                                    <tr><td>Colegas:</td>
                                        <td>7</td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Colegas</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="tabla_item">
                                <div className="label-bottom-admin">
                                    <label className="lbl_item">Familiares</label>
                                    <button id="px_btn_nuevo_admin" onClick={agregarPaciente}>
                                        <img src={img_person} />
                                        Familiar
                                    </button>
                                </div>
                                <table id="tabla_familiares">
                                    <tr><td>Familiares:</td>
                                        <td>47</td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Selecciona un filtro</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Paciente</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Familiares</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="tabla_item">
                                <div className="label-bottom-admin">
                                    <label className="lbl_item">Informes</label>
                                    <button id="px_btn_nuevo_admin" onClick={agregarPaciente}>
                                        <img src={img_feed} />
                                        Informe
                                    </button>
                                </div>
                                <table id="tabla_informes">
                                    <tr>
                                        <td>Informes:</td>
                                        <td>37</td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Selecciona un filtro</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Paciente</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                        <td>Imprimir</td>
                                        <td>Familiograma</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="tabla_item">
                                <div className="label-bottom-admin">
                                    <label className="lbl_item">Expedientes</label>
                                    <button id="px_btn_nuevo_admin" onClick={agregarPaciente}>
                                        <img src={img_note} />
                                        Expediente
                                    </button>
                                </div>
                                <table id="tabla_colegas">
                                    <tr><td>Expedientes:</td>
                                        <td>47</td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Selecciona un filtro</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                        <td><select id="px_select_admin" value={selectedValue} onChange={buscarFiltro}>
                                            <option className="px_option" value="" disabled selected >Paciente</option>
                                            <option className="px_option" value="A">Activos</option>
                                            <option className="px_option" value="I">Inactivos</option>
                                        </select></td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div className="px_buscador_admin">
                            <label className="lbl_item">Buscar usuarios:</label>
                            <div className="input_buscar_admin">
                                <select id="px_select_admin-buscar">
                                    <option className="px_option" value="" disabled selected >Tipo de usuario</option>
                                    <option className="px_option" value="px">Paciente</option>
                                    <option className="px_option" value="psi">Psicólogo</option>
                                </select>
                            </div>
                            <div className="px_filtro_admin">
                                <label className="px_lb_filtro">Filtro de usuarios:</label>
                                <select id="px_select_admin-buscar" value={selectedValue} onChange={buscarFiltro}>
                                    <option className="px_option" value="" disabled selected >Selecciona un filtro</option>
                                    <option className="px_option" value="A">Activos</option>
                                    <option className="px_option" value="I">Inactivos</option>
                                </select>
                                <button id="btn-limpiar-filtro" onClick={limpiarFiltro}>
                                    <img src={img_clear} />
                                    Limpiar filtro
                                </button>
                            </div>
                        </div>

                        <div className="px_div_3_admin">
                            <div className="div_px_lista_admin">
                                <div className="div_tablas_th_px">
                                    <table id="encabezado_px_admin">
                                        <tr className="tr-encabezado-px">
                                            <th>Nombre</th>
                                            <th>Editar</th>
                                            <th>Eliminar</th>
                                            <th>Informe</th>
                                        </tr>
                                    </table>
                                    <table id="filas_px">
                                        {DisplayData}
                                    </table>
                                </div>
                            </div>

                            {informacion.length === 0 ? (

                                <div className="div-sinInfo-admin">
                                    <div className="div_foto_perfil">
                                        <div className="div-photo-border-sinDatos">
                                            <img src={img_photo} alt="photo"></img>
                                        </div>
                                    </div>

                                    <div className="px_tabla_admin">
                                        <div id="edit_delete">
                                            <div id="px_h3">
                                                <h3>Usuario</h3>
                                            </div>
                                        </div>
                                        <div className="div-selec-px">
                                            <label id="label-selec-px-px"> Selecciona un usuario de la lista... </label>
                                        </div>
                                    </div>
                                </div>

                            ) : (

                                <div className="div-conInfo-admin">
                                    <div className="div_foto_perfil">
                                        <div className="div-photo-border">
                                            <img src={img_avatar} alt="avatar"></img>
                                        </div>
                                    </div>
                                    <div className="px_seleccionado_tabla_admin">
                                        <div className="px_tabla_admin">
                                            <div id="edit_delete">
                                                <div id="px_h3_admin">
                                                    <h3>Usuario</h3>
                                                </div>
                                                <div id="e-d_label_admin">
                                                    <label><img src={img_edit} alt="edit" id="img_edit" onClick={editarPaciente} /></label>
                                                    <label><img src={img_delete} alt="delete" id="img_delete" onClick={eliminarPaciente} /></label>
                                                </div>
                                            </div>
                                            <table id="table_px_seleccionado">
                                                <tr className="padding-row">
                                                    <td id="td-usuario-admin" colSpan="2"><label> PACIENTE </label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td id="td-paciente-admin" colSpan="2">
                                                        <div className="div-td-paciente-admin">
                                                            <div className="div-lbl-paciente">
                                                                <label id="lbl-paciente">{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                            </div>
                                                            <div id="div-menuHamb-admin">
                                                                <nav className="nav-menuHamb-admin">
                                                                    <input type="checkbox" id="in-menuHamb-admin"></input>
                                                                    <label id="label-menuHamb-admin" for="in-menuHamb-admin"> ☰ </label>
                                                                    <ul id="ul-menuHamb-admin">
                                                                        {/*     entorno local
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                        </li>
                                                                        */}

                                                                        {/*     entorno web     */}
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`#/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`#/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`#/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`#/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`#/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                        </li>

                                                                    </ul>
                                                                </nav>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-familiar" htmlFor="lbl-familiar">Familiares:</label></td>
                                                    {/* entorno local
                                                    <td><label id="lbl-familiar"><a href="/familiares">Ver</a></label></td> 
                                                    */}

                                                    {/* entorno web   */}
                                                    <td><label id="lbl-familiar"><a href="#/familiares">Ver</a></label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-edad" htmlFor="lbl-edad">Edad:</label></td>
                                                    <td><label id="lbl-edad">{informacion.edad}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-sexo" htmlFor="lbl-sexo">Sexo:</label></td>
                                                    <td><label id="lbl-sexo">{informacion.sexo}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-escolaridad" htmlFor="lbl-escolaridad">Escolaridad:</label></td>
                                                    <td><label id="lbl-escolaridad">{informacion.escolaridad}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-profesion" htmlFor="lbl-profesion">Profesion:</label></td>
                                                    <td><label id="lbl-profesion">{informacion.profesion}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-trabajo" htmlFor="lbl-trabajo">Trabajo actual:</label></td>
                                                    <td><label id="lbl-trabajo">{informacion.trabajo_actual}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-pref_sex" htmlFor="lbl-pref_sex">Preferencia sexual:</label></td>
                                                    <td><label id="lbl-pref_sex">{informacion.pref_sexual}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-edo-civil" htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                    <td><label id="lbl-edo-civil">{informacion.edo_civil}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-tel" htmlFor="lbl-tel">Teléfono:</label></td>
                                                    <td><label id="lbl-tel">{informacion.tel}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-correo" htmlFor="lbl-correo">Correo:</label></td>
                                                    <td><label id="lbl-correo">{informacion.correo}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-domicilio" htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                    <td><label id="lbl-domicilio">{informacion.domicilio}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-cp" htmlFor="lbl-cp">Código Postal:</label></td>
                                                    <td><label id="lbl-cp">{informacion.cod_pos}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-edo" htmlFor="lbl-edo">Estado:</label></td>
                                                    <td><label id="lbl-edo">{informacion.edo}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-pais" htmlFor="lbl-pais">País:</label></td>
                                                    <td><label id="lbl-pais">{informacion.pais}</label></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Administracion;