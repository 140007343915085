
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import PacientesService from "../../services/PacientesService";
import UsuariosService from "../../services/UsuariosService";

import "../../styles/FormularioAddPacientesExpress.css";



const AddUsuarioExpress = () => {
    const [nombre, setNombre] = useState('');
    const [ap1, setAp1] = useState('');
    const [ap2, setAp2] = useState('');
    const [edad, setEdad] = useState('');
    const [tel, setTel] = useState('');
    const [sexo, setSexo] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [estado, setEstado] = useState('');
    const [nombreUsuario, setNombreUsuario] = useState('');


    const navigate = useNavigate();
    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })


    /* Datos Usuario */

    const handleNombre = (event) => {
        setNombre(event.target.value);
    }
    const handleAp1 = (event) => {
        setAp1(event.target.value);
    }
    const handleAp2 = (event) => {
        setAp2(event.target.value);
    }
    const handleEdad = (event) => {
        setEdad(event.target.value);
    }
    const handleTel = (event) => {
        setTel(event.target.value);
    }
    const handleSexo = (event) => {
        setSexo(event.target.value);
    }
    const handleCiudad = (event) => {
        setCiudad(event.target.value);
    }
    const handleEstado = (event) => {
        setEstado(event.target.value);
    }


    const onBlurNombre = async () => {
        if (nombreUsuario !== '') {
            const data = await PacientesService.getPacienteNomUsuario(nombreUsuario);
            console.log(data);
            if (data[0] === undefined) {
                setNombreUsuario(nombre + ap1);
            } else {
                setNombreUsuario(nombre + ap1 + data[0].id_paciente);
            }
        } else {
            setNombreUsuario(nombre + ap1);
        }
    };

    const onBlurAp1 = async () => {
        if (nombreUsuario !== '') {
            const data = await PacientesService.getPacienteNomUsuario(nombreUsuario);
            if (data[0] === undefined) {
                setNombreUsuario(nombre + ap1);
            } else {
                setNombreUsuario(nombre + ap1 + data[0].id_paciente)
            }
        } else {
            setNombreUsuario(nombre + ap1);
        }
    }

    const handleSubmit = async () => {
        let user = {
            nombre: nombre,
            ap1: ap1,
            ap2: ap2,
            edad: edad,
            tel: tel,
            sexo: sexo,
            ciudad: ciudad,
            edo: estado,
            nom_usuario: nombreUsuario,
            contrasena: "1234",
            rol: "pax",
            estatus: "A",
        }

        await UsuariosService.createUsuario(user);

        const ultimoUsuario = await UsuariosService.getUltimoUsuario();

        let paciente = {
            fk_usuario: ultimoUsuario.id_usuario,
            fk_psicologo: usuario.id_psicologo
        }

        await PacientesService.createPaciente(paciente);

        Toast.fire("El paciente se registró correctamente!", "", "success").then((result) => {
            if (result.isConfirmed) {
                // navigate('/pacientes');  //local
                navigate('/pacientes');  //web
            }
        }).then(function () {
            // navigate('/pacientes');  //local
            navigate('/pacientes');  //web
        });
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }


    return (
        <Layout>
            <Content>
                <div className="div-datos-express">
                    <div className="px_div_2_express">
                        <div className="div_enSesion_express">
                            <label id="lbl_tipoUsuario_express">{tipoUsuario()} &nbsp; </label>
                            <label id="lbl_enSesion_express">{usuario.nombre}</label>
                        </div>
                        <div className="div_titulo_express">
                            <label id="lbl_titulo_express">Registro de pacientes</label>
                        </div>
                    </div>
                    <div className="px_div_3_express">
                        <label id="lbl-autogen-express">* El nombre de usuario y contraseña serán autogenerados.</label>
                    </div>
                    <div className="div-table-express">
                        <table className="table-express">
                            <tr className="express-px-tr">
                                <td className="express-px-colum" colspan="3">
                                    <label className="label-paciente" htmlFor="input-nombre">Paciente</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="express-px-colum">
                                    <input type="text" id="input-nombre-express" required placeholder="Nombre" onBlur={onBlurNombre} onChange={handleNombre}></input>
                                </td>
                                <td className="express-px-colum">
                                    <input type="text" id="input-ap1-express" required placeholder="Apellido paterno" onBlur={onBlurAp1} onChange={handleAp1}></input>
                                </td>
                                <td className="express-px-colum">
                                    <input type="text" id="input-ap2-express" required placeholder="Apellido materno" onChange={handleAp2}></input>
                                </td>
                            </tr>
                            <tr className="express-px-tr">
                                <td className="express-px-colum"><label className="label-edad" htmlFor="input-edad">Edad</label></td>
                                <td className="express-px-colum"><label className="label-tel" htmlFor="input-tel">Teléfono</label></td>
                                <td className="express-px-colum"><label className="label-sexo" htmlFor="input-sexo">Sexo</label></td>
                            </tr>
                            <tr>
                                <td className="express-px-colum"><input type="number" id="input-edad" onChange={handleEdad}></input></td>
                                <td className="express-px-colum"><input type="tel" id="input-tel" onChange={handleTel} required></input></td>
                                <td className="express-px-colum">
                                    <select id="select-sexo-express" onChange={handleSexo}>
                                        <option value="elige" selected>Elige una opción</option>
                                        <option value="m">Masculino</option>
                                        <option value="f">Femenino</option>
                                    </select>
                                </td>
                            </tr>
                            <tr className="express-px-tr">
                                <td className="express-px-colum" colspan="2"><label className="label-ciudad" htmlFor="input-ciudad">Ciudad</label></td>
                                <td className="express-px-colum"><label className="label-edo" htmlFor="select-edo">Estado</label></td>
                            </tr>
                            <tr>
                                <td className="express-px-colum" colspan="2">
                                    <input type="text" id="input-ciudad" required placeholder="Ciudad, localidad..." onChange={handleCiudad}></input>
                                </td>
                                <td className="express-px-colum">
                                    <select id="select-edo-express" defaultValue="mi" onChange={handleEstado}>
                                        <option value="a">Aguascalientes</option>
                                        <option value="b">Baja California</option>
                                        <option value="bs">Baja California Sur</option>
                                        <option value="c">Campeche</option>
                                        <option value="ch">Chiapas</option>
                                        <option value="chi">Chihuahua</option>
                                        <option value="co">Coahuila</option>
                                        <option value="col">Colima</option>
                                        <option value="cd">Ciudad de México</option>
                                        <option value="d">Durango</option>
                                        <option value="em">Estado de México</option>
                                        <option value="g">Guanajuato</option>
                                        <option value="gu">Guerrero</option>
                                        <option value="h">Hidalgo</option>
                                        <option value="j">Jalisco</option>
                                        <option value="mi">Michoacán</option>
                                        <option value="mo">Morelos</option>
                                        <option value="n">Nayarit</option>
                                        <option value="nl">Nuevo León</option>
                                        <option value="o">Oaxaca</option>
                                        <option value="p">Puebla</option>
                                        <option value="q">Querétaro</option>
                                        <option value="qr">Quintana Roo</option>
                                        <option value="sn">San Luis Potosí</option>
                                        <option value="s">Sinaloa</option>
                                        <option value="so">Sonora</option>
                                        <option value="t">Tabasco</option>
                                        <option value="ta">Tamaulipas</option>
                                        <option value="tl">Tlaxcala</option>
                                        <option value="v">Veracruz</option>
                                        <option value="y">Yucatán</option>
                                        <option value="z">Zacatecas</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="div-autoregistro-botones-express">
                        <div className="div-table-autoregistro">
                            <table className="table-nomUsu-contrasena">
                                <tr>
                                    <td className="express-px-generar">
                                        <label className="label-nom-usu" htmlFor="input-nom-usu">Nombre de usuario:</label>
                                    </td>
                                    <td className="express-px-generar-izq" colspan="2">
                                        <input type="text" id="input-nom-usu" placeholder="Campo autogenerado..." value={nombreUsuario} ></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="express-px-generar">
                                        <label className="label-contrasena" htmlFor="input-contrasena">Contraseña:</label>
                                    </td>
                                    <td className="express-px-generar-izq" colspan="2">
                                        <input type="pass" id="input-contrasena" placeholder="Campo autogenerado..." value={'1234'}></input>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="div-expressPx-botones">
                            <div className="div-express-botones">
                                <div className="div-express-enviar">
                                    <button value="enviar" id="btn-enviar-expressPx" onClick={handleSubmit}>Enviar</button>
                                </div>
                                <div className="div-express-cancelar">
                                    <button value="cancelar" id="btn-cancelar-expressPx" onClick={'/pacientes'}>Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default AddUsuarioExpress;