
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import ColegasService from "../../services/ColegasService";
import CanalizarService from "../../services/CanalizarService";

import "../../styles/Colegas.css";

import Swal from 'sweetalert2';

import img_edit from "../../img/edit_note.svg";
import img_delete from "../../img/delete.svg";


const Colegas = (id) => {
    const [colegas, setColegas] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [sinFoto, setSinFoto] = useState('');
    const [fotografia, setFotografia] = useState('');
    const [estatusCanalizacion, setEstatusCanalizacion] = useState([]);

    const [selectedValue, setSelectedValue] = useState(""); // Estado para el valor del select
    const [inputValue, setInputValue] = useState("");

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    function refreshPage() {
        window.location.reload(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const navigate = useNavigate();

    const getData = async () => {
        if (routeParams.id !== undefined) {
            cargarInformacion(routeParams.id);
            const data = await ColegasService.getColegas(usuario.id_psicologo);
            for (let i = 0; i < data.length; i++) {
                const estatusCan = await CanalizarService.getEstatusCanalizacionesColega(data[i].id_colega);
                if (estatusCan.length !== 0) {
                    data[i].estatusCanalizacion = "A";
                } else {
                    data[i].estatusCanalizacion = "I";
                }
            }
            setColegas(data);
        } else {
            const data = await ColegasService.getColegas(usuario.id_psicologo);
            for (let i = 0; i < data.length; i++) {
                const estatusCan = await CanalizarService.getEstatusCanalizacionesColega(data[i].id_colega);
                if (estatusCan.length !== 0) {
                    data[i].estatusCanalizacion = "A";  // automáticamente se crea el item: data[i].estatusCanalizacion
                } else {
                    data[i].estatusCanalizacion = "I";
                }
            }
            setColegas(data);
        }
    };


    useEffect(() => {
        getData();

    }, []);


    // cargar información del colega
    const cargarInformacion = async (id_colega) => {
        const data = await ColegasService.getInfoColega(id_colega);
        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }
        

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setInformacion(data[0]);
        setFotografia(data[0].fotografia.data)  // get base64 de DB

    };



    const DisplayData = colegas.map((info) => {

        return (
            <tr className="table-line-bottom">
                <td id="td_1">
                    <label id="lbl_nom_px">
                        <button onClick={() => cargarInformacion(info.id_colega)} id="btn_liga_px">
                            {info.nombre} {info.ap1} {info.ap2}
                        </button>
                    </label>
                </td>
                <td>
                    +++
                </td>
                <td>
                    {info.estatusCanalizacion === "A" ? (<label> Sí </label>) : (<label> No </label>)}
                </td>
            </tr>
        )
    });



    const addColega = () => {
        // navigate('/addcolega');     // local
        navigate('/addcolega');     // web
    }

    const editarColega = () => {
        // navigate(`/editcolega/${informacion.id_colega}`);       // local
        navigate(`/editcolega/${informacion.id_colega}`);       // web
    }

    const eliminarColega = async () => {
        if (informacion.id_colega === undefined) {
            Toast.fire("Primero debes seleccionar un colega", "", "error");
        } else {
            Swal.fire({
                title: "Eliminar Colega",
                text: "¿Esta seguro de eliminar al Colega?",
                showCancelButton: true,
                showConfirmButton: false,
                showDenyButton: true,
                denyButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isDenied) {
                    eliminarDatos(informacion.id_colega);
                }
            });
        }
    };

    const eliminarDatos = async (id_colega) => {
        const respuesta = await ColegasService.eliminarColega(id_colega);
        if (respuesta.status === 200) {
            Toast.fire("Se eliminó el colega correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    refreshPage();
                }
            });
        } else {
            Toast.fire("Hubo un problema al eliminar el colega!", "", "error");
        }
    };


    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }


    return (
        <Layout>
            <Content>
                <div className="colega_div_1">
                    <div className="colega_div_2">
                        <div className="colega_sesion">
                            <label id="lbl-tipoUsuario">{tipoUsuario()}</label>
                            &nbsp;
                            <label id="lbl-nomUsuario">{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_colega">
                            <label>Colegas</label>
                        </div>
                    </div>
                    <div className="colega_buscador">

                        <div className="div_btn_addColega">
                            <label>
                                Añadir nuevo colega:
                            </label>
                            <button id="btn-addColega" onClick={addColega}>
                                + Colega
                            </button>
                        </div>
                    </div>

                    <div className="colega_div_3">
                        <div className="div_colega_lista">
                            <div className="div_tablas_th_colega">
                                <table id="table_display_encabezado_colega">
                                    <tbody>
                                        <tr className="tr-encabezado-colega">
                                            <th>Nombre</th>
                                            <th>Activo</th>
                                            <th>¿Atendiendo pacientes?</th>
                                        </tr>
                                    </tbody>
                                </table>
                                <table id="displayData_colega">
                                    <tbody>
                                        {colegas.length !== 0 ? (DisplayData) : (
                                            <tr>
                                                <td>
                                                    <label> No existen colegas en el sistema </label>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="colega_tabla">
                            {informacion.length === 0 ? (
                                <div>
                                    <div id="edit_delete">
                                        <div id="colega_h3">
                                            <label id="lbl_datos_colega">Colegas</label>
                                        </div>
                                    </div>
                                    <div className="div-selec-colega">
                                        <label id="label-selec-colega-colega"> Selecciona un colega de la lista... </label>
                                    </div>
                                </div>
                            ) : (
                                <div className="colega_seleccionado_tabla">
                                    <div id="edit_delete">
                                        <div id="colega_h3">
                                            <label id="lbl_datos_colega">Colega</label>
                                        </div>
                                        <div className="div-colega-toolTip">
                                            <div id="container-toolTip-editar-colega">
                                                <span className="lbl-toolTip-editar-colega">
                                                    Editar
                                                </span>
                                                <img src={img_edit} alt="edit" id="img_edit" onClick={editarColega} />
                                            </div>
                                            <div id="container-toolTip-delete-colega">
                                                <span className="lbl-toolTip-delete-colega">
                                                    Eliminar
                                                </span>
                                                <img src={img_delete} alt="delete" id="img_delete" onClick={eliminarColega} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div-avatar-px">
                                        {
                                            sinFoto === "1" ? (
                                                fotografia === "" ? (
                                                    <div id="img_avatar_px">
                                                        <label> Cargando imagen...</label>
                                                    </div>
                                                ) : (
                                                    <div id="img_avatar_px">
                                                        <img src={fotografia} alt="base64"></img>
                                                    </div>
                                                )
                                            ) : (<div></div>)
                                        }
                                    </div>
                                    <table id="table_colega_seleccionado">
                                        <tbody>
                                            <tr className="padding-row">
                                                <td id="td-paciente" colSpan="2"><label id="lbl-paciente">{informacion.nombre} {informacion.ap1} {informacion.ap2}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-edad" htmlFor="lbl-edad">Edad:</label></td>
                                                <td><label id="lbl-edad">{informacion.edad}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-sexo" htmlFor="lbl-sexo">Sexo:</label></td>
                                                <td><label id="lbl-sexo">{informacion.sexo}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-edo-civil" htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                <td><label id="lbl-edo-civil">{informacion.edo_civil}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-escolaridad" htmlFor="lbl-escolaridad">Escolaridad:</label></td>
                                                <td><label id="lbl-escolaridad">{informacion.escolaridad}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-cedula" htmlFor="lbl-cedula">Cédula:</label></td>
                                                <td><label id="lbl-cedula">{informacion.cedula}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-especialidad" htmlFor="lbl-especialidad">Especialidad:</label></td>
                                                <td><label id="lbl-especialidad">{informacion.especialidad}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-titulo" htmlFor="lbl-titulo">Titulo:</label></td>
                                                <td><label id="lbl-titulo">{informacion.titulo}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-domicilio" htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                <td><label id="lbl-domicilio">{informacion.domicilio}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-ciudad" htmlFor="lbl-ciudad">Ciudad:</label></td>
                                                <td><label id="lbl-ciudad">{informacion.ciudad}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-cp" htmlFor="lbl-cp">Código Postal:</label></td>
                                                <td><label id="lbl-cp">{informacion.cod_pos}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-tel" htmlFor="lbl-tel">Teléfono:</label></td>
                                                <td><label id="lbl-tel">{informacion.tel}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-correo" htmlFor="lbl-correo">Correo:</label></td>
                                                <td><label id="lbl-correo">{informacion.correo}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-edo" htmlFor="lbl-edo">Estado:</label></td>
                                                <td><label id="lbl-edo">{informacion.edo}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-pais" htmlFor="lbl-pais">País:</label></td>
                                                <td><label id="lbl-pais">{informacion.pais}</label></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};


export default Colegas;



