
import React from "react";

import "../../styles/Canalizaciones.css";
import "../../styles/FormularioEditarCita.css";

import img_avatar from "../../img/avatar.png";



const FormularioCanalizarPaciente = ({ paciente, colega, fecha }) => {

  return (
    <div>
      <table className="table-registro-motivo">
        <tr>
          <td id="td-img-canalizar"><img src={img_avatar} alt="avatar" id="img-agendado" /></td>
          <td id="td-pac-canalizar">
            <a href={`#/pacientes`}>
              <label className="lbl-px-canalizar" id="select-paciente">Paciente: {paciente[0].nombre} {paciente[0].ap1} {paciente[0].ap2}</label>
            </a>
          </td>
        </tr>
        <tr>
          <td id="td-img-canalizar"><img src={img_avatar} alt="avatar" id="img-agendado" /></td>
          <td  id="td-pac-canalizar">
            <a href={`#/colegas`}>
              <label className="lbl-px-canalizar" id="select-colega">Colega: {colega[0].nombre} {colega[0].ap1} {colega[0].ap2}</label>
            </a>
          </td>
        </tr>
        <tr>
          <td><label htmlFor="input-motivo"> Motivo: </label></td>
          <td id="td-txt-canalizar">
            <textarea className="textarea_canalizar" id="input-motivo" required placeholder="Motivo de canalización" rows="3" cols="50"></textarea>
          </td>
        </tr>
        <tr>
          <td><label htmlFor="input-comentario"> Comentario: </label></td>
          <td id="td-txt-canalizar">
            <textarea className="textarea_canalizar" id="input-comentario" placeholder="Comentarios..." rows="3" cols="50"></textarea>
          </td>
        </tr>
        <tr>
          <td><label htmlFor="input-fecha"> Fecha: </label></td>
          <td id="td-time-canalizar"><input type="date" id="input-fecha" value={fecha} disabled required /></td>
        </tr>
      </table>
    </div>
  );
};

export default FormularioCanalizarPaciente;
