
import axios from "axios";

const PENDIENTES_API_BASE_URL = "/server/pendientes";

// const PENDIENTES_API_BASE_URL = "http://localhost:3002/pendientes";

class PendientesService {
    async getPendientes(id_psicologo) {
        const response = await axios.get(`${PENDIENTES_API_BASE_URL}?id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error PendientesService, no se obtuvo el pendiente:', error);
            });
        const citas = response.data;
        return citas;
    }

    async getPendiente(fecha, hora, comentario, fk_psicologo) {
        const response = await axios.get(`${PENDIENTES_API_BASE_URL}Individual?fecha=${fecha}&hora=${hora}&comentario=${comentario}&fk_psicologo=${fk_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error PendientesService, no se obtuvo el pendiente:', error);
            });
        const citas = response.data;
        return citas;
    }

    guardarPendiente(pendiente) {
        return axios.post(PENDIENTES_API_BASE_URL, pendiente);
    }


    editarPendiente(pendiente, id_pendiente) {
        return axios.put(PENDIENTES_API_BASE_URL, pendiente, id_pendiente);
    }
    eliminarPendiente(idPendiente) {
        return axios.delete(`${PENDIENTES_API_BASE_URL}?id_pendiente=${idPendiente}`);
    }

}

export default new PendientesService();