import React from "react";
import { useNavigate } from "react-router-dom";

import "../styles/header.css";
import logo from "../img/psiquedata_logo_trans.png";
import img_avatar from "../img/avatar.png";


const Header = () => {

  const usuarioString = sessionStorage.getItem('usuario');
  const usuario = JSON.parse(usuarioString);

  const navigate = useNavigate();
  const cerrarSesion = () => {
    navigate("/");
  };

  return (
    <div className="div-header">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="navbar-left">
        <ul className="nav-links">
          <li>
            <i className="material-symbols-outlined">calendar_month</i>
            <a href="/calendario">Agenda</a>
          </li>
          <li>
            <a href="/notificaciones">
              <i className="material-symbols-outlined"> notifications </i>
              Notificaciones
            </a>
          </li>
          <li>
            <i className="material-symbols-outlined" id="account_menu" >account_circle</i>
            <label id="lbl_menuUsers">Cuenta</label>
            <ul id="ul_profile">
              <li>
                <img id="img_profile" src={img_avatar} alt="img_profile"></img>
                <label id="lbl_menuUsers_nombre">{usuario.nombre} {usuario.ap1} {usuario.ap2} </label>
              </li>
            </ul>
          </li>
          <li>
            <i className="material-symbols-outlined">exit_to_app</i>
            <a href="/"> Salir </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;