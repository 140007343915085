
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import PacientesService from "../../services/PacientesService";
import CanalizarService from "../../services/CanalizarService";

import "../../styles/Pacientes.css";

import img_psy from "../../img/psy_engrane.svg";
import img_exped from "../../img/folder_shared.svg";
import img_groups from "../../img/groups.svg";
import img_edit from "../../img/edit_note.svg";
import img_delete from "../../img/delete.svg";
import img_done from "../../img/done.svg";
import img_search from "../../img/search_pax.svg";
import img_clear from "../../img/clear_all.svg";
import img_person from "../../img/person_add.svg";


const Pacientes = (id) => {

    const [pacientes, setPacientes] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [selectedValue, setSelectedValue] = useState(""); // Estado para el valor del select
    const [inputValue, setInputValue] = useState("");
    const [fotografia, setFotografia] = useState('');
    const [sinFoto, setSinFoto] = useState('');

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    function refreshPage() {
        window.location.reload(false);
    }

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const navigate = useNavigate();

    const getData = async () => {
        if (routeParams.id !== undefined) {     // es el id que obtiene por parametro en: const Pacientes = (id)
            cargarInformacion(routeParams.id);
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            for (let i = 0; i < data.length; i++) {
                const estatusCan = await CanalizarService.getEstatusCanalizacionesPaciente(data[i].id_paciente);
                if (estatusCan.length !== 0) {
                    if (estatusCan.length > 1) {
                        for (let x = 0; x < estatusCan.length; x++) {
                            if (estatusCan[x].estatus === "A") {
                                data[x].estatusCanalizacion = estatusCan[x].estatus;
                            }
                        }
                    } else {
                        data[i].estatusCanalizacion = estatusCan[0].estatus;
                    }

                } else {
                    data[i].estatusCanalizacion = "";
                }
            }
            setPacientes(data);
        } else {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            // estatus de canalización, canalización Activa o Inactiva
            for (let i = 0; i < data.length; i++) {
                const estatusCan = await CanalizarService.getEstatusCanalizacionesPaciente(data[i].id_paciente);
                //regresa: A || I
                if (estatusCan.length !== 0) { // existe canalización
                    if (estatusCan.length > 1) { // si tiene varias canalizaciones
                        for (let x = 0; x < estatusCan.length; x++) {
                            if (estatusCan[x].estatus === "A") {
                                data[x].estatusCanalizacion = estatusCan[x].estatus;

                            } else if (estatusCan[x].estatus === "I") {
                                data[x].estatusCanalizacion = estatusCan[x].estatus;

                            }
                        }
                    } else {    //solo tiene una canalización
                        if (estatusCan[0].estatus === "A") {
                            data[0].estatusCanalizacion = estatusCan[0].estatus;
                        } else if (estatusCan[0].estatus === "I") {
                            data[0].estatusCanalizacion = estatusCan[0].estatus;
                        }
                    }

                } else { //no tiene canalización
                    data[i].estatusCanalizacion = "";
                }
            }
            setPacientes(data);
        }
    }


    useEffect(() => {
        getData();
    }, [])

    const cargarInformacion = async (id_paciente) => {
        const data = await PacientesService.getInfoPaciente(id_paciente);
        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }
        if (data[0].edo === "mi" || data[0].edo === "MI") {
            data[0].edo = "Michoacán"
        }
        if (data[0].pais === "mx" || data[0].pais === "MX") {
            data[0].pais = "México"
        } else if (data[0].pais === "us" || data[0].pais === "US") {
            data[0].pais = "Estados Unidos"
        }

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setInformacion(data[0]);
        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInputValue(data[0].nombre + ' ' + data[0].ap1 + ' ' + data[0].ap2);

    }


    // Pacientes
    const DisplayData = pacientes.map((info) => {
        return (
            <tr className="table-line-bottom">
                <td id="td_1">
                    <label id="lbl_nom_px">
                        <button onClick={() => {
                            cargarInformacion(info.id_paciente)
                        }} id="btn_liga_px">
                            {info.nombre} {info.ap1} {info.ap2}
                        </button>
                    </label>
                </td>
                <td id="td_2">
                    {info.estatus === "A" ? (
                        <label id="lbl_estatus">Activo</label>
                    ) : (
                        <label id="lbl_estatus">Inactivo</label>
                    )}
                </td>

                {/* local 
                <td id="td_3">
                    <a href={`/sesiones/${info.id_paciente}`}>
                        <img id="img_psy" src={img_psy} alt="psy_px"></img>
                    </a>
                </td>
                <td id="td_4">
                    <a href={`/expedientes/${info.id_paciente}`}>
                        <img id="img_exped" src={img_exped} alt="img_exped"></img>
                    </a>
                </td>
                <td id="td_5">
                    <a href={`/citas/${info.id_paciente}`}>
                        <img id="img_groups" src={img_groups} alt="img_groups"></img>
                    </a>
                </td>
                */}

                {/*   web   */}
                <td id="td_3">
                    <a href={`#/sesiones/${info.id_paciente}`}>
                        <img id="img_psy" src={img_psy} alt="psy_px"></img>
                    </a>
                </td>
                <td id="td_4">
                    <a href={`#/expedientes/${info.id_paciente}`}>
                        <img id="img_exped" src={img_exped} alt="img_exped"></img>
                    </a>
                </td>
                <td id="td_5">
                    <a href={`#/citas/${info.id_paciente}`}>
                        <img id="img_groups" src={img_groups} alt="img_groups"></img>
                    </a>
                </td>

                <td id="td_6">
                    {
                        info.estatus === "A" ? (
                            info.estatusCanalizacion === "A" ? (
                                //  --------- web -------------
                                <a href={`#/canalizaciones/${info.id_paciente}`}>
                                    <img id="img_done" src={img_done} alt="img_done"></img>
                                </a>
                            ) : (
                                <label>{""}</label>
                            )
                        ) : (
                            <label>{""}</label>
                        )
                    }
                </td>
            </tr>
        )
    })



    const eliminarPaciente = async () => {
        if (informacion.id_paciente === undefined) {
            Toast.fire("Primero debes seleccionar un paciente", "", "error");
        } else {
            Swal.fire({
                title: "Eliminar cita",
                text: "¿Esta seguro de eliminar el paciente?",
                showCancelButton: true,
                showConfirmButton: false,
                showDenyButton: true,
                denyButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isDenied) {
                    eliminarDatos(informacion.id_paciente);
                }
            });
        }
    }

    const eliminarDatos = async (id_paciente) => {
        const respuesta = await PacientesService.eliminarPacienteL(id_paciente);
        if (respuesta.status === 200) {
            Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    refreshPage();
                }
            });
        } else {
            Toast.fire("Hubo un problema al eliminar la cita!", "", "error");
        }
    }

    const editarPaciente = () => {
        // navigate(`/editpaciente/${informacion.id_paciente}`);   //local
        navigate(`/editpaciente/${informacion.id_paciente}`);  //web
    }

    const agregarPaciente = () => {
        // navigate('/addpaciente');   //local
        navigate('/addpaciente');   //web
    }

    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value);
        console.log(data);
        setPacientes(data);
    }



    const buscarFiltro = async (event) => {
        if (event.target.value === "") {

            const data = await PacientesService.getPacientes(usuario.id_psicologo);

            for (let i = 0; i < data.length; i++) {
                const estatusCan = await CanalizarService.getEstatusCanalizacionesPaciente(data[i].id_paciente);
                if (estatusCan.length !== 0) {
                    if (estatusCan.length > 1) {
                        for (let x = 0; x < estatusCan.length; x++) {
                            if (estatusCan[x].estatus === "A") {
                                data[x].estatusCanalizacion = estatusCan[x].estatus;
                            } else if (estatusCan[x].estatus === "I") {
                                data[x].estatusCanalizacion = estatusCan[x].estatus;
                            }
                        }
                    } else {
                        if (estatusCan[0].estatus === "A") {
                            data[0].estatusCanalizacion = estatusCan[0].estatus;
                        } else if (estatusCan[0].estatus === "I") {
                            data[0].estatusCanalizacion = estatusCan[0].estatus;
                        }
                    }
                } else {
                    data[i].estatusCanalizacion = "";
                }
            }
            setPacientes(data);

        } else {

            setSelectedValue(event.target.value);

            const data = await PacientesService.getPacientesEstatus(event.target.value);

            for (let i = 0; i < data.length; i++) {
                const estatusCan = await CanalizarService.getEstatusCanalizacionesPaciente(data[i].id_paciente);
                if (estatusCan.length !== 0) {
                    if (estatusCan.length > 1) {
                        for (let x = 0; x < estatusCan.length; x++) {
                            if (estatusCan[x].estatus === "A") {
                                data[x].estatusCanalizacion = estatusCan[x].estatus;
                            } else if (estatusCan[x].estatus === "I") {
                                data[x].estatusCanalizacion = estatusCan[x].estatus;
                            }
                        }
                    } else {
                        if (estatusCan[0].estatus === "A") {
                            data[0].estatusCanalizacion = estatusCan[0].estatus;
                        } else if (estatusCan[0].estatus === "I") {
                            data[0].estatusCanalizacion = estatusCan[0].estatus;
                        }
                    }

                } else {
                    data[i].estatusCanalizacion = "";
                }
            }
            setPacientes(data);
        }
    }


    const limpiarFiltro = async (event) => {

        setInputValue("");
        setSelectedValue("");

        const data = await PacientesService.getPacientes(usuario.id_psicologo);

        for (let i = 0; i < data.length; i++) {
            const estatusCan = await CanalizarService.getEstatusCanalizacionesPaciente(data[i].id_paciente);
            if (estatusCan.length !== 0) {
                if (estatusCan.length > 1) {
                    for (let x = 0; x < estatusCan.length; x++) {
                        if (estatusCan[x].estatus === "A") {
                            data[x].estatusCanalizacion = estatusCan[x].estatus;
                        } else if (estatusCan[x].estatus === "I") {
                            data[x].estatusCanalizacion = estatusCan[x].estatus;
                        }
                    }
                } else {
                    if (estatusCan[0].estatus === "A") {
                        data[0].estatusCanalizacion = estatusCan[0].estatus;
                    } else if (estatusCan[0].estatus === "I") {
                        data[0].estatusCanalizacion = estatusCan[0].estatus;
                    }
                }

            } else {
                data[i].estatusCanalizacion = "";
            }
        }
        setPacientes(data);

    }


    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    return (
        <Layout>
            <Content>
                <div className="px_div_1">
                    <div className="px_div_2">
                        <div className="px_sesion">
                            <label id="lbl-enSesion">{tipoUsuario()} {usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_px">
                            <label>Pacientes</label>
                        </div>
                    </div>
                    <div className="px_buscador">

                        <div className="div_No_exp_buscar_px">
                            <div className="input_No_px">
                                <input type="number" placeholder="Folio" id="Buscador"></input>
                            </div>
                            <div className="input_buscar">
                                <input type="search" placeholder="Buscar paciente..." id="Buscador" value={inputValue} onChange={buscar} />
                                <img src={img_search} alt="buscar" id="img_lupa"></img>
                            </div>
                        </div>

                        <div className="px_filtro">
                            <select id="px_select" value={selectedValue} onChange={buscarFiltro}>
                                <option value="" disabled>Selecciona un filtro</option>
                                <option value="A">Activos</option>
                                <option value="I">Inactivos</option>
                            </select>
                            <button id="btn-limpiar-filtro" onClick={limpiarFiltro}>
                                <img src={img_clear} alt="img_clear" />
                                Limpiar
                            </button>
                        </div>

                        <div className="px_nuevo">
                            <button id="btn-limpiar-filtro" onClick={agregarPaciente}>
                                <img src={img_person} alt="img_person" />
                                Paciente
                            </button>
                        </div>

                    </div>

                    <div className="px_div_3">
                        <div className="div_px_lista">
                            <div className="div_tablas_th_px">
                                <table id="encabezado_px">
                                    <tbody>
                                        <tr className="tr-encabezado-px">
                                            <th>Nombre</th>
                                            <th>Activo</th>
                                            <th>Sesiones</th>
                                            <th>Expediente</th>
                                            <th>Citas</th>
                                            <th>Canalización activa</th>
                                        </tr>
                                    </tbody>
                                </table>
                                <table id="displayData_px">
                                    <tbody>
                                        {pacientes.length !== 0 ? (DisplayData) : (
                                            <tr>
                                                <td>
                                                    <label> No existen pacientes en el sistema </label>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="px_tabla">
                            {informacion.length === 0 ? (
                                <div>
                                    <div id="edit_delete">
                                        <div id="px_h3">
                                            <label id="lbl_datos_px">Paciente</label>
                                        </div>
                                    </div>
                                    <div className="div-selec-px">
                                        <label id="label-selec-px-px"> Selecciona un paciente de la lista... </label>
                                    </div>
                                </div>
                            ) : (
                                <div className="px_seleccionado_tabla">
                                    <div id="edit_delete">
                                        <div id="px_h3">
                                            <label id="lbl_datos_px">Paciente</label>
                                        </div>
                                        <div className="div-px-toolTip">
                                            <div id="container-toolTip-editar-px">
                                                <span className="lbl-toolTip-editar-px">
                                                    Editar
                                                </span>
                                                <img src={img_edit} alt="edit" id="img_edit" onClick={editarPaciente} />
                                            </div>
                                            <div id="container-toolTip-delete-px">
                                                <span className="lbl-toolTip-delete-px">
                                                    Eliminar
                                                </span>
                                                <img src={img_delete} alt="delete" id="img_delete" onClick={eliminarPaciente} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div-avatar-px">
                                        {
                                            sinFoto === "1" ? (
                                                fotografia === "" ? (
                                                    <div id="img_avatar_px">
                                                        <label> Cargando imagen...</label>
                                                    </div>
                                                ) : (
                                                    <div id="img_avatar_px">
                                                        <img src={fotografia} alt="base64"></img>
                                                    </div>
                                                )
                                            ) : (<div></div>)
                                        }
                                    </div>
                                    <table id="table_px_seleccionado">
                                        <tbody>
                                            <tr className="padding-row">
                                                <td id="td-paciente" colSpan="2"><label id="lbl-paciente">{informacion.nombre} {informacion.ap1} {informacion.ap2}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-familiar" htmlFor="lbl-familiar">Familiares:</label></td>
                                                <td>
                                                    {/*   local   
                                                    <label id="lbl-familiar">
                                                        <a href="/familiares">Ver</a>
                                                    </label>*/}

                                                    {/*   web   */}
                                                    <label id="lbl-familiar">
                                                        <a href="#/familiares">Ver</a>
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-edad" htmlFor="lbl-edad">Edad:</label></td>
                                                <td><label id="lbl-edad">{informacion.edad}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-sexo" htmlFor="lbl-sexo">Sexo:</label></td>
                                                <td><label id="lbl-sexo">{informacion.sexo}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-escolaridad" htmlFor="lbl-escolaridad">Escolaridad:</label></td>
                                                <td><label id="lbl-escolaridad">{informacion.escolaridad}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-profesion" htmlFor="lbl-profesion">Profesion:</label></td>
                                                <td><label id="lbl-profesion">{informacion.profesion}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-trabajo" htmlFor="lbl-trabajo">Trabajo actual:</label></td>
                                                <td><label id="lbl-trabajo">{informacion.trabajo_actual}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-pref_sex" htmlFor="lbl-pref_sex">Preferencia sexual:</label></td>
                                                <td><label id="lbl-pref_sex">{informacion.pref_sexual}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-edo-civil" htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                <td><label id="lbl-edo-civil">{informacion.edo_civil}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-tel" htmlFor="lbl-tel">Teléfono:</label></td>
                                                <td><label id="lbl-tel">{informacion.tel}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-correo" htmlFor="lbl-correo">Correo:</label></td>
                                                <td><label id="lbl-correo">{informacion.correo}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-domicilio" htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                <td><label id="lbl-domicilio">{informacion.domicilio}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-cp" htmlFor="lbl-cp">Código Postal:</label></td>
                                                <td><label id="lbl-cp">{informacion.cod_pos}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-ciudad" htmlFor="lbl-ciudad">Ciudad:</label></td>
                                                <td><label id="lbl-ciudad">{informacion.ciudad}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-edo" htmlFor="lbl-edo">Estado:</label></td>
                                                <td><label id="lbl-edo">{informacion.edo}</label></td>
                                            </tr>
                                            <tr className="padding-row">
                                                <td><label className="label-pais" htmlFor="lbl-pais">País:</label></td>
                                                <td><label id="lbl-pais">{informacion.pais}</label></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Pacientes;
