
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { parse, format } from 'date-fns';
import { Navigate, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import PsicologoService from "../../services/PsicologoService";
import UsuariosService from "../../services/UsuariosService";

import img_avatar from "../../img/avatar.png";

import "../../styles/FormularioAddPsicologo.css";



const AddPsicologo = () => {

  const [nombre, setNombre] = useState('');
  const [ap1, setAp1] = useState('');
  const [ap2, setAp2] = useState('');
  const [edad, setEdad] = useState('');
  const [sexo, setSexo] = useState('');
  const [escolaridad, setEscolaridad] = useState('');
  const [cedula, setCedula] = useState('');
  const [especialidad, setEspecialidad] = useState('');
  const [titulo, setTitulo] = useState('');
  const [edoCivil, setEdoCivil] = useState('');
  const [tel, setTel] = useState('');
  const [correo, setCorreo] = useState('');
  const [domicilio, setDomicilio] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [codpostal, setCodpostal] = useState('');
  const [estado, setEstado] = useState('');
  const [pais, setPais] = useState('');
  const [image, setImage] = useState(null);
  const [fotografia, setFotografia] = useState('');
  const [formatoFoto, setFormatoFoto] = useState('');

  const navigate = useNavigate();
  const usuarioString = sessionStorage.getItem('usuario');
  const usuario = JSON.parse(usuarioString);
  const routeParams = useParams();

  const Toast = Swal.mixin({
    toast: true,
    timer: 3000,
    timerProgressBar: true,
    confirmButtonText: "Aceptar"
  })


  const handleNombre = (event) => {
    setNombre(event.target.value);
  }
  const handleAp1 = (event) => {
    setAp1(event.target.value);
  }
  const handleAp2 = (event) => {
    setAp2(event.target.value);
  }
  const handleEdad = (event) => {
    setEdad(event.target.value);
  }
  const handleSexo = (event) => {
    setSexo(event.target.value);
  }
  const handleEscolaridad = (event) => {
    setEscolaridad(event.target.value);
  }
  const handleCedula = (event) => {
    setCedula(event.target.value);
  }
  const handleEspecialidad = (event) => {
    setEspecialidad(event.target.value);
  }
  const handleTitulo = (event) => {
    setTitulo(event.target.value);
  }
  const handleEdoCivil = (event) => {
    setEdoCivil(event.target.value);
  }
  const handleTel = (event) => {
    setTel(event.target.value);
  }
  const handleCorreo = (event) => {
    setCorreo(event.target.value);
  }
  const handleDomicilio = (event) => {
    setDomicilio(event.target.value);
  }
  const handleCiudad = (event) => {
    setCiudad(event.target.value);
  }
  const handleCodPostal = (event) => {
    setCodpostal(event.target.value);
  }
  const handleEstado = (event) => {
    setEstado(event.target.value);
  }
  const handlePais = (event) => {
    setPais(event.target.value);
  }
  const handleFotografia = (event) => {
    setFotografia(event.target.value);
  }
  const handleFormatoFoto = (event) => {
    setFormatoFoto(event.target.value);
  }

  const handleSalir = (event) => {
    navigate('/administracion');
  }


  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/png') {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64String = reader.result;
            setImage(base64String);
          };
        }
      }
      else {
        event.target.value = '';
        event.target.setCustomValidity('Solo se permiten archivos con extensión: .jpg, .jpeg, .png');
        event.target.reportValidity();      // es como un Alert
      }
    }
  };


  const handleSubmit = async () => {

    let formato = "";
    if (image.includes("data:image/jpeg;base64,")) {
      formato = "jpeg"
    } else if (image.includes("data:image/jpg;base64,")) {
      formato = "jpg"
    } else if (image.includes("data:image/png;base64,")) {
      formato = "png";
    }

    let user = {
      nombre: nombre,
      ap1: ap1,
      ap2: ap2,
      edad: edad,
      sexo: sexo,
      edo_civil: edoCivil,
      domicilio: domicilio,
      ciudad: ciudad,
      cod_pos: codpostal,
      edo: estado,
      pais: pais,
      tel: tel,
      correo: correo,
      contrasena: "123",
      rol: "psi",
      estatus: "A",
      fotografia: image,
      formatoFoto: formato
    }

    await UsuariosService.createUsuario(user);

    const ultimoUsuario = await UsuariosService.getUltimoUsuario();

    let psicologo = {
      escolaridad: escolaridad,
      cedula: cedula,
      especialidad: especialidad,
      titulo: titulo,
      fk_usuario: ultimoUsuario.id_usuario
    }

    await PsicologoService.createPsicologo(psicologo);

    Toast.fire("El paciente se registró correctamente!", "", "success").then((result) => {
      if (result.isConfirmed) {
        navigate('/administracion');
      }
    }).then(function () {
      navigate('/administracion');
    });
  }

  return (
    <Layout>
      <Content>
        <div className="div-1-addPsi">
          <div className="div_2_addPsi">
            <div className="addPsi_sesion">
              <label id="lbl-enSesion_addPsi">En sesión: &nbsp; </label>
              <label>{usuario.nombre} {usuario.ap1} {usuario.ap2} </label>
            </div>
            <div className="div_lbl_addPsi">
              <label>Ingresar nuevo psicólogo</label>
            </div>
          </div>
          <div className="div-datos-addPsi">
            <div className="div_content_foto_addPsi">
              <div className="div-foto-px-addPsi">
                {image && <img alt="vista previa" className="vista-previa" src={image} />}
              </div>
              <div className="div_btn_foto_addPsi">
                <input type="file" onChange={onImageChange} className="filetype" accept=".jpg, .jpeg, .png" />
              </div>
            </div>

            <table className="table-EdPx">
              <tr>
                <td className="addPsi-td-izq"><label className="label-psicologo" htmlFor="input-nombre">Psicologo:</label></td>
                <td className="addPsi-td-der1">
                  <input type="text" id="input-nombre" required placeholder="Nombre" onChange={handleNombre}></input>
                </td>
                <td className="addPsi-td-der2">
                  <input type="text" id="input-ap1" required placeholder="Apellido paterno" onChange={handleAp1}></input>
                </td>
                <td className="addPsi-td-der3">
                  <input type="text" id="input-ap2" required placeholder="Apellido materno" onChange={handleAp2}></input>
                </td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-edad" htmlFor="input-edad">Edad:</label></td>
                <td className="addPsi-td-der3"><input type="number" id="input-edad" required onChange={handleEdad}></input></td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-sexo" htmlFor="select-sexo">Sexo:</label></td>
                <td className="addPsi-td-der">
                  <div className="radio-container">
                    <label for="masculino">
                      <input type="radio" id="masculino" name="sexo" value="m" required onChange={handleSexo} /> Masculino
                    </label>
                    <label for="femenino">
                      <input type="radio" id="femenino" name="sexo" value="f" onChange={handleSexo} /> Femenino
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-escolaridad" htmlFor="select-escolaridad">Escolaridad:</label></td>
                <td className="addPsi-td-der1">
                  <select id="select-escolaridad-addPsi" onChange={handleEscolaridad}>
                    <option value="elige" selected>Elige una opción</option>
                    <option value="primaria">Primaria</option>
                    <option value="secundaria">Secundaria</option>
                    <option value="preparatoria">Preparatoria</option>
                    <option value="universidad">Universidad</option>
                    <option value="posgrado">Posgrado</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-cedula" htmlFor="input-cedula">Cedula:</label></td>
                <td className="addPsi-td-der1"><input type="text" id="input-cedula" placeholder="Cédula" onChange={handleCedula}></input></td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-especialidad" htmlFor="input-especialidad">Especialidad:</label></td>
                <td className="addPsi-td-der1"><input type="text" id="input-especialidad" placeholder="Especialidad" onChange={handleEspecialidad}></input></td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-titulo" htmlFor="select-titulo">Título:</label></td>
                <td className="addPsi-td-der1">
                  <select id="select-titulo-addPsi" onChange={handleTitulo}>
                    <option value="elige" selected>Elige una opción</option>
                    <option value="psicologo">Psicólogo</option>
                    <option value="psicoterapeuta">Psicoterapeuta</option>
                    <option value="psicoanalista">Psicoanalista</option>
                    <option value="psiquiatra">Psiquiatra</option>
                    <option value="paidopsiquiatra">Paidopsiquiatra</option>
                    <option value="geriatra">Geriatra</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-edo-civil" htmlFor="select-edo-civil">Estado civil:</label></td>
                <td className="addPsi-td-der1">
                  <select id="select-edo-civil-addPsi" onChange={handleEdoCivil}>
                    <option value="elige" selected>Elige una opción</option>
                    <option value="casado">Casado</option>
                    <option value="divorsiado">Divorciado</option>
                    <option value="soltero">Soltero</option>
                    <option value="separado">Separado</option>
                    <option value="viudo">Viudo</option>
                    <option value="concubinato">Concubinato</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-tel" htmlFor="input-tel">Teléfono:</label></td>
                <td className="addPsi-td-der1"><input type="tel" id="input-tel" required onChange={handleTel}></input></td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-correo" htmlFor="input-correo">Correo:</label></td>
                <td className="addPsi-td-der1"><input type="email" id="input-correo" placeholder="Correo electrónico" onChange={handleCorreo}></input></td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-domicilio" htmlFor="input-domicilio">Domicilio:</label></td>
                <td className="addPsi-td-der1"><input type="text" id="input-domicilio" placeholder="Calle, número..." onChange={handleDomicilio}></input></td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-ciudad" htmlFor="input-ciudad">Ciudad:</label></td>
                <td className="addPsi-td-der1"><input type="text" id="input-ciudad" required placeholder="Ciudad..." onChange={handleCiudad}></input></td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-cp" htmlFor="input-cp">Código Postal:</label></td>
                <td className="addPsi-td-der1"><input type="number" id="input-cp" placeholder="Código postal" onChange={handleCodPostal}></input></td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-edo" htmlFor="select-edo">Estado:</label></td>
                <td className="addPsi-td-der1">
                  <select id="select-edo-addPsi" required onChange={handleEstado}>
                    <option value="a">Aguascalientes</option>
                    <option value="b">Baja California</option>
                    <option value="bs">Baja California Sur</option>
                    <option value="c">Campeche</option>
                    <option value="ch">Chiapas</option>
                    <option value="chi">Chihuahua</option>
                    <option value="co">Coahuila</option>
                    <option value="col">Colima</option>
                    <option value="cd">Ciudad de México</option>
                    <option value="d">Durango</option>
                    <option value="em">Estado de México</option>
                    <option value="g">Guanajuato</option>
                    <option value="gu">Guerrero</option>
                    <option value="h">Hidalgo</option>
                    <option value="j">Jalisco</option>
                    <option value="m" selected>Michoacán</option>
                    <option value="mo">Morelos</option>
                    <option value="n">Nayarit</option>
                    <option value="nl">Nuevo León</option>
                    <option value="o">Oaxaca</option>
                    <option value="p">Puebla</option>
                    <option value="q">Querétaro</option>
                    <option value="qr">Quintana Roo</option>
                    <option value="sn">San Luis Potosí</option>
                    <option value="s">Sinaloa</option>
                    <option value="so">Sonora</option>
                    <option value="t">Tabasco</option>
                    <option value="ta">Tamaulipas</option>
                    <option value="tl">Tlaxcala</option>
                    <option value="v">Veracruz</option>
                    <option value="y">Yucatán</option>
                    <option value="z">Zacatecas</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="addPsi-td-izq"><label className="label-pais" htmlFor="select-pais">País:</label></td>
                <td className="addPsi-td-der1">
                  <select id="select-pais-addPsi" onChange={handlePais}>
                    <option value="AF">Afganistán</option>
                    <option value="AL">Albania</option>
                    <option value="DE">Alemania</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AI">Anguilla</option>
                    <option value="AQ">Antártida</option>
                    <option value="AG">Antigua y Barbuda</option>
                    <option value="AN">Antillas Holandesas</option>
                    <option value="SA">Arabia Saudí</option>
                    <option value="DZ">Argelia</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AW">Aruba</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaiyán</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrein</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BE">Bélgica</option>
                    <option value="BZ">Belice</option>
                    <option value="BJ">Benin</option>
                    <option value="BM">Bermudas</option>
                    <option value="BY">Bielorrusia</option>
                    <option value="MM">Birmania</option>
                    <option value="BO">Bolivia</option>
                    <option value="BA">Bosnia y Herzegovina</option>
                    <option value="BW">Botswana</option>
                    <option value="BR">Brasil</option>
                    <option value="BN">Brunei</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="BT">Bután</option>
                    <option value="CV">Cabo Verde</option>
                    <option value="KH">Camboya</option>
                    <option value="CM">Camerún</option>
                    <option value="CA">Canadá</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CY">Chipre</option>
                    <option value="VA">Ciudad del Vaticano (Santa Sede)</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comores</option>
                    <option value="CG">Congo</option>
                    <option value="CD">Congo, República Democrática del</option>
                    <option value="KR">Corea</option>
                    <option value="KP">Corea del Norte</option>
                    <option value="CI">Costa de Marfíl</option>
                    <option value="CR">Costa Rica</option>
                    <option value="HR">Croacia (Hrvatska)</option>
                    <option value="CU">Cuba</option>
                    <option value="DK">Dinamarca</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egipto</option>
                    <option value="SV">El Salvador</option>
                    <option value="AE">Emiratos Árabes Unidos</option>
                    <option value="ER">Eritrea</option>
                    <option value="SI">Eslovenia</option>
                    <option value="ES">España</option>
                    <option value="US">Estados Unidos</option>
                    <option value="EE">Estonia</option>
                    <option value="ET">Etiopía</option>
                    <option value="FJ">Fiji</option>
                    <option value="PH">Filipinas</option>
                    <option value="FI">Finlandia</option>
                    <option value="FR">Francia</option>
                    <option value="GA">Gabón</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GD">Granada</option>
                    <option value="GR">Grecia</option>
                    <option value="GL">Groenlandia</option>
                    <option value="GP">Guadalupe</option>
                    <option value="GU">Guam</option>
                    <option value="GT">Guatemala</option>
                    <option value="GY">Guayana</option>
                    <option value="GF">Guayana Francesa</option>
                    <option value="GN">Guinea</option>
                    <option value="GQ">Guinea Ecuatorial</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="HT">Haití</option>
                    <option value="HN">Honduras</option>
                    <option value="HU">Hungría</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IQ">Irak</option>
                    <option value="IR">Irán</option>
                    <option value="IE">Irlanda</option>
                    <option value="BV">Isla Bouvet</option>
                    <option value="CX">Isla de Christmas</option>
                    <option value="IS">Islandia</option>
                    <option value="KY">Islas Caimán</option>
                    <option value="CK">Islas Cook</option>
                    <option value="CC">Islas de Cocos o Keeling</option>
                    <option value="FO">Islas Faroe</option>
                    <option value="HM">Islas Heard y McDonald</option>
                    <option value="FK">Islas Malvinas</option>
                    <option value="MP">Islas Marianas del Norte</option>
                    <option value="MH">Islas Marshall</option>
                    <option value="UM">Islas menores de Estados Unidos</option>
                    <option value="PW">Islas Palau</option>
                    <option value="SB">Islas Salomón</option>
                    <option value="SJ">Islas Svalbard y Jan Mayen</option>
                    <option value="TK">Islas Tokelau</option>
                    <option value="TC">Islas Turks y Caicos</option>
                    <option value="VI">Islas Vírgenes (EEUU)</option>
                    <option value="VG">Islas Vírgenes (Reino Unido)</option>
                    <option value="WF">Islas Wallis y Futuna</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italia</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japón</option>
                    <option value="JO">Jordania</option>
                    <option value="KZ">Kazajistán</option>
                    <option value="KE">Kenia</option>
                    <option value="KG">Kirguizistán</option>
                    <option value="KI">Kiribati</option>
                    <option value="KW">Kuwait</option>
                    <option value="LA">Laos</option>
                    <option value="LS">Lesotho</option>
                    <option value="LV">Letonia</option>
                    <option value="LB">Líbano</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libia</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lituania</option>
                    <option value="LU">Luxemburgo</option>
                    <option value="MK">Macedonia, Ex-República Yugoslava de</option>
                    <option value="MG">Madagascar</option>
                    <option value="MY">Malasia</option>
                    <option value="MW">Malawi</option>
                    <option value="MV">Maldivas</option>
                    <option value="ML">Malí</option>
                    <option value="MT">Malta</option>
                    <option value="MA">Marruecos</option>
                    <option value="MQ">Martinica</option>
                    <option value="MU">Mauricio</option>
                    <option value="MR">Mauritania</option>
                    <option value="YT">Mayotte</option>
                    <option value="MX" selected>México</option>
                    <option value="FM">Micronesia</option>
                    <option value="MD">Moldavia</option>
                    <option value="MC">Mónaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="MS">Montserrat</option>
                    <option value="MZ">Mozambique</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Níger</option>
                    <option value="NG">Nigeria</option>
                    <option value="NU">Niue</option>
                    <option value="NF">Norfolk</option>
                    <option value="NO">Noruega</option>
                    <option value="NC">Nueva Caledonia</option>
                    <option value="NZ">Nueva Zelanda</option>
                    <option value="OM">Omán</option>
                    <option value="NL">Países Bajos</option>
                    <option value="PA">Panamá</option>
                    <option value="PG">Papúa Nueva Guinea</option>
                    <option value="PK">Paquistán</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Perú</option>
                    <option value="PN">Pitcairn</option>
                    <option value="PF">Polinesia Francesa</option>
                    <option value="PL">Polonia</option>
                    <option value="PT">Portugal</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="QA">Qatar</option>
                    <option value="UK">Reino Unido</option>
                    <option value="CF">República Centroafricana</option>
                    <option value="CZ">República Checa</option>
                    <option value="ZA">República de Sudáfrica</option>
                    <option value="DO">República Dominicana</option>
                    <option value="SK">República Eslovaca</option>
                    <option value="RE">Reunión</option>
                    <option value="RW">Ruanda</option>
                    <option value="RO">Rumania</option>
                    <option value="RU">Rusia</option>
                    <option value="EH">Sahara Occidental</option>
                    <option value="KN">Saint Kitts y Nevis</option>
                    <option value="WS">Samoa</option>
                    <option value="AS">Samoa Americana</option>
                    <option value="SM">San Marino</option>
                    <option value="VC">San Vicente y Granadinas</option>
                    <option value="SH">Santa Helena</option>
                    <option value="LC">Santa Lucía</option>
                    <option value="ST">Santo Tomé y Príncipe</option>
                    <option value="SN">Senegal</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leona</option>
                    <option value="SG">Singapur</option>
                    <option value="SY">Siria</option>
                    <option value="SO">Somalia</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="PM">St Pierre y Miquelon</option>
                    <option value="SZ">Suazilandia</option>
                    <option value="SD">Sudán</option>
                    <option value="SE">Suecia</option>
                    <option value="CH">Suiza</option>
                    <option value="SR">Surinam</option>
                    <option value="TH">Tailandia</option>
                    <option value="TW">Taiwán</option>
                    <option value="TZ">Tanzania</option>
                    <option value="TJ">Tayikistán</option>
                    <option value="TF">Territorios franceses del Sur</option>
                    <option value="TP">Timor Oriental</option>
                    <option value="TG">Togo</option>
                    <option value="TO">Tonga</option>
                    <option value="TT">Trinidad y Tobago</option>
                    <option value="TN">Túnez</option>
                    <option value="TM">Turkmenistán</option>
                    <option value="TR">Turquía</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UA">Ucrania</option>
                    <option value="UG">Uganda</option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistán</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VE">Venezuela</option>
                    <option value="VN">Vietnam</option>
                    <option value="YE">Yemen</option>
                    <option value="YU">Yugoslavia</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabue</option>
                  </select>
                </td>
              </tr>
            </table>
          </div>
          <div className="div-autoregistro-botones-addPsi">
            <div className="div-btn-alineacion-addPsi">
              <div className="div-addPsi-enviar">
                <button value="enviar" id="btn-enviar-addPsi" onClick={handleSubmit}>Enviar</button>
              </div>
              <div className="div-addPsi-cancelar">
                <button value="cancelar" id="btn-cancelar-addPsi" onClick={handleSalir}>Cancelar</button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default AddPsicologo;
