import React, { useEffect, useState } from "react";
import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import { useParams, useNavigate } from "react-router-dom";

import Swal from 'sweetalert2';

import PsicologoService from "../../services/PsicologoService";
import PacientesService from "../../services/PacientesService";
import FamiliaresService from "../../services/FamiliaresService";

import "../../styles/FormularioAddFamiliar.css";
import img_search from "../../img/search_pax.svg";
import img_clear from "../../img/clear.svg";
import img_avatar from "../../img/avatar.png";

const AddFamiliar = () => {

    const [idselectfam, setIdSelectFam] = useState('');
    const [idselectpac, setIdSelectPac] = useState('');
    const [psicologos, setPsicologos] = useState('');
    const [getFamiliar, setGetFamiliares] = useState([]);
    const [tablaFamiliar, setCargarFamiliar] = useState([]);
    const [pacientes, setPacientes] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [infoFamPac, setInfoFamiliarPaciente] = useState([]);
    const [paciente, setPaciente] = useState('');
    const [familiar, setFamiliar] = useState('');
    const [selectedValue, setSelectedValue] = useState(""); // Estado para el valor del select
    const [inputValue, setInputValue] = useState("");
    const [selectedValueFam, setSelectedValueFam] = useState(""); // Estado para el valor del select
    const [inputValueFam, setInputValueFam] = useState("");
    const [inputBuscarFam, setInputBuscarFam] = useState("");
    const [esPac, setEsPac] = useState('no');

    const [nombre, setNombre] = useState('');
    const [ap1, setAp1] = useState('');
    const [ap2, setAp2] = useState('');
    const [parentesco, setParentesco] = useState('');
    const [edad, setEdad] = useState('');
    const [sexo, setSexo] = useState('');
    const [edoCivil, setEdoCivil] = useState('');
    const [tel, setTel] = useState('');
    const [correo, setCorreo] = useState('');
    const [domicilio, setDomicilio] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [codPostal, setCodPostal] = useState('');
    const [estado, setEstado] = useState('m');
    const [pais, setPais] = useState('MX');
    const [estatus, setEstatus] = useState('');
    const [image, setImage] = useState(null);
    const [fotografia, setFotografia] = useState(null);

    const routeParams = useParams();
    const navigate = useNavigate();
    const usuarioString = sessionStorage.getItem('usuario');    //usuario psicólogo
    const usuario = JSON.parse(usuarioString);

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }


    const getData = async () => {
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);

        const data2 = await PsicologoService.getPsicologos();
        setPsicologos(data2);

        const data3 = await FamiliaresService.getFamiliares();
        setGetFamiliares(data3);
    }

    useEffect((
    ) => {
        getData();
    }, []);



    /* Datos Usuario */

    const handlePaciente = (event) => {
        setPaciente(event.target.value);
    }
    const handleNombre = (event) => {
        setNombre(event.target.value);
    }
    const handleAp1 = (event) => {
        setAp1(event.target.value);
    }
    const handleAp2 = (event) => {
        setAp2(event.target.value);
    }
    const handleParentesco = (event) => {
        setParentesco(event.target.value);
    }
    const handleSexo = (event) => {
        setSexo(event.target.value);
    }
    const handleEdoCivil = (event) => {
        setEdoCivil(event.target.value);
    }
    const handleEdad = (event) => {
        setEdad(event.target.value);
    }
    const handleDomicilio = (event) => {
        setDomicilio(event.target.value);
    }
    const handleCiudad = (event) => {
        setCiudad(event.target.value);
    }
    const handleCodPostal = (event) => {
        setCodPostal(event.target.value);
    }
    const handleEstado = (event) => {
        setEstado(event.target.value);
    }
    const handlePais = (event) => {
        setPais(event.target.value);
    }
    const handleTel = (event) => {
        setTel(event.target.value);
    }
    const handleCorreo = (event) => {
        setCorreo(event.target.value);
    }
    const handleEstatus = (event) => {
        setEstatus(event.target.value);
    }

    const handleEsPac = (event) => {
        setEsPac(event.target.value);
    }

    const handleSalir = (event) => {
        // navigate('/familiares');    // local
        navigate('/familiares');    // web
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/png') {
                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        const base64String = reader.result;
                        setImage(base64String);
                    };
                }
            }
            else {
                event.target.value = '';
                event.target.setCustomValidity('Solo se permiten archivos con extensión: .jpg, .jpeg, .png');
                event.target.reportValidity();      // es como un Alert
            }
        }
    };

    const cargarInfoPacRelacionado = async (event) => {
        const data = await PacientesService.getInfoPaciente(event.target.value);
        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }
        setInformacion(data[0]);
        setIdSelectPac(data[0].id_paciente);
    };

    const cargarInfoFamiliarPaciente = async (event) => {
        const data = await PacientesService.getInfoPaciente(event.target.value);
        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }
        setInfoFamiliarPaciente(data[0]);
        setIdSelectFam(data[0].id_paciente);    // obtiene el id del paciente seleccionado del Select: el pac es familiar
    };

    const cargarFamiliar = async (event) => {
        const data = await FamiliaresService.getFamiliarIndividual(event.target.value);
        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }
        setCargarFamiliar(data[0]);
    };


    /******************* *************/

    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value);
        setPacientes(data);
    };

    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value);
            setPacientes(data);
        }
    };

    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);
    };


    /********* Form y Busqueda de Familiar a ingresar ******/
    const buscarFam = async (event) => {
        setInputBuscarFam(event.target.value);
        try {
            const data = await FamiliaresService.getFamiliarBuscador(event.target.value);
            setInputValueFam(data);

            setNombre(data[0].nombre)
            setAp1(data[0].ap1);
            setAp2(data[0].ap2);
            setParentesco(data[0].parentesco);
            setEdad(data[0].edad);
            setSexo(data[0].sexo);
            setEdoCivil(data[0].edo_civil);
            setTel(data[0].tel);
            setCorreo(data[0].correo);
            setDomicilio(data[0].domicilio);
            setCiudad(data[0].ciudad);
            setCodPostal(data[0].cod_pos);
            setEstado(data[0].edo);
            setPais(data[0].pais);

        } catch (err) {
            //alert("No existen pacientes con la letra ingresada",err);
            mostrarError("No existen pacientes con la letra ingresada");
        }

    };

    const buscarFiltroFam = async (event) => {
        let optNum = parseInt(event.target.value)
        if (event.target.value !== "") {
            const data = await FamiliaresService.getFamiliarIndividual(event.target.value);
            setInputValueFam(data[0]);
            setSelectedValueFam(optNum);
            // setInputBuscarFam(data[0].nombre + " " + data[0].ap1 + " " + data[0].ap2);   // cada vez que se borra una letra, busca y manda error

            setNombre(data[0].nombre)
            setAp1(data[0].ap1);
            setAp2(data[0].ap2);
            setParentesco(data[0].parentesco);
            setEdad(data[0].edad);
            setSexo(data[0].sexo);
            setEdoCivil(data[0].edo_civil);
            setTel(data[0].tel);
            setCorreo(data[0].correo);
            setDomicilio(data[0].domicilio);
            setCiudad(data[0].ciudad);
            setCodPostal(data[0].cod_pos);
            setEstado(data[0].edo);
            setPais(data[0].pais);

        }
    };


    const limpiarFiltroFam = async () => {
        setInputValueFam("");
        setSelectedValueFam("");
        setInputBuscarFam("");
        //document.getElementById("form_addFamiliar").reset();    // al menos borra el input buscar

        setNombre("")
        setAp1("");
        setAp2("");
        setParentesco("");
        setEdad("");
        setSexo("");
        setEdoCivil("");
        setTel("");
        setCorreo("");
        setDomicilio("");
        setCiudad("");
        setCodPostal("");
        setEstado("");
        setPais("");
    };

    /******************* *************/


    const handleSubmit = async () => {

        if (esPac === 'si') {
            let parent = {
                parentesco: parentesco,
                fk_paciente: paciente,
                fk_paciente_familiar: informacion.id_paciente
            }
            await FamiliaresService.createParentesco(parent);
            Toast.fire("El paciente se registró correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    // navigate('/familiares');    // local
                    navigate('/familiares');    // web
                }
            }).then(function () {
                // navigate('/familiares');    // local
                navigate('/familiares');    // web
            });
        } else {
            let formato = "";
            if (image.includes("data:image/jpeg;base64,")) {
                formato = "jpeg"
            } else if (image.includes("data:image/jpg;base64,")) {
                formato = "jpg"
            } else if (image.includes("data:image/png;base64,")) {
                formato = "png";
            }

            let add_familiar = {
                nombre: nombre,
                ap1: ap1,
                ap2: ap2,
                edad: edad,
                sexo: sexo,
                edo_civil: edoCivil,
                domicilio: domicilio,
                ciudad: ciudad,
                cod_pos: codPostal,
                edo: estado,
                pais: pais,
                tel: tel,
                correo: correo,
                parentesco: parentesco,
                fotografia: image,
                fk_paciente: informacion.id_paciente,
                formatoImagen: formato,
            }

            if (idselectpac !== "" || idselectpac === undefined) {
                await FamiliaresService.createFamiliares(add_familiar);
                Toast.fire("¡El familiar se registró correctamente!", "", "success")
                    .then((result) => {
                        if (result.isConfirmed) {
                            // navigate('/familiares');    // local
                            navigate('/familiares');    // web
                        }
                    }).then(function () {
                        // navigate('/familiares');    // local
                        navigate('/familiares');    // web
                    });
            } else {
                mostrarError("Se debe seleccionar al paciente relacionado");
            }
        }
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    return (
        <Layout>
            <Content>
                <div className="div-1-addFam">
                    <div className="div_2_addFam">
                        <div className="px_sesion_addFam">
                            <label id="lbl-enSesion-addFam">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_addFam">
                            <label>Ingresar familiares del paciente</label>
                        </div>
                    </div>
                    <div className="div-datos-addFam">
                        <div className="div_content_foto_addFam">
                            <div className="div-foto-px-addFam">
                                {image && <img alt="vista previa" className="vista-previa" src={image} />}
                            </div>
                            <div className="div_btn_foto_addFam">
                                <input type="file" onChange={onImageChange} className="filetype" accept=".jpg, .jpeg, .png" />
                            </div>
                        </div>
                        <div className="div-selectPacRel-fam">
                            <div className="div-tableSelect-fam">
                                <table id="table-select-addFam">
                                    <tbody>
                                        <tr>
                                            <td id="td-izq-selectPx-addFam">
                                                <label className="label-paciente" htmlFor="select-paciente">Paciente relacionado:</label>
                                            </td>
                                            <td id="td-der-selectPx-addFam">
                                                <select id="select-paciente-addFam" onChange={cargarInfoPacRelacionado} required>
                                                    <option className="option_addFam" disabled selected >Selecciona una opción</option>
                                                    {pacientes.map((paciente) => (
                                                        <option className="selected-option-addFam" value={paciente.id_paciente}>
                                                            {paciente.nombre} {paciente.ap1} {paciente.ap2}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="div-tableSelect-fam">
                                <table id="table-select-addFam">
                                    <tbody>
                                        <tr>
                                            <td id="td-izq-selectPx-addFam">
                                                <select id="select-paciente-addFam" value={selectedValue} onChange={buscarFiltro}>
                                                    <option value="" disabled >Selecciona un filtro</option>
                                                    <option value="A">Activos</option>
                                                    <option value="I">Inactivos</option>
                                                </select>
                                            </td>
                                            <td id="td-der-selectPx-addFam">
                                                <button id="btn-limpiar-filtro" onClick={limpiarFiltro}>
                                                    <img src={img_clear} alt="img_clear" />
                                                    Limpiar
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="div_esFam_addFam">
                            <div className="radio-container-addFam">
                                <label className="lbl-esPac-addFam" htmlFor="select-esPac">¿El familiar que se ingresará es paciente?</label>
                                <label id="lbl-pac-si" for="pac-si">
                                    <input type="radio" id="pac-si" name="esPac-radio" value="si" onChange={handleEsPac} /> Si
                                </label>
                                <label id="lbl-pac-no" for="pac-no">
                                    <input type="radio" id="pac-no" name="esPac-radio" value="no" onChange={handleEsPac} /> No
                                </label>
                            </div>
                        </div>
                        <hr />
                        <div className="div-esPac1-fam">
                            {esPac === 'si' ? (
                                <table id="table-select-addFam">
                                    <tbody>
                                        <tr>
                                            <td id="td-izq-selectPx-addFam">
                                                <label className="label-paciente" htmlFor="select-paciente">Familiar del paciente:</label>
                                            </td>
                                            <td id="td-der-selectPx-addFam">
                                                <select id="select-paciente-addFam" onChange={cargarInfoFamiliarPaciente} required>
                                                    <option className="option_addFam" disabled selected >Selecciona una opción</option>
                                                    {pacientes.map((paciente) => (
                                                        <option className="selected-option-addFam" value={paciente.id_paciente}>
                                                            {paciente.nombre} {paciente.ap1} {paciente.ap2}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <form id="form_addFamiliar">
                                    <p>
                                        En caso de que el familiar esté relacionado con otros pacientes,
                                        es posible que ya esté registrado.
                                    </p>
                                    <p>
                                        Puedes realizar una búsqueda para confirmar.
                                    </p>
                                    <hr></hr>
                                    <table id="table-selectNo-addFam">
                                        <tbody>
                                            <tr>
                                                <td id="td-izq-selectPx-addFam">
                                                    <label className="label-paciente" htmlFor="select-paciente">Familiar:</label>
                                                </td>
                                                <td id="td-der-selectPx-addFam">
                                                    <select id="select-paciente-addFam" value={selectedValueFam} onChange={buscarFiltroFam} required>
                                                        <option className="option_addFam" value="" selected disabled>Selecciona una opción</option>
                                                        {getFamiliar.map((fam) => (
                                                            <option className="selected-option-addFam" value={fam.id_familiar}>
                                                                {fam.nombre} {fam.ap1} {fam.ap2}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                                <td>
                                                    <button type="button" id="btn-limpiar-filtro" onClick={limpiarFiltroFam}>
                                                        <img src={img_clear} alt="img_clear"></img>
                                                        Limpiar
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr></hr>
                                    <div className="div_buscadorFam_addfam">
                                        <div className="div_inputFam_fam">
                                            <div className="input_buscar_fam">
                                                <input type="search" placeholder="Buscar familiar..." id="Buscador" value={inputBuscarFam} onChange={buscarFam} />
                                                <img src={img_search} alt="buscar" id="img_lupa"></img>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <table id="table-addFam">
                                        <tbody>
                                            <tr>
                                                <td className="addFam-td-izq">
                                                    <label className="label-familiar">Familiar:</label>
                                                </td>
                                                <td className="addFam-td-der1">
                                                    <input type="text" id="input-nombre-addPx" placeholder="Nombre" value={nombre} onChange={handleNombre} required></input>
                                                </td>
                                                <td className="addFam-td-der2">
                                                    <input type="text" id="input-ap1-addPx" placeholder="Apellido paterno" value={ap1} onChange={handleAp1} required ></input>
                                                </td>
                                                <td className="addFam-td-der3">
                                                    <input type="text" id="input-ap2-addPx" placeholder="Apellido materno" value={ap2} onChange={handleAp2} required ></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="addFam-td-izq">
                                                    <label className="label-parentesco" htmlFor="select-parentesco-addFam">Parentesco:</label>
                                                </td>
                                                <td className="addFam-td-der1">
                                                    <select id="select-parentesco-addFam" value={parentesco} onChange={handleParentesco}>
                                                        <option value="elige" selected>Elige una opción</option>
                                                        <option value="padre">Padre</option>
                                                        <option value="madre">Madre</option>
                                                        <option value="hijo">Hijo</option>
                                                        <option value="hermano">Hermano</option>
                                                        <option value="tio">Tío</option>
                                                        <option value="abuelo">Abuelo</option>
                                                        <option value="primo">Primo</option>
                                                        <option value="esposo">Esposo</option>
                                                        <option value="esposa">Esposa</option>
                                                        <option value="amigo">Amigo</option>
                                                        <option value="padrastro">Padrastro</option>
                                                        <option value="madrastra">Madrastra</option>
                                                        <option value="suegro">Suegro</option>
                                                        <option value="cuñado">Cuñado</option>
                                                        <option value="consuegro">Consuegro</option>
                                                        <option value="concuño">Concuño</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="addFam-td-izq">
                                                    <label className="label-edad" htmlFor="input-edad-addPx">Edad:</label>
                                                </td>
                                                <td className="addFam-td-der1">
                                                    <input type="number" id="input-edad-addPx" value={edad} onChange={handleEdad} required ></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="addFam-td-izq">
                                                    <label className="label-sexo">Sexo:</label>
                                                </td>
                                                <td className="addFam-td-der1" colSpan={2}>
                                                    <div className="radio-container">
                                                        <label htmlFor="masculino">
                                                            <input type="radio" id="masculino" name="sexo" value="m" onChange={handleSexo} /> Masculino
                                                        </label>
                                                        <label htmlFor="femenino">
                                                            <input type="radio" id="femenino" name="sexo" value="f" onChange={handleSexo} /> Femenino
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="addFam-td-izq">
                                                    <label className="label-edo-civil" htmlFor="select-edo-civil-addPx">Estado civil:</label>
                                                </td>
                                                <td className="addFam-td-select">
                                                    <select id="select-edo-civil-addPx" defaultValue="elige" value={edoCivil} onChange={handleEdoCivil}>
                                                        <option value="elige">Elige una opción</option>
                                                        <option value="Casado">Casado</option>
                                                        <option value="Divorciado">Divorciado</option>
                                                        <option value="Soltero">Soltero</option>
                                                        <option value="Separado">Separado</option>
                                                        <option value="Viudo">Viudo</option>
                                                        <option value="Concubinato">Concubinato</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="addFam-td-izq">
                                                    <label className="label-tel" htmlFor="input-tel">Teléfono:</label>
                                                </td>
                                                <td className="addFam-td-der1">
                                                    <input type="tel" id="input-tel" value={tel} onChange={handleTel} required></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="addFam-td-izq">
                                                    <label className="label-correo" htmlFor="input-correo">Correo:</label>
                                                </td>
                                                <td className="addFam-td-der1-a" colSpan={2}>
                                                    <input type="email" id="input-correo" placeholder="Correo electrónico" value={correo} onChange={handleCorreo} ></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="addFam-td-izq">
                                                    <label className="label-domicilio" htmlFor="input-domicilio">Domicilio:</label></td>
                                                <td className="addFam-td-der1-a" colSpan={2}>
                                                    <input type="text" id="input-domicilio" placeholder="Calle, número..." value={domicilio} onChange={handleDomicilio} required ></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="addFam-td-izq">
                                                    <label className="label-ciudad" htmlFor="input-ciudad">Ciudad:</label>
                                                </td>
                                                <td className="addFam-td-der1-a" colSpan={2}>
                                                    <input type="text" id="input-ciudad" placeholder="Ciudad..." value={ciudad} onChange={handleCiudad} required ></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="addFam-td-izq">
                                                    <label className="label-cp" htmlFor="input-cp">Código Postal:</label>
                                                </td>
                                                <td className="addFam-td-der1">
                                                    <input type="number" id="input-cp" placeholder="Código postal" value={codPostal} onChange={handleCodPostal}></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="addFam-td-izq">
                                                    <label className="label-edo" htmlFor="select-edo-addPx">Estado:</label></td>
                                                <td className="addFam-td-select">
                                                    <select id="select-edo-addPx" defaultValue={"mi"} onChange={handleEstado}>
                                                        <option value="a">Aguascalientes</option>
                                                        <option value="b">Baja California</option>
                                                        <option value="bs">Baja California Sur</option>
                                                        <option value="c">Campeche</option>
                                                        <option value="ch">Chiapas</option>
                                                        <option value="chi">Chihuahua</option>
                                                        <option value="co">Coahuila</option>
                                                        <option value="col">Colima</option>
                                                        <option value="cd">Ciudad de México</option>
                                                        <option value="d">Durango</option>
                                                        <option value="em">Estado de México</option>
                                                        <option value="g">Guanajuato</option>
                                                        <option value="gu">Guerrero</option>
                                                        <option value="h">Hidalgo</option>
                                                        <option value="j">Jalisco</option>
                                                        <option value="mi">Michoacán</option>
                                                        <option value="mo">Morelos</option>
                                                        <option value="n">Nayarit</option>
                                                        <option value="nl">Nuevo León</option>
                                                        <option value="o">Oaxaca</option>
                                                        <option value="p">Puebla</option>
                                                        <option value="q">Querétaro</option>
                                                        <option value="qr">Quintana Roo</option>
                                                        <option value="sn">San Luis Potosí</option>
                                                        <option value="s">Sinaloa</option>
                                                        <option value="so">Sonora</option>
                                                        <option value="t">Tabasco</option>
                                                        <option value="ta">Tamaulipas</option>
                                                        <option value="tl">Tlaxcala</option>
                                                        <option value="v">Veracruz</option>
                                                        <option value="y">Yucatán</option>
                                                        <option value="z">Zacatecas</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="addFam-td-izq">
                                                    <label className="label-pais" htmlFor="select-pais-addPx">País:</label></td>
                                                <td className="addFam-td-select" colSpan={1}>
                                                    <select id="select-pais-addPx" defaultValue={"MX"} onChange={handlePais}>
                                                        <option value="AF">Afganistán</option>
                                                        <option value="AL">Albania</option>
                                                        <option value="DE">Alemania</option>
                                                        <option value="AD">Andorra</option>
                                                        <option value="AO">Angola</option>
                                                        <option value="AI">Anguilla</option>
                                                        <option value="AQ">Antártida</option>
                                                        <option value="AG">Barbuda</option>
                                                        <option value="AN">Holandesas</option>
                                                        <option value="SA">Arabia Saudí</option>
                                                        <option value="DZ">Argelia</option>
                                                        <option value="AR">Argentina</option>
                                                        <option value="AM">Armenia</option>
                                                        <option value="AW">Aruba</option>
                                                        <option value="AU">Australia</option>
                                                        <option value="AT">Austria</option>
                                                        <option value="AZ">Azerbaiyán</option>
                                                        <option value="BS">Bahamas</option>
                                                        <option value="BH">Bahrein</option>
                                                        <option value="BD">Bangladesh</option>
                                                        <option value="BB">Barbados</option>
                                                        <option value="BE">Bélgica</option>
                                                        <option value="BZ">Belice</option>
                                                        <option value="BJ">Benin</option>
                                                        <option value="BM">Bermudas</option>
                                                        <option value="BY">Bielorrusia</option>
                                                        <option value="MM">Birmania</option>
                                                        <option value="BO">Bolivia</option>
                                                        <option value="BA">Bosnia</option>
                                                        <option value="BW">Botswana</option>
                                                        <option value="BR">Brasil</option>
                                                        <option value="BN">Brunei</option>
                                                        <option value="BG">Bulgaria</option>
                                                        <option value="BF">Burkina Faso</option>
                                                        <option value="BI">Burundi</option>
                                                        <option value="BT">Bután</option>
                                                        <option value="CV">Cabo Verde</option>
                                                        <option value="KH">Camboya</option>
                                                        <option value="CM">Camerún</option>
                                                        <option value="CA">Canadá</option>
                                                        <option value="TD">Chad</option>
                                                        <option value="CL">Chile</option>
                                                        <option value="CN">China</option>
                                                        <option value="CY">Chipre</option>
                                                        <option value="VA">Vaticano</option>
                                                        <option value="CO">Colombia</option>
                                                        <option value="KM">Comores</option>
                                                        <option value="CG">Congo</option>
                                                        <option value="CD">Congo</option>
                                                        <option value="KR">Corea</option>
                                                        <option value="KP">Corea del Norte</option>
                                                        <option value="CI">Costa de Marfíl</option>
                                                        <option value="CR">Costa Rica</option>
                                                        <option value="HR">Croacia</option>
                                                        <option value="CU">Cuba</option>
                                                        <option value="DK">Dinamarca</option>
                                                        <option value="DJ">Djibouti</option>
                                                        <option value="DM">Dominica</option>
                                                        <option value="EC">Ecuador</option>
                                                        <option value="EG">Egipto</option>
                                                        <option value="SV">El Salvador</option>
                                                        <option value="AE">Emiratos Árabes U.</option>
                                                        <option value="ER">Eritrea</option>
                                                        <option value="SI">Eslovenia</option>
                                                        <option value="ES">España</option>
                                                        <option value="US">Estados Unidos</option>
                                                        <option value="EE">Estonia</option>
                                                        <option value="ET">Etiopía</option>
                                                        <option value="FJ">Fiji</option>
                                                        <option value="PH">Filipinas</option>
                                                        <option value="FI">Finlandia</option>
                                                        <option value="FR">Francia</option>
                                                        <option value="GA">Gabón</option>
                                                        <option value="GM">Gambia</option>
                                                        <option value="GE">Georgia</option>
                                                        <option value="GH">Ghana</option>
                                                        <option value="GI">Gibraltar</option>
                                                        <option value="GD">Granada</option>
                                                        <option value="GR">Grecia</option>
                                                        <option value="GL">Groenlandia</option>
                                                        <option value="GP">Guadalupe</option>
                                                        <option value="GU">Guam</option>
                                                        <option value="GT">Guatemala</option>
                                                        <option value="GY">Guayana</option>
                                                        <option value="GF">Guayana Francesa</option>
                                                        <option value="GN">Guinea</option>
                                                        <option value="GQ">Guinea Ecuatorial</option>
                                                        <option value="GW">Guinea-Bissau</option>
                                                        <option value="HT">Haití</option>
                                                        <option value="HN">Honduras</option>
                                                        <option value="HU">Hungría</option>
                                                        <option value="IN">India</option>
                                                        <option value="ID">Indonesia</option>
                                                        <option value="IQ">Irak</option>
                                                        <option value="IR">Irán</option>
                                                        <option value="IE">Irlanda</option>
                                                        <option value="BV">Isla Bouvet</option>
                                                        <option value="CX">Isla de Christmas</option>
                                                        <option value="IS">Islandia</option>
                                                        <option value="KY">Islas Caimán</option>
                                                        <option value="CK">Islas Cook</option>
                                                        <option value="CC">Islas de Cocos</option>
                                                        <option value="FO">Islas Faroe</option>
                                                        <option value="HM">Islas Heard</option>
                                                        <option value="FK">Islas Malvinas</option>
                                                        <option value="MP">Islas Marianas</option>
                                                        <option value="MH">Islas Marshall</option>
                                                        <option value="PW">Islas Palau</option>
                                                        <option value="SB">Islas Salomón</option>
                                                        <option value="SJ">Islas Svalbard</option>
                                                        <option value="TK">Islas Tokelau</option>
                                                        <option value="TC">Islas Turks</option>
                                                        <option value="IL">Israel</option>
                                                        <option value="IT">Italia</option>
                                                        <option value="JM">Jamaica</option>
                                                        <option value="JP">Japón</option>
                                                        <option value="JO">Jordania</option>
                                                        <option value="KZ">Kazajistán</option>
                                                        <option value="KE">Kenia</option>
                                                        <option value="KG">Kirguizistán</option>
                                                        <option value="KI">Kiribati</option>
                                                        <option value="KW">Kuwait</option>
                                                        <option value="LA">Laos</option>
                                                        <option value="LS">Lesotho</option>
                                                        <option value="LV">Letonia</option>
                                                        <option value="LB">Líbano</option>
                                                        <option value="LR">Liberia</option>
                                                        <option value="LY">Libia</option>
                                                        <option value="LI">Liechtenstein</option>
                                                        <option value="LT">Lituania</option>
                                                        <option value="LU">Luxemburgo</option>
                                                        <option value="MK">Macedonia</option>
                                                        <option value="MG">Madagascar</option>
                                                        <option value="MY">Malasia</option>
                                                        <option value="MW">Malawi</option>
                                                        <option value="MV">Maldivas</option>
                                                        <option value="ML">Malí</option>
                                                        <option value="MT">Malta</option>
                                                        <option value="MA">Marruecos</option>
                                                        <option value="MQ">Martinica</option>
                                                        <option value="MU">Mauricio</option>
                                                        <option value="MR">Mauritania</option>
                                                        <option value="YT">Mayotte</option>
                                                        <option value="MX">México</option>
                                                        <option value="FM">Micronesia</option>
                                                        <option value="MD">Moldavia</option>
                                                        <option value="MC">Mónaco</option>
                                                        <option value="MN">Mongolia</option>
                                                        <option value="MS">Montserrat</option>
                                                        <option value="MZ">Mozambique</option>
                                                        <option value="NA">Namibia</option>
                                                        <option value="NR">Nauru</option>
                                                        <option value="NP">Nepal</option>
                                                        <option value="NI">Nicaragua</option>
                                                        <option value="NE">Níger</option>
                                                        <option value="NG">Nigeria</option>
                                                        <option value="NU">Niue</option>
                                                        <option value="NF">Norfolk</option>
                                                        <option value="NO">Noruega</option>
                                                        <option value="NC">Nueva Caledonia</option>
                                                        <option value="NZ">Nueva Zelanda</option>
                                                        <option value="OM">Omán</option>
                                                        <option value="NL">Países Bajos</option>
                                                        <option value="PA">Panamá</option>
                                                        <option value="PG">Papúa Nueva Guinea</option>
                                                        <option value="PK">Paquistán</option>
                                                        <option value="PY">Paraguay</option>
                                                        <option value="PE">Perú</option>
                                                        <option value="PN">Pitcairn</option>
                                                        <option value="PF">Polinesia Francesa</option>
                                                        <option value="PL">Polonia</option>
                                                        <option value="PT">Portugal</option>
                                                        <option value="PR">Puerto Rico</option>
                                                        <option value="QA">Qatar</option>
                                                        <option value="UK">Reino Unido</option>
                                                        <option value="CF">Rep. Centroafricana</option>
                                                        <option value="CZ">Rep. Checa</option>
                                                        <option value="ZA">Rep. de Sudáfrica</option>
                                                        <option value="DO">Rep. Dominicana</option>
                                                        <option value="SK">Rep. Eslovaca</option>
                                                        <option value="RE">Reunión</option>
                                                        <option value="RW">Ruanda</option>
                                                        <option value="RO">Rumania</option>
                                                        <option value="RU">Rusia</option>
                                                        <option value="EH">Sahara Occidental</option>
                                                        <option value="KN">Saint Kitts</option>
                                                        <option value="WS">Samoa</option>
                                                        <option value="AS">Samoa Americana</option>
                                                        <option value="SM">San Marino</option>
                                                        <option value="VC">San Vicente</option>
                                                        <option value="SH">Santa Helena</option>
                                                        <option value="LC">Santa Lucía</option>
                                                        <option value="ST">Santo Tomé</option>
                                                        <option value="SN">Senegal</option>
                                                        <option value="SC">Seychelles</option>
                                                        <option value="SL">Sierra Leona</option>
                                                        <option value="SG">Singapur</option>
                                                        <option value="SY">Siria</option>
                                                        <option value="SO">Somalia</option>
                                                        <option value="LK">Sri Lanka</option>
                                                        <option value="PM">St Pierre</option>
                                                        <option value="SZ">Suazilandia</option>
                                                        <option value="SD">Sudán</option>
                                                        <option value="SE">Suecia</option>
                                                        <option value="CH">Suiza</option>
                                                        <option value="SR">Surinam</option>
                                                        <option value="TH">Tailandia</option>
                                                        <option value="TW">Taiwán</option>
                                                        <option value="TZ">Tanzania</option>
                                                        <option value="TJ">Tayikistán</option>
                                                        <option value="TP">Timor Oriental</option>
                                                        <option value="TG">Togo</option>
                                                        <option value="TO">Tonga</option>
                                                        <option value="TT">Trinidad y Tobago</option>
                                                        <option value="TN">Túnez</option>
                                                        <option value="TM">Turkmenistán</option>
                                                        <option value="TR">Turquía</option>
                                                        <option value="TV">Tuvalu</option>
                                                        <option value="UA">Ucrania</option>
                                                        <option value="UG">Uganda</option>
                                                        <option value="UY">Uruguay</option>
                                                        <option value="UZ">Uzbekistán</option>
                                                        <option value="VU">Vanuatu</option>
                                                        <option value="VE">Venezuela</option>
                                                        <option value="VN">Vietnam</option>
                                                        <option value="YE">Yemen</option>
                                                        <option value="YU">Yugoslavia</option>
                                                        <option value="ZM">Zambia</option>
                                                        <option value="ZW">Zimbabue</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            )}
                        </div>
                        <div className="div-pacFam-addFam">
                            {
                                esPac === 'si' ? (
                                    <div>
                                        <div id="div-siPxFoto-esFam">
                                            <div id="div-siPxFoto1-esFam">
                                                <label>{infoFamPac.nombre}&nbsp;{infoFamPac.ap1}&nbsp;{infoFamPac.ap2}</label>
                                            </div>
                                            <div id="div-siPxFoto2-esFam">
                                                <img alt="img_fam" src={img_avatar}></img>
                                            </div>
                                        </div>
                                        <div id="div-siPxParent-esFam">
                                            <label>Selecciona el parentesco: </label>
                                            <select id="select-parentesco-addFam" onChange={handleParentesco}>
                                                <option value="elige" selected>Elige una opción</option>
                                                <option value="padre">Padre</option>
                                                <option value="madre">Madre</option>
                                                <option value="hijo">Hijo</option>
                                                <option value="hermano">Hermano</option>
                                                <option value="tio">Tío</option>
                                                <option value="abuelo">Abuelo</option>
                                                <option value="primo">Primo</option>
                                                <option value="esposo">Esposo</option>
                                                <option value="esposa">Esposa</option>
                                                <option value="amigo">Amigo</option>
                                                <option value="padrastro">Padrastro</option>
                                                <option value="madrastra">Madrastra</option>
                                                <option value="suegro">Suegro</option>
                                                <option value="cuñado">Cuñado</option>
                                                <option value="consuegro">Consuegro</option>
                                                <option value="concuño">Concuño</option>
                                            </select>
                                        </div>
                                        <div id="div-siPx-esFam">
                                            <table id="table-siPx-addFam">
                                                <tbody>
                                                    <tr>
                                                        <td id="siPx-td-izq">
                                                            <label>Edad:</label>
                                                        </td>
                                                        <td id="siPx-td-der1">
                                                            <lable>{infoFamPac.edad}</lable><br />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td id="siPx-td-izq">
                                                            <label>Estado civil:</label>
                                                        </td>
                                                        <td id="siPx-td-der1">
                                                            <label>{infoFamPac.edo_civil}</label><br />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td id="siPx-td-izq">
                                                            <label>Profesión:</label>
                                                        </td>
                                                        <td id="siPx-td-der1">
                                                            <label>{infoFamPac.profesion}</label><br />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td id="siPx-td-izq">
                                                            <label>Trabajo actual:</label>
                                                        </td>
                                                        <td id="siPx-td-der1">
                                                            <label>{infoFamPac.trabajo_actual}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td id="siPx-td-izq">
                                                            <label>Correo:</label>
                                                        </td>
                                                        <td id="siPx-td-der1">
                                                            <label>{infoFamPac.correo}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td id="siPx-td-izq">
                                                            <label>Teléfono:</label>
                                                        </td>
                                                        <td id="siPx-td-der1">
                                                            <label>{infoFamPac.tel}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td id="siPx-td-izq">
                                                            <label>Ciudad:</label>
                                                        </td>
                                                        <td id="siPx-td-der1">
                                                            <label>{infoFamPac.ciudad}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td id="siPx-td-izq">
                                                            <label>Domicilio:</label>
                                                        </td>
                                                        <td id="siPx-td-der1">
                                                            <label>{infoFamPac.domicilio}</label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : (<div></div>)
                            }
                        </div>
                    </div>
                    <div className="div-autoregistro-botones-addFam">
                        <div className="div-btn-alineacion-addFam">
                            <div className="div-addFam-enviar">
                                <button value="enviar" id="btn-enviar-addFam" onClick={handleSubmit}>Enviar</button>
                            </div>
                            <div className="div-addFam-cancelar">
                                <button value="cancelar" id="btn-cancelar-addFam" onClick={handleSalir}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default AddFamiliar;