
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { parse, format } from 'date-fns';
import { useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";
import CitasService from "../../services/CitasService";
import PendientesService from "../../services/PendientesService";


import FormularioRegistroCita from "../Agenda/FormularioRegistroCita";
import FormularioRegistroCitaPX from "../Agenda/FormularioEditarCitaPX";
import FormularioEditarCita from "../Agenda/FormularioEditarCita";
import FormularioEditarCitaPX from "../Agenda/FormularioRegistroCitaPX";

import "../../styles/Calendario.css";
import "../../styles/FormularioEditarCita.css";
import "../../styles/Pacientes.css";
import "../../styles/FullCalendar.css";

import img_edit from "../../img/edit_note.svg";
import SesionesService from "../../services/SesionesService";



const Calendario = () => {
    const [pacientes, setPacientes] = useState([]);
    const [psicologos, setPsicologos] = useState([]);
    const [eventos, setEventos] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [pendientes, setPendientes] = useState([]);


    const formatosFecha = ["DD-MM-YYYY", "DD/MM/YYYY"];
    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);  // usuario que ingresó al sistema
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    function refreshPage() {
        window.location.reload(false);
    }

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    const getData = async () => {
        if (usuario.rol === "psi") {
            const data = await CitasService.getCitasPS(usuario.id_psicologo);
            setEventos(data.map(evento => ({
                title: (evento.nombre + " " + evento.ap1 + " " + evento.ap2),
                start: `${evento.fecha}T${evento.hora_i}:00`,
                end: `${evento.fecha}T${evento.hora_f}:00`,
                editable: true
            })));
            const data2 = await PacientesService.getPacientesCalendario();
            setPacientes(data2);
        } else if (usuario.rol === "pax") {
            const data = await CitasService.getCitasPacientePX(usuario.id_paciente);
            const data2 = await CitasService.getCitasPsicologoPX(usuario.psicologo, usuario.id_paciente);
            console.log(data2);
            const eventosCombinados = [...data.map(evento => ({
                title: (evento.nombre + " " + evento.ap1 + " " + evento.ap2),
                start: `${evento.fecha}T${evento.hora_i}:00`,
                end: `${evento.fecha}T${evento.hora_f}:00`,
                editable: true,
                description: 'Paciente'
            })), ...data2.map(evento => ({
                title: (evento.nombre + " " + evento.ap1 + " " + evento.ap2),
                start: `${evento.fecha}T${evento.hora_i}:00`,
                end: `${evento.fecha}T${evento.hora_f}:00`,
                color: 'gray',
                description: 'Psicologo'
            }))]
            setEventos(eventosCombinados);
            const data3 = await PsicologoService.getPsicologos();
            setPsicologos(data3);
        }
    };


    const getDataPendientes = async () => {
        const data = await PendientesService.getPendientes(usuario.id_psicologo);
        setPendientes(data);
    };

    useEffect(() => {
        getData();
        getDataPendientes();
    }, []);

    const verficarSolapamiento = async (fecha, horaI, horaF, id_psicologo) => {
        const data = await CitasService.comprobarCita(fecha, horaI, horaF, id_psicologo);
        if (data[0] === undefined) {
            console.log("si")
            return true;
        } else {
            console.log("no")
            return false;
        }
    }


    function verificarCitaAnterior(fechaAnterior, fecha) {
        const lastAppointmentDate = new Date(fechaAnterior); // Fecha de la última cita
        const currentDate = new Date(fecha); // Fecha actual
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Un día en milisegundos
        console.log(currentDate - lastAppointmentDate);
        const daysDifference = Math.floor((currentDate - lastAppointmentDate) / oneDayInMilliseconds);
        console.log(daysDifference);
        if (daysDifference >= 7) {
            return true;
        } else {
            console.log("NO");
            return false;
        }
    }

    function verficarTiempo(horaI, horaF) {
        const tInicio = new Date();
        const pInicio = horaI.split(':');
        tInicio.setHours(parseInt(pInicio[0]), parseInt(pInicio[1]));

        const tFin = new Date();
        const pFin = horaF.split(':');
        tFin.setHours(parseInt(pFin[0]), parseInt(pFin[1]));

        const diferenciaEnMilisegundos = Math.abs(tFin - tInicio);
        const minutosDiferencia = Math.floor(diferenciaEnMilisegundos / (1000 * 60));
        console.log(minutosDiferencia);
        return minutosDiferencia;
    }

    function validarFecha(fecha) {
        console.log(fecha)
        return moment(fecha, formatosFecha, true).isValid();
    }

    function validarHora(hora) {
        return moment(hora, "HH:mm", true).isValid();
    }

    function sumarMediaHora(hora) {
        const horaActual = new Date(hora); // Crea un objeto Date a partir de la hora recibida
        horaActual.setMinutes(horaActual.getMinutes() + 60); // Suma 60 minutos
        return horaActual;
    }

    const guardarDatos = async (datos) => {
        console.log(datos);
        if (datos.paciente === "Selecciona una opción") {
            //Swal.fire("Asegurate de seleccionar un paciente", "", "error");
            Toast.fire("Asegurate de seleccionar un paciente", "", "error");
        } else {
            console.log(validarFecha(datos.fecha))
            if (validarFecha(datos.fecha)) {
                if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                    if (datos.horaI >= datos.horaF) {
                        Toast.fire("Asegurate de que la hora de inicio se menor que la de fin!", "", "error");
                    } else {
                        const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                        const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.id_psicologo);
                        if (solapamiento === true) {
                            let cita = {
                                id_paciente: parseInt(datos.paciente),
                                id_psicologo: usuario.id_psicologo,
                                fecha: fechaFormateada,
                                hora_i: datos.horaI,
                                hora_f: datos.horaF,
                                estatus: "CONFIRMADA",
                                comentario: datos.comentario,
                            }
                            try {
                                const respuestaCita = await CitasService.crearCita(cita);
                                const ultimaCita = await CitasService.getUltimaCita(usuario.id_psicologo);
                                console.log(ultimaCita);
                                let sesion = {
                                    sesion: "",
                                    fk_cita_sesion: ultimaCita.id_cita,
                                }
                                const respuestaSesion = await SesionesService.crearSesion(sesion);
                                if (respuestaCita.status === 200 && respuestaSesion.status === 200) {
                                    Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                        if (result.isConfirmed) {
                                            refreshPage();
                                        }
                                    }).then(function () {
                                        refreshPage();
                                    });
                                } else {
                                    Toast.fire("Hubo un problema al registrar la cita!", "", "error");
                                }
                            } catch (error) {
                                Toast.fire("Hubo un problema al registrar la cita!", "", "error");
                                console.log('Error al realizar la inserción:', error.message);
                            }
                        } else {
                            Toast.fire("Ya hay una cita en ese horario", "", "error");
                        }
                    }
                } else {
                    Toast.fire("Hora incorrecta, asegurate que el formato sea HH:MM!", "", "error");
                }
            } else {
                Toast.fire("¡Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA!", "", "error");
            }
        }
    };

    const editarDatos = async (datos, idCita, estatus) => {
        console.log(datos);
        console.log(idCita);
        if (datos.paciente === "Selecciona una opción") {
            Toast.fire("Asegurate de seleccionar un paciente", "", "error");
        } else {
            if (validarFecha(datos.fecha)) {
                if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                    if (datos.horaI >= datos.horaF) {
                        Toast.fire("Asegurate de que la hora de inicio se menor que la de fin!", "", "error");
                    } else {
                        const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                        const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.id_psicologo);
                        if (solapamiento === true) {
                            let cita = {
                                id_paciente: parseInt(datos.paciente),
                                id_psicologo: usuario.id_psicologo,
                                fecha: fechaFormateada,
                                hora_i: datos.horaI,
                                hora_f: datos.horaF,
                                estatus: estatus,
                                comentario: datos.comentario,
                                id_cita: idCita,
                            }
                            try {
                                const respuesta = await CitasService.editarCita(cita, idCita);
                                console.log(respuesta.status)
                                if (respuesta.status === 200) {
                                    Toast.fire("Se editó la cita correctamente!", "", "success").then((result) => {
                                        if (result.isConfirmed) {
                                            refreshPage();
                                        }
                                    }).then(function () {
                                        refreshPage();
                                    });
                                } else {
                                    Toast.fire("Hubo un problema al registrar la cita", "", "error");
                                }
                            } catch (error) {
                                Toast.fire("Hubo un problema al registrar la cita", "", "error");
                                console.log('Error al realizar la inserción:', error.message);
                            }
                        } else {
                            Toast.fire("Ya hay una cita en ese horario", "", "error");
                        }
                    }
                } else {
                    Toast.fire("Hora incorrecta, asegurate que el formato sea HH:MM!", "", "error");
                }
            } else {
                Toast.fire("¡Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA!", "", "error");
            }
        }
    };

    const eliminarDatos = async (idCita) => {
        const respuestaSesion = await SesionesService.eliminarSesion(idCita);
        const respuestaCita = await CitasService.eliminarCita(idCita);
        if (respuestaCita.status === 200 && respuestaSesion.status === 200) {
            Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    refreshPage();
                }
            }).then(function () {
                refreshPage();
            });
        } else {
            Toast.fire("Hubo un problema al eliminar la cita!", "", "error");
        }
    };

    //Paciente
    const guardarDatosPX = async (datos) => {
        console.log(datos);
        if (datos.psicologo === "Selecciona una opción") {
            Toast.fire("Asegurate de seleccionar un psicologo", "", "error");
        } else {
            if (validarFecha(datos.fecha)) {
                if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                    if (datos.horaI >= datos.horaF) {
                        Toast.fire("Asegurate de que la hora de inicio se menor que la de fin!", "", "error");
                    } else {
                        const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                        const data = await CitasService.verificarCitaAnterior(usuario.id_paciente);
                        const citaAnt = verificarCitaAnterior(data[0].fecha, fechaFormateada);
                        if (citaAnt) {
                            const tiempo = verficarTiempo(datos.horaI, datos.horaF);
                            if (tiempo < 60 || tiempo > 60) {
                                Toast.fire("La cita tiene que durar una hora!", "", "error");
                            } else if (tiempo === 60) {
                                const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);
                                if (solapamiento === true) {
                                    let cita = {
                                        id_paciente: usuario.id_paciente,
                                        id_psicologo: parseInt(datos.psicologo),
                                        fecha: fechaFormateada,
                                        hora_i: datos.horaI,
                                        hora_f: datos.horaF,
                                        estatus: "CONFIRMADA",
                                        comentario: datos.comentario,
                                    }
                                    try {
                                        const respuesta = await CitasService.crearCita(cita);
                                        console.log(respuesta.status)
                                        if (respuesta.status === 200) {
                                            Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                                if (result.isConfirmed) {
                                                    refreshPage();
                                                }
                                            });
                                        } else {
                                            Toast.fire("Hubo un problema al registrar la cita!", "", "error");
                                        }
                                    } catch (error) {
                                        Toast.fire("Hubo un problema al registrar la cita!", "", "error");
                                        console.log('Error al realizar la inserción:', error.message);
                                    }
                                } else {
                                    Toast.fire("Ya hay una cita en ese horario", "", "error");
                                }
                            }
                        } else {
                            Toast.fire("Para agendar una cita, tiene que pasar al menos una semana de la cita anterior", "", "error");
                        }
                    }
                } else {
                    Toast.fire("Hora incorrecta, asegurate que el formato sea HH:MM!", "", "error");
                }
            } else {
                Toast.fire("¡Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA!", "", "error");
            }
        }
    };

    const editarDatosPX = async (datos, idCita) => {
        console.log(datos);
        console.log(idCita);
        if (datos.psicologo === "Selecciona una opción") {
            Toast.fire("Asegurate de seleccionar un psicologo", "", "error");
        } else {
            if (validarFecha(datos.fecha)) {
                if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                    if (datos.horaI >= datos.horaF) {
                        Toast.fire("Asegurate de que la hora de inicio se menor que la de fin!", "", "error");
                    } else {
                        const fechaFormateada = format(parse(datos.fecha, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                        const data = await CitasService.verificarCitaAnterior(usuario.id_paciente);
                        const citaAnt = verificarCitaAnterior(data[0].fecha, fechaFormateada);
                        if (citaAnt) {
                            const tiempo = verficarTiempo(datos.horaI, datos.horaF);
                            if (tiempo < 60 || tiempo > 60) {
                                Toast.fire("La cita tiene que durar una hora!", "", "error");
                            } else if (tiempo === 60) {
                                const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);
                                if (solapamiento === true) {
                                    let cita = {
                                        id_paciente: usuario.id_paciente,
                                        id_psicologo: parseInt(datos.psicologo),
                                        fecha: fechaFormateada,
                                        hora_i: datos.horaI,
                                        hora_f: datos.horaF,
                                        estatus: datos.estatus,
                                        comentario: datos.comentario,
                                        id_cita: idCita,
                                    }
                                    try {
                                        const respuesta = await CitasService.editarCita(cita, idCita);
                                        console.log(respuesta.status)
                                        if (respuesta.status === 200) {
                                            Toast.fire("Se editó la cita correctamente!", "", "success").then((result) => {
                                                if (result.isConfirmed) {
                                                    refreshPage();
                                                }
                                            });
                                        } else {
                                            Toast.fire("Hubo un problema al registrar la cita!", "", "error");
                                        }
                                    } catch (error) {
                                        Toast.fire("Hubo un problema al registrar la cita!", "", "error");
                                        console.log('Error al realizar la inserción:', error.message);
                                    }
                                } else {
                                    Toast.fire("Ya hay una cita en ese horario", "", "error");
                                }
                            }
                        } else {
                            Toast.fire("Para agendar una cita, tiene que pasar al menos una semana de la cita anterior", "", "error");
                        }
                    }
                } else {
                    Toast.fire("Hora incorrecta, asegurate que el formato sea HH:MM!", "", "error");
                }
            } else {
                Toast.fire("¡Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA!", "", "error");
            }
        }
    };

    const eliminarDatosPX = async (idCita) => {
        const respuesta = await CitasService.eliminarCita(idCita);
        if (respuesta.status === 200) {
            Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    refreshPage();
                }
            });
        } else {
            Toast.fire("Hubo un problema al eliminar la cita!", "", "error");
        }
    };


    // Componente MODAL de agendar cita por el psicolgo
    const agendarCita = async (info) => {
        console.log(info);
        const fechaF = moment(info.date).format('YYYY-MM-DD');
        const horaI = moment(info.date).format('HH:mm');
        const horaF = sumarMediaHora(info.date);
        const horaFForm = moment(horaF).format('HH:mm');
        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioRegistroCita pacientes={pacientes} fechaF={fechaF} horaI={horaI} horaF={horaFForm} />
        );
        Swal.fire({
            title: "Agendar cita",
            html: formularioHtml,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Guardar",
            cancelButtonText: "Nuevo paciente",
            preConfirm: () => {
                const paciente = document.getElementById("select-paciente").value;
                const fecha = moment(document.getElementById("input-fecha").value).format('DD-MM-YYYY');
                const horaI = document.getElementById("input-horaI").value;
                const horaF = document.getElementById("input-horaF").value;
                const comentario = document.getElementById("input-comentario").value;
                return { paciente, fecha, horaI, horaF, comentario };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                guardarDatos(datos);
            } else if (result.dismiss === 'cancel') {
                //navigate('/addpacienteexpress');        // entorno local
                navigate('/addpacienteexpress');        // entorno web
            }
        });
    };

    const eliminarCita = async (info) => {
        console.log(info);
        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');
        const data = await CitasService.getInfoCita(fechaF, horaI, horaF);
        Swal.fire({
            title: "Eliminar cita",
            text: "¿Esta seguro de eliminar la cita?",
            showCancelButton: true,
            showConfirmButton: false,
            showCloseButton: true,
            showDenyButton: true,
            denyButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isDenied) {
                eliminarDatos(data[0].id_cita);
            }
        });
    };


    const editarCita = async (info) => {
        console.log(info);
        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fecha = moment(info.event.start).format('YYYY-MM-DD');
        const data = await CitasService.getInfoCita(fecha, horaI, horaF);
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');
        let pac;
        for (let i = 0; i < pacientes.length; i++) {
            if (pacientes[i].id_paciente === data[0].id_paciente) {
                pac = pacientes[i]
            }
        }
        console.log(data[0])
        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioEditarCita pacientes={pacientes} fechaF={fechaF} horaI={horaI} horaF={horaF} comentario={data[0].comentario} pac={pac} estatus={data[0].estatus} />
        );
        Swal.fire({
            title: "Editar cita",
            html: formularioHtml,
            // showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: "Guardar cambios",
            // cancelButtonText: "Cancelar",
            preConfirm: () => {
                const paciente = document.getElementById("select-px-editar").value;
                const fecha = moment(document.getElementById("input-fecha-editar").value).format('DD-MM-YYYY');
                const horaI = document.getElementById("input-horaI-editar").value;
                const horaF = document.getElementById("input-horaF-editar").value;
                const comentario = document.getElementById("input-comentario-editar").value;
                /*const estatus = document.getElementById("select-estatus").value;*/
                return { paciente, fecha, horaI, horaF, comentario };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                editarDatos(datos, data[0].id_cita, data[0].estatus);
            }
        });
    };


    // Informacion modal paciente agendado,  sesión Psicólogo
    const informacionPaciente = async (info) => {
        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fechaF, horaI, horaF);

        console.log("informacionPaciente");
        console.log(data);

        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }

        cargarInformacion(data[0].id_paciente);
        //cargarInformacionFamiliares(data[0].id_paciente);

        MySwal.fire({
            title: 'Paciente agendado',
            html: (
                <div className="datos-paciente-modal">
                    <table className="modal-pax-agendado">
                        <tr className="padding-row">
                            <td id="td-img-agendado">
                                <img src={data[0].fotografia} alt="avatar" id="img_avatar" />
                            </td>
                            <td>
                                { /*    entorno local
                                <a href={`/pacientes/${data[0].id_paciente}`}>
                                    <label id="lbl-px-agendado">{data[0].nombre} {data[0].ap1} {data[0].ap2}</label>
                                </a>
                                    */}

                                {/* entorno web   */}
                                <a href={`#/pacientes/${data[0].id_paciente}`}>
                                    <label id="lbl-px-agendado">{data[0].nombre} {data[0].ap1} {data[0].ap2}</label>
                                </a>

                            </td>
                        </tr>
                        <tr className="padding-row">
                            <td><label className="lbl-fecha-agendado" htmlFor="lbl-fecha-agendado">Fecha:</label></td>
                            <td><label id="lbl-fecha-agendado">{data[0].fecha}</label></td>
                        </tr>
                        <tr className="padding-row">
                            <td><label className="lbl-horaI-agendado" htmlFor="lbl-horaI-agendado">Horario:</label></td>
                            <td><label id="lbl-horaI-agendado">{data[0].hora_i} - {data[0].hora_f}</label></td>
                        </tr>
                        <tr className="padding-row">
                            <td><label className="lbl-edad-agendado" htmlFor="lbl-edad-agendado">Edad:</label></td>
                            <td><label id="lbl-edad-agendado">{data[0].edad}</label></td>
                        </tr>
                        <tr className="padding-row">
                            <td><label className="lbl-trabajo-agendado" htmlFor="lbl-trabajo-agendado">Trabajo actual:</label></td>
                            <td><label id="lbl-trabajo-agendado">{data[0].trabajo_actual}</label></td>
                        </tr>
                        <tr className="padding-row">
                            <td><label className="lbl-telefono-agendado" htmlFor="lbl-telefono-agendado">Telefono:</label></td>
                            <td><label id="lbl-telefono-agendado">{data[0].tel}</label></td>
                        </tr>
                        <tr className="padding-row">
                            <td><label className="lbl-ciudad-agendado" htmlFor="lbl-ciudad-agendado">Ciudad:</label></td>
                            <td><label id="lbl-ciudad-agendado">{data[0].ciudad}</label></td>
                        </tr>
                    </table>
                </div>
            ),
            showCancelButton: true,
            showDenyButton: true,
            showCloseButton: true,
            confirmButtonText: 'Editar cita',
            cancelButtonText: 'Ir a sesión',
            denyButtonText: 'Cancelar cita',
        }).then((result) => {
            if (result.isConfirmed) {
                editarCita(info);
            } else if (result.isDenied) {
                eliminarCita(info);
            } else if (result.dismiss === 'cancel') {
                // navigate(`/sesiones/${data[0].id_paciente}`);       // entorno local
                navigate(`/sesiones/${data[0].id_paciente}`);       // entorno web
            }
        });
    }


    //Paciente
    const agendarCitaPX = async (info) => {
        console.log(info);
        const fechaF = moment(info.date).format('DD-MM-YYYY');
        const horaI = moment(info.date).format('HH:mm');
        const horaF = sumarMediaHora(info.date);
        const horaFForm = moment(horaF).format('HH:mm');
        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioRegistroCitaPX psicologos={psicologos} fechaF={fechaF} horaI={horaI} horaF={horaFForm} />
        );
        Swal.fire({
            title: "Agendar cita",
            html: formularioHtml,
            showCancelButton: true,
            confirmButtonText: "Guardar",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const psicologo = document.getElementById("select-psicologo").value;
                const fecha = document.getElementById("input-fecha").value;
                const horaI = document.getElementById("input-horaI").value;
                const horaF = document.getElementById("input-horaF").value;
                const comentario = document.getElementById("input-comentario").value;
                return { psicologo, fecha, horaI, horaF, comentario };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                guardarDatosPX(datos);
            }
        });
    }

    const eliminarCitaPX = async (info) => {
        console.log(info);
        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');
        const data = await CitasService.getInfoCita(fechaF, horaI, horaF);
        Swal.fire({
            title: "Eliminar cita",
            text: "¿Esta seguro de eliminar la cita?",
            showCancelButton: true,
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isDenied) {
                eliminarDatos(data[0].id_cita);
            }
        });
    }

    const editarCitaPX = async (info) => {
        console.log(info);
        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fecha = moment(info.event.start).format('YYYY-MM-DD');
        console.log(fecha, horaI, horaF);
        const data = await CitasService.getInfoCita(fecha, horaI, horaF);
        const fechaF = moment(info.event.start).format('DD-MM-YYYY');
        console.log(data);
        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioEditarCitaPX psicologos={psicologos} fechaF={fechaF} horaI={horaI} horaF={horaF} comentario={data[0].comentario} psi={data[0].fk_psicologo} estatus={data[0].estatus} />
        );
        Swal.fire({
            title: "Editar cita",
            html: formularioHtml,
            showCancelButton: true,
            confirmButtonText: "Guardar cambios",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const psicologo = document.getElementById("select-psicologo").value;
                const fecha = document.getElementById("input-fecha").value;
                const horaI = document.getElementById("input-horaI").value;
                const horaF = document.getElementById("input-horaF").value;
                const comentario = document.getElementById("input-comentario").value;
                const estatus = document.getElementById("select-estatus").value;
                return { psicologo, fecha, horaI, horaF, comentario, estatus };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                editarDatosPX(datos, data[0].id_cita);
            }
        });
    }

    // Informacion modal paciente en rol Paciente
    const informacionPacientePX = async (info) => {
        if (info.event.extendedProps.description === 'Paciente') {
            const horaI = moment(info.event.start).format('HH:mm');
            const horaF = moment(info.event.end).format('HH:mm');
            const fechaF = moment(info.event.start).format('YYYY-MM-DD');
            console.log(fechaF);
            const data = await CitasService.getInfoCita(fechaF, horaI, horaF);
            console.log(data);
            if (data[0].sexo === "m" || data[0].sexo === "M") {
                data[0].sexo = "Masculino"
            } else if (data[0].sexo === "f" || data[0].sexo === "F") {
                data[0].sexo = "Femenino"
            }
            if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
                data[0].edo_civil = "Soltero"
            } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
                data[0].edo_civil = "Casado"
            } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
                data[0].edo_civil = "Divorciado"
            } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
                data[0].edo_civil = "Viudo"
            }

            MySwal.fire({
                title: 'Cita agendada',
                html: (
                    <div className="datos-paciente-modal">
                        <table className="table-datos-modal">
                            <tr className="padding-row">
                                <td><label className="label-paciente" htmlFor="lbl-paciente">Fecha:</label></td>
                                <td><label id="lbl-paciente"></label>Paciente paciente paciente</td>
                            </tr>
                            <tr className="padding-row">
                                <td><label className="label-fecha" htmlFor="lbl-fecha">Fecha:</label></td>
                                <td><label id="lbl-fecha"></label>{data[0].fecha}</td>
                            </tr>
                            <tr className="padding-row">
                                <td><label className="label-horaI" htmlFor="lbl-horaI">Hora inicio:</label></td>
                                <td><label id="lbl-horaI"></label>{data[0].hora_i}</td>
                            </tr>
                            <tr className="padding-row">
                                <td><label className="label-horaF" htmlFor="lbl-horaF">Hora fin:</label></td>
                                <td><label id="lbl-horaF"></label>{data[0].hora_f}</td>
                            </tr>
                            {/* <tr className="padding-row">
                            <td><label className="label-paciente" htmlFor="lbl-paciente">Paciente:</label></td>
                            <td><label id="lbl-paciente">{data[0].nombre} {data[0].ap1} {data[0].ap2}</label></td>
                        </tr> */}

                        </table>
                    </div>
                ),
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: 'Editar cita',
                cancelButtonText: 'Cancelar',
                denyButtonText: 'Eliminar cita',
            }).then((result) => {
                if (result.isConfirmed) {
                    editarCitaPX(info);
                } else if (result.isDenied) {
                    // Lógica a ejecutar cuando se presiona el tercer botón
                    eliminarCitaPX(info);
                }
            });
        }
    }

    // const cargarInformacionFamiliares = async (id_paciente) => {
    //     //const dataFam = await FamiliaresService.getFamiliar(id_paciente);
    //     //setFamiliares(dataFam[0]);
    // }

    const cargarInformacion = async (id_paciente) => {
        const data = await PacientesService.getInfoPaciente(id_paciente);

        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }

        setInformacion(data[0]);

        //cargarInformacionFamiliares(data[0]);

        console.log("setInformacion");
        console.log(data[0]);

    }

    const handleComentario = (index, comentario) => {
        const pendienteComentario = pendientes[index]
        pendienteComentario.comentario = comentario;
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }



    return (
        <Layout>
            <Content>
                <div className="vista-calendario">
                    <div className="encabezado-usuario">
                        <div className="px_sesion_citas">
                            <label id="lbl-enSesion">{tipoUsuario()} {usuario.nombre}</label>
                        </div>
                    </div>

                    {usuario.rol === "psi" ? (
                        <div className="calendario-datos">
                            <div className="calend">
                                {eventos !== undefined ? (
                                    <FullCalendar
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'listDay, dayGridMonth,timeGridWeek,multiMonthYear' // Agrega las vistas aquí
                                        }}
                                        buttonText={{
                                            day: 'Dia',
                                            today: 'Hoy', // Cambia el texto del botón "Hoy"
                                            month: 'Mes', // Cambia el texto del botón "Mes"
                                            week: 'Semana', // Cambia el texto del botón "Semana"
                                            year: 'Año' // Cambia el texto del botón "Día"
                                        }}
                                        locales={[esLocale]}
                                        slotLabelFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hourCycle: "h12",
                                            meridiem: 'short',
                                        }}
                                        eventTimeFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hourCycle: "h12",
                                        }}
                                        nowIndicator={true}
                                        slotMinTime="07:00:00"
                                        slotMaxTime="22:00:00"
                                        allDaySlot={false}
                                        themeSystem="pulse"
                                        plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                                        initialView="timeGridWeek"
                                        events={eventos}
                                        dateClick={agendarCita}
                                        eventClick={informacionPaciente}
                                    />
                                ) : (
                                    <p>Cargando eventos... </p>
                                )}
                            </div>

                            <div className="datos-paciente">
                                <div className="edit_delete-pendientes">
                                    <div id="px_h3_calend">
                                        <label id="lbl_datos_calend">Pendientes</label>
                                    </div>
                                </div>
                                <div className="div-pendientes-textArea">
                                    <div>
                                        {pendientes.map((pendiente, index) => (
                                            <div>
                                                <div className="div-pendientes-edit-delete">
                                                    <div key={index} id="id-hora-fecha-pendientes">
                                                        <label> Registrado el día: {pendiente.fecha} </label>
                                                    </div>
                                                    <div className="div-pendientes-toolTip">
                                                        <div id="container-toolTip-save">
                                                            <span className="lbl-toolTip-save">
                                                                Editar
                                                            </span>
                                                            { /*   entorno local
                                                                <a href="/pendientes">
                                                                <img src={img_edit} alt="edit" id="img_edit-calendar" />
                                                            </a>
                                                            */}
                                                            
                                                                {/*  entorno web   */}
                                                            <a href="#/pendientes">
                                                                <img src={img_edit} alt="edit" id="img_edit-calendar" />
                                                            </a>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="div-notif-px">
                                                    <p style={{ border: "none" }}>
                                                        <textarea id="textArea-pendientes" disabled rows="4" cols="50" onChange={(event) => handleComentario(index, event.target.value)} defaultValue={pendiente.comentario}></textarea>
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {usuario.rol === "pax" ? (
                                <div className="calendario-datos">
                                    <div className="calend">
                                        {eventos !== undefined ? (
                                            <FullCalendar
                                                headerToolbar={{
                                                    left: 'prev,next today',
                                                    center: 'title',
                                                    right: 'listDay, dayGridMonth,timeGridWeek,multiMonthYear' // Agrega las vistas aquí
                                                }}
                                                buttonText={{
                                                    day: 'Dia',
                                                    today: 'Hoy', // Cambia el texto del botón "Hoy"
                                                    month: 'Mes', // Cambia el texto del botón "Mes"
                                                    week: 'Semana', // Cambia el texto del botón "Semana"
                                                    year: 'Año' // Cambia el texto del botón "Día"
                                                }}
                                                locales={[esLocale]}
                                                slotLabelFormat={{
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hourCycle: "h12",
                                                    meridiem: 'short',
                                                }}
                                                eventTimeFormat={{
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hourCycle: "h12",
                                                }}
                                                nowIndicator={true}
                                                slotMinTime="07:00:00"
                                                slotMaxTime="22:00:00"
                                                allDaySlot={false}
                                                themeSystem="pulse"
                                                plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                                                initialView="timeGridWeek"
                                                events={eventos}
                                                dateClick={agendarCita}
                                                eventClick={informacionPaciente}
                                            />
                                        ) : (
                                            <p>Cargando eventos... </p>
                                        )}
                                    </div>
                                    <div className="datos-paciente">
                                        <div className="edit_delete-pendientes">
                                            <div id="px_h3">
                                                <h3>Recomendaciones</h3>
                                            </div>
                                        </div>
                                        <div className="div-pendientes-textArea">

                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {usuario.rol === 'adm' ? (
                                        <div className="calendario-datos">

                                            <h1>Agenda...</h1>

                                            <div className="datos-paciente">
                                                <div className="edit_delete-pendientes">
                                                    <div id="px_h3">
                                                        <h3>Pendientes del sistema</h3>
                                                    </div>
                                                </div>
                                                <div className="div-pendientes-textArea">

                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Content>
        </Layout >
    );
};

export default Calendario;
