
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import { Button, ButtonGroup } from "@material-ui/core";


import PacientesService from "../../services/PacientesService";
import FamiliaresService from "../../services/FamiliaresService";

import "../../styles/Familiares.css";

import img_edit from "../../img/edit_note.svg";
import img_delete from "../../img/delete.svg";
import img_search from "../../img/search_pax.svg";
import img_person from "../../img/person_add.svg";
import img_clear from "../../img/clear.svg";



const Familiares = (id) => {
    const [pacientes, setPacientes] = useState([]);
    const [famDelPac, setFamDelPaciente] = useState([]);
    const [famEsPaciente, setFamEsPaciente] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [selectedValue, setSelectedValue] = useState(""); // Estado para el valor del select
    const [inputValue, setInputValue] = useState("");
    const [esPaciente, setEsPaciente] = useState("");   // evaluar si el Familiar tambien es paciente
    const [image, setImage] = useState(null);
    const [fotoFam, setFotoFam] = useState('');
    const [sinFoto, setSinFoto] = useState('');


    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    function refreshPage() {
        window.location.reload(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }


    const navigate = useNavigate();

    const getData = async () => {
        if (routeParams.id !== undefined) {
            cargarInformacion(routeParams.id);
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        }
    };


    useEffect(() => {
        getData();
    }, [])


    // cargar información del paciente
    const cargarInformacion = async (id_paciente) => {
        const data = await PacientesService.getInfoPaciente(id_paciente);
        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }

        setInformacion(data[0]);

    };


    const DisplayData = pacientes.map((info) => {
        return (
            <tr>
                <td>
                    <label>
                        <input type="radio" name="status" id="radio_check_fam" onClick={() => cargarInformacionFam(info.id_paciente)} />
                        <span>
                            {info.nombre} {info.ap1} {info.ap2}
                        </span>
                    </label>
                </td>
            </tr>
        )
    });



    // cargar datos familiares: El familiar no es paciente
    const cargarInformacionFam = async (idPaciente) => {
        //tabla familiar
        const data = await FamiliaresService.getFamDelPaciente(idPaciente);
        setFamDelPaciente(data);    //famDelPac

        //tabla vparentesco -- El familiar es paciente
        const data2 = await FamiliaresService.getFamiliarEsPaciente(idPaciente);
        setFamEsPaciente(data2);    //famEsPaciente
    }


    // Display Familiar No paciente
    const DisplayData2 = famDelPac.map((info) => {
        return (
            <tr id="filas_familiar">
                <td>
                    <ButtonGroup className="td-border-bottom" onClick={() => cargarInformacionPorFamiliar(info.id_familiar, 'no')}>
                        <Button> {info.nombre} {info.ap1} {info.ap2} </Button>
                    </ButtonGroup>
                </td>
                <td id="td_2_fam"> {info.parentesco} </td>
                <td id="td_3_fam"> No </td>
            </tr>
        )
    });


    // // Display paciente_familiar: el familiar es paciente
    const DisplayData3 = famEsPaciente.map((info) => {
        return (
            <tr id="filas_familiar">
                <td>
                    <ButtonGroup className="td-border-bottom" onClick={() => cargarInformacionPorFamiliar(info.id_paciente_familiar, 'si')}>
                        <Button> {info.pf_nom} {info.pf_ap1} {info.pf_ap2} </Button>
                    </ButtonGroup>
                </td>
                <td id="td_2_fam"> {info.parentesco} </td>
                <td id="td_3_fam"> Si </td>
            </tr>
        )
    });


    // cargar información del familiar
    const cargarInformacionPorFamiliar = async (id_familiar, esPac) => {
        // DisplayData2: El familiar no es paciente
        if (esPac === 'no') {
            const data = await FamiliaresService.getFamiliarIndividual(id_familiar);
            if (data[0].sexo === "m" || data[0].sexo === "M") {
                data[0].sexo = "Masculino"
            } else if (data[0].sexo === "f" || data[0].sexo === "F") {
                data[0].sexo = "Femenino"
            }
            if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
                data[0].edo_civil = "Soltero"
            } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
                data[0].edo_civil = "Casado"
            } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
                data[0].edo_civil = "Divorciado"
            } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
                data[0].edo_civil = "Viudo"
            }
            if (data[0].edo === "mi" || data[0].edo === "MI") {
                data[0].edo = "Michoacán"
            }
            if (data[0].pais === "mx" || data[0].pais === "MX") {
                data[0].pais = "México"
            } else if (data[0].pais === "us" || data[0].pais === "US") {
                data[0].pais = "Estados Unidos"
            }
            
            const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

            if (cont.length < 1) {
                setSinFoto("0");
            } else if (cont.length > 1 && cont.length < 50) {
                mostrarAdvertencia("Error al obtener fotografía");
            } else if (cont.length > 50) {
                setSinFoto("1");
            }

            setEsPaciente("no");
            setInformacion(data[0]);
            setFotoFam(data[0].fotografia.data)

        } else if (esPac === 'si') {

            // Display3: El familiar sí es paciente
            const data = await FamiliaresService.getFamiliarP(id_familiar);

            if (data[0].pf_sexo === "m" || data[0].pf_sexo === "M") {
                data[0].pf_sexo = "Masculino"
            } else if (data[0].pf_sexo === "f" || data[0].pf_sexo === "F") {
                data[0].pf_sexo = "Femenino"
            }
            if (data[0].pf_edo_civil === "s" || data[0].pf_edo_civil === "S") {
                data[0].pf_edo_civil = "Soltero"
            } else if (data[0].pf_edo_civil === "c" || data[0].pf_edo_civil === "C") {
                data[0].pf_edo_civil = "Casado"
            } else if (data[0].pf_edo_civil === "d" || data[0].pf_edo_civil === "D") {
                data[0].pf_edo_civil = "Divorciado"
            } else if (data[0].pf_edo_civil === "v" || data[0].pf_edo_civil === "V") {
                data[0].pf_edo_civil = "Viudo"
            }
            if (data[0].pf_edo === "mi" || data[0].pf_edo === "MI") {
                data[0].pf_edo = "Michoacán"
            }
            if (data[0].pf_pais === "mx" || data[0].pf_pais === "MX") {
                data[0].pf_pais = "México"
            } else if (data[0].pf_pais === "us" || data[0].pf_pais === "US") {
                data[0].pf_pais = "Estados Unidos"
            }

            const cont = data[0].pf_fotografia.data;

            if (cont.length < 1) {
                setSinFoto("0");
            } else if (cont.length > 1 && cont.length < 50) {
                mostrarAdvertencia("Error al obtener fotografía");
            } else if (cont.length > 50) {
                setSinFoto("1");
            }

            setEsPaciente("si");
            setInformacion(data[0]);
            setFotoFam(data[0].pf_fotografia.data)

        }
    };



    const eliminarFamiliar = async () => {

        eliminarDatos(informacion.id_familiar);

    };

    const eliminarDatos = async (id_paciente) => {
        const respuesta = await PacientesService.eliminarPacienteL(id_paciente);
        if (respuesta.status === 200) {
            Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    refreshPage();
                }
            });
        } else {
            Toast.fire("Hubo un problema al eliminar la cita!", "", "error");
        }
    };

    const editarFamiliar = async () => {
        if (esPaciente === 'no') {
            // local
            // navigate(`/editfamiliar/${informacion.id_familiar}`);

            // web
            navigate(`/editfamiliar/${informacion.id_familiar}`);

        } else if (esPaciente === 'si') {
            Swal.fire({
                title: "Editar paciente",
                text: "Estás tratando de editar un paciente...",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isConfirmed) {
                    // navigate(`/editpaciente/${informacion.id_paciente_familiar}`);  // local
                    navigate(`/editpaciente/${informacion.id_paciente_familiar}`);     // web
                }
            });
        }
    };

    const agregarPaciente = () => {
        // navigate('/addfamiliar');   //local
        navigate('/addfamiliar');   //web
    };

    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value);
        setPacientes(data);
    };

    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value);
            setPacientes(data);
        }
    };

    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);
    };

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    };

    return (
        <Layout>
            <Content>
                <div className="px_div_1_familiares">
                    <div className="px_div_2_fam">
                        <div className="px_sesion_fam">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_fam">
                            <label id="lbl_familiares">Familiares</label>
                        </div>
                    </div>
                    <div className="px_div_3_fam">
                        <div className="div_filtro_selec_px_fam">
                            <div className="px_filtro_fam">
                                <select id="px_select_fam" value={selectedValue} onChange={buscarFiltro}>
                                    <option value="" disabled >Selecciona un filtro</option>
                                    <option value="A">Activos</option>
                                    <option value="I">Inactivos</option>
                                </select>
                                <button id="btn-limpiar-filtro" onClick={limpiarFiltro}>
                                    <img src={img_clear} alt="img_clear" />
                                    Limpiar
                                </button>
                            </div>
                            <div className="px_input_fam">
                                <div className="input_buscar_fam">
                                    <input type="search" placeholder="Buscar paciente..." id="Buscador" value={inputValue} onChange={buscar} />
                                    <img src={img_search} alt="buscar" id="img_lupa"></img>
                                </div>
                            </div>
                            <div className="div_tablas_th_px-fam">
                                <table id="filas_px-fam">
                                    <tbody>
                                        {DisplayData}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="div_boton_tabla_fam">
                            <div className="px_nuevo_fam">
                                <button id="px_btn_nuevo" onClick={agregarPaciente}>
                                    <img src={img_person} alt="img_person" />
                                    Familiar
                                </button>
                            </div>
                            <div className="div_tablas_th_fam">
                                <div id="div_table_encabezado_fam">
                                    <table id="encabezado_familiar">
                                        <tbody>
                                            <tr id="tr-encabezado-fam">
                                                <th>Familiares del paciente</th>
                                                <th>Parentesco</th>
                                                <th>Es paciente</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {famDelPac.length === 0 && famEsPaciente.length === 0 ? (
                                    <div className="div-selec-px">
                                        <label id="label-selec-px-px"> Selecciona un paciente de la lista </label>
                                    </div>
                                ) : (
                                    <table id="filas_familiar">
                                        <tbody>
                                            {DisplayData2}
                                        </tbody>
                                        <tbody>
                                            {DisplayData3}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                        <div className="px_tabla_fam">
                            {informacion.length === 0 ? (
                                <div>
                                    <div id="edit_delete_fam">
                                        <div id="px_h3_fam">
                                            <label>Familiar</label>
                                        </div>
                                    </div>
                                    <div className="div-selec-px">
                                        <label id="label-selec-px-px"> Selecciona un familiar de la lista </label>
                                    </div>
                                </div>
                            ) : (
                                esPaciente === 'no' ? (
                                    <div>
                                        <div id="edit_delete_fam">
                                            <div id="px_h3_fam">
                                                <label>Familiar</label>
                                            </div>
                                            <div className="div-px-toolTip">
                                                <div id="container-toolTip-editar-px">
                                                    <span className="lbl-toolTip-editar-px">
                                                        Editar
                                                    </span>
                                                    <img src={img_edit} alt="edit" id="img_edit" onClick={editarFamiliar} />
                                                </div>
                                                <div id="container-toolTip-delete-px">
                                                    <span className="lbl-toolTip-delete-px">
                                                        Eliminar
                                                    </span>
                                                    <img src={img_delete} alt="delete" id="img_delete" onClick={eliminarFamiliar} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div-avatar-px">
                                            {
                                                sinFoto === "1" ? (
                                                    fotoFam === "" ? (
                                                        <div id="img_avatar_px">
                                                            <label> Cargando imagen...</label>
                                                        </div>
                                                    ) : (
                                                        <div id="img_avatar_px">
                                                            <img src={fotoFam} alt="base64"></img>
                                                        </div>
                                                    )
                                                ) : (<div></div>)
                                            }
                                        </div>
                                        <table id="table_px_seleccionado_fam">
                                            <tbody>
                                                <tr className="padding-row">
                                                    <td id="td-paciente-fam" colSpan="2">
                                                        <center>
                                                            <label id="lbl-paciente-fam">
                                                                {informacion.nombre} {informacion.ap1} {informacion.ap2}
                                                            </label>
                                                        </center>
                                                    </td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-edad" htmlFor="lbl-edad">Edad:</label></td>
                                                    <td><label id="lbl-edad">{informacion.edad}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-sexo" htmlFor="lbl-sexo">Sexo:</label></td>
                                                    <td><label id="lbl-sexo">{informacion.sexo}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-edo-civil" htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                    <td><label id="lbl-edo-civil">{informacion.edo_civil}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-tel" htmlFor="lbl-tel">Teléfono:</label></td>
                                                    <td><label id="lbl-tel">{informacion.tel}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-correo" htmlFor="lbl-correo">Correo:</label></td>
                                                    <td><label id="lbl-correo">{informacion.correo}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-domicilio" htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                    <td><label id="lbl-domicilio">{informacion.domicilio}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-ciudad" htmlFor="lbl-ciudad">Ciudad:</label></td>
                                                    <td><label id="lbl-ciudad">{informacion.ciudad}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-cp" htmlFor="lbl-cp">Código Postal:</label></td>
                                                    <td><label id="lbl-cp">{informacion.cod_pos}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-edo" htmlFor="lbl-edo">Estado:</label></td>
                                                    <td><label id="lbl-edo">{informacion.edo}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-pais" htmlFor="lbl-pais">País:</label></td>
                                                    <td><label id="lbl-pais">{informacion.pais}</label></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div>
                                        <div id="edit_delete_fam">
                                            <div id="px_h3_fam">
                                                <label>Familiar</label>
                                            </div>
                                            <div className="div-px-toolTip">
                                                <div id="container-toolTip-editar-px">
                                                    <span className="lbl-toolTip-editar-px">
                                                        Editar
                                                    </span>
                                                    <img src={img_edit} alt="edit" id="img_edit" onClick={editarFamiliar} />
                                                </div>
                                                <div id="container-toolTip-delete-px">
                                                    <span className="lbl-toolTip-delete-px">
                                                        Eliminar
                                                    </span>
                                                    <img src={img_delete} alt="delete" id="img_delete" onClick={eliminarFamiliar} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div-avatar-px">
                                            {
                                                sinFoto === "1" ? (
                                                    fotoFam === "" ? (
                                                        <div id="img_avatar_px">
                                                            <label> Cargando imagen...</label>
                                                        </div>
                                                    ) : (
                                                        <div id="img_avatar_px">
                                                            <img src={fotoFam} alt="base64"></img>
                                                        </div>
                                                    )
                                                ) : (<div></div>)
                                            }
                                        </div>
                                        <table id="table_px_seleccionado_fam">
                                            <tbody>
                                                <tr className="padding-row">
                                                    <td id="td-paciente-fam" colSpan="2">
                                                        <center>
                                                            <label id="lbl-paciente-fam">
                                                                {informacion.pf_nom} {informacion.pf_ap1} {informacion.pf_ap2}
                                                            </label>
                                                        </center>
                                                    </td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-edad" htmlFor="lbl-edad">Edad:</label></td>
                                                    <td><label id="lbl-edad">{informacion.pf_edad}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-sexo" htmlFor="lbl-sexo">Sexo:</label></td>
                                                    <td><label id="lbl-sexo">{informacion.pf_sexo}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-edo-civil" htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                    <td><label id="lbl-edo-civil">{informacion.pf_edo_civil}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-tel" htmlFor="lbl-tel">Teléfono:</label></td>
                                                    <td><label id="lbl-tel">{informacion.pf_tel}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-correo" htmlFor="lbl-correo">Correo:</label></td>
                                                    <td><label id="lbl-correo">{informacion.pf_correo}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-domicilio" htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                    <td><label id="lbl-domicilio">{informacion.pf_dom}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-ciudad" htmlFor="lbl-ciudad">Ciudad:</label></td>
                                                    <td><label id="lbl-ciudad">{informacion.pf_ciudad}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-cp" htmlFor="lbl-cp">Código Postal:</label></td>
                                                    <td><label id="lbl-cp">{informacion.pf_cod_pos}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-edo" htmlFor="lbl-edo">Estado:</label></td>
                                                    <td><label id="lbl-edo">{informacion.pf_edo}</label></td>
                                                </tr>
                                                <tr className="padding-row">
                                                    <td><label className="label-pais" htmlFor="lbl-pais">País:</label></td>
                                                    <td><label id="lbl-pais">{informacion.pf_pais}</label></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Familiares;
