

import React from "react";
import { useNavigate } from "react-router-dom";

import "../styles/header.css";
import "../styles/MenuUsuario.css";
import logo from "../img/psiquedata_logo_trans.png";
import img_avatar from "../img/avatar.png";
import img_colegas from "../img/groups.svg";
import img_calendar from "../img/calendar_month_black.svg";
import img_patient from "../img/patient_list_black.svg";
import img_pending from "../img/pending_actions_black.svg";


const Header = () => {

  const usuarioString = sessionStorage.getItem('usuario');
  const usuario = JSON.parse(usuarioString);

  const navigate = useNavigate();

  const cerrarSesion = () => {
    navigate("/");
  };

  return (
    <div className="div-header">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="div-navBar">
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
          <div class="container-fluid">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" href="#/calendario">
                    <img id="img_calendar" src={img_calendar} alt="img_calendar"></img>
                    Agenda
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#/pacientes">
                    <img id="img_patient" src={img_patient} alt="img_patient"></img>
                    Pacientes
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    ☰ Módulos
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a class="dropdown-item" href="#/citas">Citas</a></li>
                    <li><a class="dropdown-item" href="#/sesiones">Sesiones</a></li>
                    <li><a class="dropdown-item" href="#/expedientes">Expedientes</a></li>
                    <li><a class="dropdown-item" href="#/familiares">Familiares</a></li>
                    <li><a class="dropdown-item" href="#/canalizaciones">Canalizaciones</a></li>
                    <li><hr class="dropdown-divider" /></li>
                    <li><a class="dropdown-item" href="#/notificaciones">Notificaciones</a></li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#/pendientes">
                    <img id="img_pending" src={img_pending} alt="img_pending"></img>
                    Pendientes
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Cuenta
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <a class="dropdown-item" href={`#/psicologo/${usuario.id_psicologo}`}>
                      <div id="img_link">
                        <img id="img_profile" src={img_avatar} alt="img_profile"></img>
                        {usuario.nombre} {usuario.ap1} {usuario.ap2}
                      </div>
                      </a>
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                      <a class="dropdown-item" href="#/colegas">
                      <div id="img_link">
                        <img id="img_colegas" src={img_colegas} alt="img_colegas"></img>
                        Colegas
                      </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#/">Salir</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;


/* 
Desplegable: javascript puro, estilos en header.css:

return (
    <div className="div-header">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="navbar-left">
        <ul className="nav-links">
          <li>
            <i className="material-symbols-outlined">calendar_month</i>
            <a href="/calendario">Agenda</a>
          </li>
          <li>
            <i className="material-symbols-outlined">patient_list</i>
            <a href="/pacientes">Pacientes</a>
          </li>
          <li>
            <a href="">☰ Módulos</a>
            <ul id="ul_modulos">
              <li>
                <a href="/citas">
                  <i className="material-symbols-outlined">groups</i>
                  Citas
                </a>
              </li>
              <li>
                <a href="/sesiones">
                  <i className="material-symbols-outlined">psychology</i>
                  Sesiones
                </a>
              </li>
              <li>
                <a href="/expedientes">
                  <i className="material-symbols-outlined"> folder_shared </i>
                  Expedientes
                </a>
              </li>
              <li>
                <a href="/familiares">
                  <i className="material-symbols-outlined"> family_restroom </i>
                  Familiares
                </a>
              </li>
              <li>
                <a href="/canalizaciones">
                  <i className="material-symbols-outlined"> filter_alt </i>
                  Canalizaciones
                </a>
              </li>
              <li>
                <a href="/notificaciones">
                  <i className="material-symbols-outlined"> notifications </i>
                  Notificaciones
                </a>
              </li>
            </ul>
          </li>
          <li>
            <i className="material-symbols-outlined">pending_actions</i>
            <a href="/pendientes">Pendientes</a>
          </li>
          <li>
            <i className="material-symbols-outlined" id="account_menu" >account_circle</i>
            <label id="lbl_menuUsers">Cuenta</label>
            <ul id="ul_profile">
              <li>
                <a href={`/psicologo/${usuario.id_psicologo}`}>
                  <img id="img_profile" src={img_avatar} alt="img_profile"></img>
                  <label id="lbl_psic">{usuario.nombre} {usuario.ap1} {usuario.ap2} </label>
                </a>
              </li>
              <li>
                <a href={'/colegas'}>
                <div className="div_linkColegas">
                <div className="div_imgColegas">
                    <img id="img_colegas" src={img_colegas} alt="img_colegas"></img>
                  </div>
                  <div className="div_labelColegas">
                    <label id="lbl_colegas">Colegas</label>
                  </div>
                </div>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <i className="material-symbols-outlined">exit_to_app</i>
            <a href="/"> Salir </a>
          </li>
        </ul>
      </div>

    </div>
  );

*/


