
import React from "react";



const Content = ({ children }) => {
  
    return (
    <div>
        { children }
    </div>
    );
};


export default Content;