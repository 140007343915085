import axios from "axios";

const SESIONES_API_BASE_URL = "/server/sesiones";
const VSESIONES_API_BASE_URL = "/server/vsesiones";

// const SESIONES_API_BASE_URL = "http://localhost:3002/sesiones";
// const VSESIONES_API_BASE_URL = "http://localhost:3002/vsesiones";


class SesionesService {

    crearSesion(sesion) {
        return axios.post(SESIONES_API_BASE_URL, sesion);
    }

    
    guardarSesion(sesion, id_sesion) {
        return axios.put(`${SESIONES_API_BASE_URL}?sesion=${sesion}&id_sesion=${id_sesion}`);
    }


    eliminarSesion(idCita) {
        return axios.delete(`${SESIONES_API_BASE_URL}?id_cita=${idCita}`);
    }


    async getSesionesHistorial(id_paciente) {
        const response = await axios.get(`${VSESIONES_API_BASE_URL}Historial?fk_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error SesionesService,  no se obtuvo la cita:', error);
            });
        const sesiones = response.data;
        console.log("SS getSesionesHistorial -----")
        console.log(sesiones)
        return sesiones;
    }

}

export default new SesionesService();