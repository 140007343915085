import axios from "axios";

const CITAS_API_BASE_URL = "/server/citas";
const VCITAS_API_BASE_URL = "/server/vcitas";

// const CITAS_API_BASE_URL = "http://localhost:3002/citas";
// const VCITAS_API_BASE_URL = "http://localhost:3002/vcitas";

class CitasService {

    async getCitasPS(id_psicologo) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}?id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getCitasPS CS :', error);
            });
        const citas = response.data;
        return citas;
    }

    async getCitasPsicologoPX(id_psicologo, id_paciente) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}psicologopx?id_psicologo=${id_psicologo}&id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getCitasPsicologoPX CS :', error);
            });
        const citas = response.data;
        return citas;
    }

    async getCitasPacientePX(id_paciente) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}px?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getCitasPacientePX CS: ', error);
            });
        const citas = response.data;
        return citas;
    }

    async crearCita(cita) {
        return await axios.post(CITAS_API_BASE_URL, cita);
    }

    async getInfoCita(fecha, horaI, horaF) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}info?fecha=${fecha}&hora_i=${horaI}&hora_f=${horaF}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getInfoCita CS: ', error);
            });
        const cita = response.data;
        return cita;
    }

    editarCita(cita, idCita) {
        return axios.put(CITAS_API_BASE_URL, cita, idCita);
    }
    
    eliminarCita(idCita) {
        return axios.delete(`${CITAS_API_BASE_URL}?id_cita=${idCita}`);
    }

    async verificarCitaAnterior(id_paciente) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}anterior?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error verificarCitaAnterior CS: ', error);
            });
        const citas = response.data;
        return citas;
    }

    async comprobarCita(fecha, hora_i, hora_f, fk_psicologo) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}comprobar?fecha=${fecha}&hora_i=${hora_i}&hora_f=${hora_f}&fk_psicologo=${fk_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error comprobarCita CS: ', error);
            });
        const citas = response.data;
        return citas;
    }

    async getCitasHistorial(id_paciente) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}Historial?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getCitasHistorial CS: ', error);
            });
        const citas = response.data;
        return citas;
    }

    async getUltimaCita(id_psicologo) {
        const response = await axios.get(`${VCITAS_API_BASE_URL}?id_psicologo=${id_psicologo}`)
        const citas = response.data;
        const ultimaCita = citas.slice(-1)[0];
        return ultimaCita;
      }


    async estatusCita(estatus, id_cita, id_paciente) {
        const response = await axios.put(`${CITAS_API_BASE_URL}Estatus?estatus=${estatus}&id_cita=${id_cita}&id_paciente=${id_paciente}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error('Error estatusCita CS: ', error);
        })
        const citas = response.data;
        return citas;
    }


}

export default new CitasService();