// FormularioCita.js
import React from "react";
import "../../styles/FormularioEditarCita.css";

const FormularioRegistroCita = ({ pacientes, fechaF, horaI, horaF }) => {
  return (
    <div>
      <table className="table-registro-cita">
        <tr className="table-line-bottom">
          <td className="registrar-cita-td-izq">
            <label className="lbl-paciente-registro" htmlFor="select-paciente"> Paciente: </label>
          </td>
          <td>
            <select className="selected-pax" id="select-paciente">
              <option className="selected-option" disabled selected>
                Selecciona una opción
              </option>
              {pacientes.map((paciente) => (
                <option className="selected-option" value={paciente.id_paciente}>
                  {paciente.nombre} {paciente.ap1} {paciente.ap2}
                </option>
              ))}
            </select>
          </td>
        </tr>
        <tr className="table-line-bottom">
          <td className="registrar-cita-td-izq"><label className="lbl-fecha-registro" htmlFor="input-fecha"> Fecha: </label></td>
          <td className="registrar-cita-td-der"><input type="date" id="input-fecha" defaultValue={fechaF} /></td>
        </tr>
        <tr className="table-line-bottom">
          <td className="registrar-cita-td-izq"><label className="lbl-horaI-registro" htmlFor="input-horaI"> Hora Inicio: </label></td>
          <td className="registrar-cita-td-der"><input type="time" id="input-horaI" defaultValue={horaI} /></td>
        </tr>
        <tr className="table-line-bottom">
          <td className="registrar-cita-td-izq"><label className="lbl-horaF-registro" htmlFor="input-horaF"> Hora Final: </label></td>
          <td className="registrar-cita-td-der"><input type="time" id="input-horaF" defaultValue={horaF} /></td>
        </tr>
        <tr className="table-line-bottom">
          <td className="registrar-cita-td-izq"><label className="lbl-comentario-registro" htmlFor="input-comentario"> Comentario: </label></td>
          <td className="registrar-cita-td-der"><input type="text" placeholder="Deja un comentario..." id="input-comentario" /></td>
        </tr>
      </table>
    </div>
  );
};

export default FormularioRegistroCita;
