
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";
import FormularioCambiarContrasena from "../Psicologo/FormularioCambiarContrasena";

import "../../styles/Psicologo.css";

import img_psy from "../../img/psy_engrane.svg";
import img_exped from "../../img/folder_shared.svg";
import img_groups from "../../img/groups.svg";
import img_edit from "../../img/edit_note.svg";
import img_delete from "../../img/delete.svg";
import img_whatsapp from "../../img/whatsapp.svg";
import img_search from "../../img/search_pax.svg";
import img_avatar from "../../img/avatar.png";


const Psicologo = (id) => {
    const [pacientes, setPacientes] = useState([]);
    const [psicologo, setPsicologo] = useState([]);

    const navigate = useNavigate();
    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    function refreshPage() {
        window.location.reload(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            timer: 6000,
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    const getData = async () => {
        if (routeParams.id !== undefined) {
            cargarInformacionPsicologo(routeParams.id);
        }

        cargarInformacionPacientes(usuario.id_psicologo);
    };


    useEffect(() => {
        getData();
    }, [])

    const cargarInformacionPsicologo = async (id_psicologo) => {
        const data = await PsicologoService.getPsicologoIndividual(id_psicologo);
        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }
        setPsicologo(data[0]);
    };


    const cargarInformacionPacientes = async (id_psicologo) => {
        const data = await PacientesService.getPacientes(id_psicologo);
        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }
        setPacientes(data);
    };

    const DisplayData = pacientes.map((info) => {
        return (
            <tr className="table-line-bottom">
                <td id="td_1">
                    <button onClick={() => cargarInformacionPacientes(info.id_paciente)} id="liga_psi">
                        {info.nombre} {info.ap1} {info.ap2}
                    </button>
                </td>
                <td id="td_2">
                    <a href={`#/sesiones/${info.id_paciente}`}><img id="img_psy" src={img_psy} alt="psy_psi" />
                    </a>
                </td>
                <td id="td_3">
                    <a href={`#/expediente/${info.id_paciente}`}><img id="img_exped" src={img_exped} alt="img_exped"></img>
                    </a>
                </td>
                <td id="td_4">
                    <a href={`#/cita/${info.id_paciente}`}><img id="img_groups" src={img_groups} alt="img_groups"></img>
                    </a>
                </td>
                <td id="td_5">
                    <a href={`#/notificaciones/${info.id_paciente}`}><img id="img_whatsapp" src={img_whatsapp} alt="img_whatsapp"></img>
                    </a>
                </td>
            </tr>
        )
    });

    const editarPsicologo = () => {
        navigate(`/editpsicologo/${usuario.id_psicologo}`);
    }



    const cambiarContrasena = async () => {
        const data = await PsicologoService.getPsicologoIndividual(usuario.id_psicologo);

        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioCambiarContrasena psicologo={data} />
        );
        Swal.fire({
            title: "Cambiar contraseña",
            html: formularioHtml,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Guardar",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const contrasenaActual = document.getElementById("input-contrasenaActual").value;
                const contrasenaNueva = document.getElementById("input-contrasenaNueva").value;
                const id_usuario = data[0].id_usuario;

                if (contrasenaActual !== data[0].contrasena) {
                    Toast.fire("La contraseña actual no es correcta", "", "error");
                } else if (contrasenaNueva === data[0].contrasena) {
                    Toast.fire("No se puede repetir una contraseña actual", "", "error");
                } else {
                    return { contrasenaNueva, id_usuario };
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;

                console.log("isConfirmed ------------ ");
                console.log(datos);

                putCambiarContrasena(datos);
                // refreshPage();
            } else if (result.dismiss === 'cancel') {
                navigate(`/psicologo/${usuario.id_psicologo}`);
            }
        });
    }


    const putCambiarContrasena = async (datos) => {

        let data = {
            contrasena: datos.contrasenaNueva,
            id_usuario: datos.id_usuario,
        }

        await PsicologoService.editarContrasenaPsicologo(data);

        Toast.fire("¡La contraseña se actualizó correctamente!", "", "success").then((result) => {
            if (result.isConfirmed) {
                navigate(`/psicologo/${usuario.id_psicologo}`);
            }
        }).then(function () {
            navigate(`/psicologo/${usuario.id_psicologo}`);
        });
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    return (
        <Layout>
            <Content>
                <div className="psi_div_1">
                    <div className="psi_div_2">
                        <div className="px_sesion">
                            <label id="lbl-enSesion">{tipoUsuario()} {usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_psi">
                            <label>Psicólogo</label>
                        </div>
                    </div>
                    <div className="div-datos-psi">
                        <div className="div-foto-psi">
                            <div className="div-avatar-psi">
                                <img src={img_avatar} alt="avatar" id="img_avatar" />
                            </div>
                            <div className="div-label-psi">
                                <label> {psicologo.nombre} {psicologo.ap1} {psicologo.ap2} </label>
                                <div id="container-toolTip-editar-psi">
                                    <span className="lbl-toolTip-editar-psi">
                                        Editar
                                    </span>
                                    <img src={img_edit} alt="edit" id="img_psi" onClick={editarPsicologo} />
                                </div>
                            </div>
                        </div>
                        <table className="table-psi">
                            <tbody>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-edad" htmlFor="input-edad">Edad:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.edad}</label>
                                    </td>
                                    <td className="psi-td-izq"><label className="label-sexo" htmlFor="select-sexo">Sexo:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.sexo}</label>
                                    </td>
                                </tr>
                                <tr>

                                    <td className="psi-td-izq"><label className="label-edo-civil" htmlFor="select-edo-civil">Estado civil:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.edo_civil}</label>
                                    </td>
                                    <td className="psi-td-izq"><label className="label-domicilio" htmlFor="input-domicilio">Domicilio:</label></td>
                                    <td className="psi-td-der" colSpan={3}>
                                        <label>{psicologo.domicilio}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-ciudad" htmlFor="input-ciudad">Ciudad:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.ciudad}</label>
                                    </td>
                                    <td className="psi-td-izq"><label className="label-cp" htmlFor="input-cp">Código Postal:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.cod_pos}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-tel" htmlFor="input-tel">Teléfono:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.tel}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-correo" htmlFor="input-correo">Correo:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.correo}</label>
                                    </td>
                                    <td className="psi-td-izq"><label className="label-contrasena" htmlFor="input-contrasena">Contraseña:</label></td>
                                    <td className="psi-td-der">
                                        <button className="div-psi-botones" onClick={cambiarContrasena}>Cambiar</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-escolaridad" htmlFor="select-escolaridad">Escolaridad:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.escolaridad}</label>
                                    </td>
                                    <td className="psi-td-izq"><label className="label-cedula" htmlFor="input-cedula">Cédula:</label></td>
                                    <td className="psi-td-der" colSpan={2}>
                                        <label>{psicologo.cedula}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-especialidad" htmlFor="input-especialidad">Especialidad actual:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.especialidad}</label>
                                    </td>
                                    <td className="psi-td-izq"><label className="label-titulo" htmlFor="select-titulo">Titulo:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.titulo}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="psi-td-izq"><label className="label-edo" htmlFor="select-edo">Estado:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.edo}</label>
                                    </td>
                                    <td className="psi-td-izq"><label className="label-pais" htmlFor="select-pais">País:</label></td>
                                    <td className="psi-td-der">
                                        <label>{psicologo.pais}</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Psicologo;
