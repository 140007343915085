
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import moment from 'moment';

import PacientesService from "../../services/PacientesService";
import SesionesService from "../../services/SesionesService";
import CitasService from "../../services/CitasService";

import "../../styles/Sesiones.css";
import "../../styles/MenuHamburguesa.css";

import img_search from "../../img/search_pax.svg";
import img_avatar from "../../img/avatar.png";
import img_clear from "../../img/clear_all.svg";


const Sesiones = (id) => {

    const [pacientes, setPacientes] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [selectedValue, setSelectedValue] = useState(""); // Estado para el valor del select
    const [inputValue, setInputValue] = useState("");
    const [sesiones, setSesiones] = useState([]);
    const [fk_cita_sesion, setFk_cita_sesion] = useState("");
    const [fotografia, setFotografia] = useState('');
    const [sinFoto, setSinFoto] = useState('');


    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const navigate = useNavigate();

    const getData = async () => {
        if (routeParams.id !== undefined) {
            cargarInformacion(routeParams.id);
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        }
    };


    useEffect(() => {
        getData();
    }, []);


    function horaEnRango(hora_inicio, hora_fin) {
        const ahora = new Date();
        const horaActual = ahora.getHours();
        const minutosActuales = ahora.getMinutes();

        const [inicioHoras, inicioMinutos] = hora_inicio.split(":").map(Number);
        const [finHoras, finMinutos] = hora_fin.split(":").map(Number);

        const inicioEnMinutos = inicioHoras * 60 + inicioMinutos;
        const finEnMinutos = finHoras * 60 + finMinutos;
        const actualEnMinutos = horaActual * 60 + minutosActuales;

        return actualEnMinutos >= inicioEnMinutos && actualEnMinutos <= finEnMinutos;
    }

    const cargarInformacion = async (id_paciente) => {
        const data2 = await SesionesService.getSesionesHistorial(id_paciente);
        if (data2.length === 0) {
            Swal.fire({
                title: "Sesiones del paciente",
                text: "El paciente no tiene sesiones registradas. Para abrir una sesión, se requiere agendar una cita.",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Agendar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isConfirmed) {
                    // navigate(`/calendario`);        //local
                    navigate(`/calendario`);    //web
                }
            });
        } else {
            for (let i = 0; i < data2.length; i++) {
                data2[i].numeroSesion = (data2.length + 1) - (i + 1);   //.numeroSesion, se puede crear un parámetro temporal cuando no existe en la tabla DB
                const fechaActual = moment(); // Fecha actual
                const fechaSesion = moment(data2[i].fecha, 'YYYY-MM-DD');
                if (fechaActual.isSame(fechaSesion, 'day')) {
                    if (horaEnRango(data2[i].hora_i, data2[i].hora_f) === true) {
                        data2[i].editable = true;   // .editable, parametro creado
                        setFk_cita_sesion(data2[i].id_cita);
                    } else {
                        data2[i].editable = false;
                    }
                }
            }
            setSesiones(data2);
            cargarInformacionPx(id_paciente);
        }
    };

    const cargarInformacionPx = async (id_paciente) => {
        const data = await PacientesService.getInfoPaciente(id_paciente);
        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInformacion(data[0]);
    };

    const DisplayPx = pacientes.map((info) => {
        return (
            <tr>
                <td className="td-border-button">
                    <button onClick={() => cargarInformacion(info.id_paciente)} id="liga_px">
                        {info.nombre} {info.ap1} {info.ap2}
                    </button>
                </td>
            </tr>
        )
    });

    const handleGuardarSesion = async (event, id_sesion, id_paciente) => {
        try {
            const respuesta = await SesionesService.guardarSesion(event.target.value, id_sesion);
            if (respuesta.status === 200) {
                cargarInformacion(id_paciente)
            } else {
                Toast.fire("Hubo un problema al guardar la sesión", "", "error");
            }
            if (selectedValue !== "") {
                await CitasService.estatusCita(selectedValue, fk_cita_sesion, id_paciente);
            }
            else {
                Toast.fire("Selecciona estatus de cita: ", "", "error");
            }
        } catch (error) {
            Toast.fire("Hubo un problema al guardar la sesión", "", "error");
            console.log('Error al realizar la inserción:', error.message);
        }
    };


    const handleSelect = async (event) => {

        setSelectedValue(event.target.value);
    };

    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value);
        console.log(data);
        setPacientes(data);
    };

    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            console.log(data);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value);
            console.log(data);
            setPacientes(data);
        }
    };

    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        console.log(data);
        setPacientes(data);
    };

    const DisplaySesiones = sesiones.map((sesion) => {
        return (
            <p>
                <div id="div-datos-sesiones">
                    <div id="div-datos2-sesiones">
                        <p style={{ border: "none" }}>
                            Sesión {sesion.numeroSesion}   ----   Hora: {sesion.hora_i}-{sesion.hora_f}  ---- Fecha: {moment(sesion.fecha).format('DD-MM-YYYY')}
                        </p>
                    </div>
                    <div id="div-select-sesiones">
                        <div id="div-select2-sesiones">
                            <label id="lbl-select-sesiones">
                                Estatus de la cita: &nbsp;
                                <select className="select-cita-sesiones" defaultValue="Selecciona una opción" onChange={handleSelect}>
                                    <option disabled>Selecciona una opción</option>
                                    <option>Cita atendida</option>
                                    <option>Cita con retardo</option>
                                    <option>Cita Cancelada por el paciente</option>
                                    <option>Cita Cancelada por el psicólogo</option>
                                    <option>Cita incumplida (el paciente no se presentó)</option>
                                </select>
                            </label>
                        </div>
                    </div>
                </div>
                <div>
                    <p style={{ border: "none" }}>
                        {sesion.editable === true ? (
                            <textarea id="textarea-sesiones" rows="4" cols="50" value={sesion.sesion} onChange={(e) => handleGuardarSesion(e, sesion.id_sesion, sesion.fk_paciente)}>
                            </textarea>
                        ) : (
                            <textarea id="textarea-sesiones" rows="4" cols="50" value={sesion.sesion} disabled>
                            </textarea>
                        )
                        }
                    </p>
                </div>
            </p>
        )
    });

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }


    return (
        <Layout>
            <Content>
                <div className="div-1-sesiones">
                    <div className="div-2-sesiones">
                        <div className="px_sesion_citas">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp;</label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_citas">
                            <label>Historial de sesiones</label>
                        </div>
                    </div>
                    <div className="div_buscador_px_sesiones">
                        <div className="input_buscar_sesiones">
                            <input type="search" placeholder="Buscar paciente..." id="Buscador" value={inputValue} onChange={buscar} />
                            <img src={img_search} alt="buscar" id="img_lupa"></img>
                        </div>
                        <div className="div_filtro_sesiones">
                            <select id="px_select_cita" value={selectedValue} onChange={buscarFiltro}>
                                <option value="" disabled >Selecciona un filtro</option>
                                <option value="A">Activos</option>
                                <option value="I">Inactivos</option>
                            </select>
                            <button id="btn-limpiar-filtro" onClick={limpiarFiltro}>
                                <img src={img_clear} alt="img_clear" />
                                Limpiar
                            </button>
                        </div>
                    </div>
                    <div className="div-3-sesiones">
                        <div className="div_DisplayPx_sesion">
                            <table id="table-DisplayPx-sesion">
                                <tbody>
                                    {DisplayPx}
                                </tbody>
                            </table>
                        </div>

                        <div className="div-desplegable-sesion">
                            {informacion.length === 0 ? (
                                <div className="faq-container">
                                    <details open>
                                        <summary> Sesiones </summary>
                                    </details>
                                    <div className="div-selecPx-sesion">
                                        <label id="label-selecPx-sesion"> Selecciona un paciente de la lista... </label>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="div-p-nombre-cita">
                                        <table className="table-head-px-cita">
                                            <tr>
                                                <td className="td-avatar-Exp" rowSpan={3}>
                                                    <div className="div-avatar-Exp">
                                                        {
                                                            sinFoto === "1" ? (
                                                                fotografia === "" ? (
                                                                    <div id="img_avatar_Exp">
                                                                        <label> Cargando imagen...</label>
                                                                    </div>
                                                                ) : (
                                                                    <div id="img_avatar_Exp">
                                                                        <img src={fotografia} alt="base64"></img>
                                                                    </div>
                                                                )
                                                            ) : (<div></div>)
                                                        }
                                                    </div>
                                                </td>
                                                <td id="td-paciente-citas" colSpan={4}>
                                                    {/* local 
                                                    <a href={`/pacientes/${informacion.id_paciente}`}>
                                                        <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                    </a>
                                                    */}

                                                    {/* web */}
                                                    <a href={`#/pacientes/${informacion.id_paciente}`}>
                                                        <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                    </a>
                                                    
                                                </td>
                                                <td id="td-menuHamb-citas">
                                                    <div id="div-menuHamb">
                                                        <nav className="nav-menuHamb">
                                                            <input type="checkbox" id="in-menuHamb"></input>
                                                            <label id="label-menuHamb" for="in-menuHamb"> ☰ </label>
                                                            <ul id="ul-menuHamb">
                                                                    {/*     entorno local
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                        </li>
                                                                        */}

                                                                    {/*     entorno web     */}
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                    </li>
                                                                </ul>
                                                        </nav>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr id="encabezado_px_citas">
                                                <th>Edad</th>
                                                <th>Trabajo actual</th>
                                                <th>Edo. civil</th>
                                                <th>Ciudad</th>
                                                <th>Tel</th>
                                            </tr>
                                            <tr id="filas_px_citas">
                                                <td>
                                                    <label id="lbl-edad-citas">{informacion.edad}</label>
                                                </td>
                                                <td>
                                                    <label id="lbl-trabajo-citas">{informacion.trabajo_actual}</label>
                                                </td>
                                                <td>
                                                    <label id="lbl-edo-civil-citas">{informacion.edo_civil}</label>
                                                </td>
                                                <td>
                                                    <label id="lbl-ciudad-citas">{informacion.ciudad}</label>
                                                </td>
                                                <td>
                                                    <label id="lbl-tel-citas">{informacion.tel}</label>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="faq-container">
                                        <details open>
                                            <summary> Sesiones </summary>
                                            <div className="summary-sesiones-parrafo">
                                                {DisplaySesiones}
                                            </div>
                                        </details>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Sesiones;