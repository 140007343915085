
import React from "react";


const Content_login = ({ children }) => {
  
    return (
    <div className="content-login">
        { children }
    </div>
    );
};


export default Content_login;