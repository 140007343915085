
import axios from "axios";

const ADMIN_API_BASE_URL = "/server/admin";

// const ADMIN_API_BASE_URL = "http://localhost:3002/admin";


class AdminService {

    async getAdmin (id_usuario) {
        const response = await axios.get(`${ADMIN_API_BASE_URL} ? id_usuario = ${id_usuario}`)
            .then( response => {
                return response;
            })
            .catch( error => {
                console.error('error al recibir datos del usuario admin' , error);
            })

            console.log(response);
            const admin = response.data;
            return admin;
    }

}

export default new AdminService;