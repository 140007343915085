
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";

import img_search from "../../img/search_pax.svg";
import img_send from "../../img/send.svg";
import img_avatar from "../../img/avatar.png";
import img_clear from "../../img/clear_all.svg";


const Notificaciones = (id) => {

    const [pacientes, setPacientes] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [selectedValue, setSelectedValue] = useState(""); // Estado para el valor del select
    const [inputValue, setInputValue] = useState("");

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    function refreshPage() {
        window.location.reload(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    const navigate = useNavigate();

    const getData = async () => {
        if (routeParams.id !== undefined) {
            cargarInformacion(routeParams.id);
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        }
    };


    useEffect(() => {
        getData();
    }, []);


    const cargarInformacion = async (id_paciente) => {
        const data = await PacientesService.getInfoPaciente(id_paciente);
        if (data[0].sexo === "m" || data[0].sexo === "M") {
            data[0].sexo = "Masculino"
        } else if (data[0].sexo === "f" || data[0].sexo === "F") {
            data[0].sexo = "Femenino"
        }
        if (data[0].edo_civil === "s" || data[0].edo_civil === "S") {
            data[0].edo_civil = "Soltero"
        } else if (data[0].edo_civil === "c" || data[0].edo_civil === "C") {
            data[0].edo_civil = "Casado"
        } else if (data[0].edo_civil === "d" || data[0].edo_civil === "D") {
            data[0].edo_civil = "Divorciado"
        } else if (data[0].edo_civil === "v" || data[0].edo_civil === "V") {
            data[0].edo_civil = "Viudo"
        }
        setInformacion(data[0]);
        console.log(data[0]);
    };

    const DisplayData = pacientes.map((info) => {
        return (
            <tr>
                <td className="td-border-bottom">
                    <button onClick={() => cargarInformacion(info.id_paciente)} id="liga_px">
                        {info.nombre} {info.ap1} {info.ap2}
                    </button>
                </td>
            </tr>
        )
    });


    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value);
        console.log(data);
        setPacientes(data);
    };


    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes();
            console.log(data);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value);
            console.log(data);
            setPacientes(data);
        }
    };


    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes();
        console.log(data);
        setPacientes(data);
    };


    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }


    return (
        <Layout>
            <Content>
                <div className="div-1-expedientes">
                    <div className="px_div_2_citas">
                        <div className="px_sesion_citas">
                            <label id="lbl-enSesion">{tipoUsuario()} {usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_citas">
                            <label>Notificaciones</label>
                        </div>
                    </div>

                    <div className="px_buscador_citas">

                        <div className="div_No_exp_buscar_px">
                            <div className="input_buscar_cita">
                                <input type="search" placeholder="Buscar paciente..." id="Buscador" value={inputValue} onChange={buscar} />
                                <img src={img_search} alt="buscar" id="img_lupa"></img>
                            </div>
                        </div>

                        <div className="px_filtro_cita">
                            <select id="px_select_cita" value={selectedValue} onChange={buscarFiltro}>
                                <option value="" disabled selected >Selecciona un filtro</option>
                                <option value="A">Activos</option>
                                <option value="I">Inactivos</option>
                            </select>
                            <button id="btn-limpiar-filtro" onClick={limpiarFiltro}>
                                <img src={img_clear} />
                                Limpiar filtro
                            </button>
                        </div>

                    </div>

                    <div className="px_div_3_citas">

                        <div className="div-buscador-cita">

                            <div className="div_tablas_th_px-citas">
                                <table id="filas_px-citas">
                                    {DisplayData}
                                </table>
                            </div>
                        </div>

                        <div className="div-3-expediente-menu">

                            {informacion.length === 0 ? (

                                <div className="faq-container">
                                    <details open>

                                        <summary> Historial de notificaciones </summary>

                                    </details>
                                    <div className="div-label-selec-cita">
                                        <p> Selecciona un paciente de la lista </p>
                                    </div>
                                </div>

                            ) : (
                                <div>
                                    <div className="div-p-nombre-cita">
                                        <table className="table-head-px-cita">
                                            <tr>
                                                <td className="td-avatar-citas" rowSpan={3}>
                                                    <label className="lbl-avatar-citas">
                                                        <img src={img_avatar} alt="avatar" id="img_avatar" />
                                                    </label>
                                                </td>
                                                <td id="td-paciente-citas" colSpan={4}>
                                                    {/*    local
                                                        <a href={`/pacientes/${informacion.id_paciente}`}>
                                                            <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                        </a>
                                                    */}

                                                    {/* web */}
                                                    <a href={`#/pacientes/${informacion.id_paciente}`}>
                                                        <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                    </a>
                                                </td>
                                                <td id="td-menuHamb-citas">
                                                    <div id="div-menuHamb">
                                                        <nav className="nav-menuHamb">
                                                            <input type="checkbox" id="in-menuHamb"></input>
                                                            <label id="label-menuHamb" for="in-menuHamb"> ☰ </label>
                                                            <ul id="ul-menuHamb">
                                                                    {/*     entorno local
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                        </li>
                                                                        */}

                                                                    {/*     entorno web     */}
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                    </li>
                                                                </ul>
                                                        </nav>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr id="encabezado_px_citas">
                                                <th>Edad</th>
                                                <th>Trabajo actual</th>
                                                <th>Edo. civil</th>
                                                <th>Ciudad</th>
                                                <th>Tel</th>
                                            </tr>
                                            <tr id="filas_px_citas">
                                                <td>
                                                    <label id="lbl-edad-citas">{informacion.edad}</label>
                                                </td>
                                                <td>
                                                    <label id="lbl-trabajo-citas">{informacion.trabajo_actual}</label>
                                                </td>
                                                <td>
                                                    <label id="lbl-edo-civil-citas">{informacion.edo_civil}</label>
                                                </td>
                                                <td>
                                                    <label id="lbl-ciudad-citas">{informacion.ciudad}</label>
                                                </td>
                                                <td>
                                                    <label id="lbl-tel-citas">{informacion.tel}</label>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="faq-container">
                                        <details open>
                                            <div className="summary-notificaciones">
                                                <div className="summary-mns-textarea">
                                                    <p>
                                                        <textarea id="textarea-notificaciones" placeholder="Mensaje..." rows="2" cols="50"></textarea>
                                                    </p>
                                                </div>
                                                <div className="summary-mns-img">
                                                    <img src={img_send}></img>
                                                </div>
                                            </div>

                                            <summary> Historial de notificaciones </summary>

                                            <p>
                                                <div className="div-notif-psi">
                                                    <p> Su próxima citas es:   ---- 10:30 am  ----  2 Abril, 2024 </p>
                                                </div>

                                                <div className="div-notif-px">
                                                    <p className="p-notif-px">
                                                        <p id="p-notif-msn"> Visto </p>
                                                    </p>
                                                </div>
                                            </p>
                                            <p>
                                                <div className="div-notif-psi">
                                                    <p> Su próxima citas es:   ---- 12:30 am  ----  24 Marzo, 2024 </p>
                                                </div>

                                                <div className="div-notif-px">
                                                    <p className="p-notif-px">
                                                        <p id="p-notif-msn"> Enterado. Sí asistiré. Gracias. </p>
                                                    </p>
                                                </div>
                                            </p>
                                            <p>
                                                <div className="div-notif-psi">
                                                    <p> Su próxima citas es:   ---- 11:30 am  ----  2 Marzo, 2024  </p>
                                                </div>

                                                <div className="div-notif-px">
                                                    <p className="p-notif-px">
                                                        <p id="p-notif-msn"> Visto </p>
                                                    </p>
                                                </div>
                                            </p>
                                        </details>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Notificaciones;