
import axios from "axios";

const PARENTESCO_API_BASE_URL = "/server/parentesco";
const EDIT_PARENT_API_BASE_URL = "/server/editParentesco";
const VPARENTESCO_API_BASE_URL = "/server/vparentesco";
const FAMILIARES_API_BASE_URL = "/server/familiares";
const FAM_EDIT_API_BASE_URL = "/server/familiarEdit";


// const PARENTESCO_API_BASE_URL = "http://localhost:3002/parentesco";
// const EDIT_PARENT_API_BASE_URL = "http://localhost:3002/editParentesco";
// const VPARENTESCO_API_BASE_URL = "http://localhost:3002/vparentesco";
// const FAMILIARES_API_BASE_URL = "http://localhost:3002/familiares";
// const FAM_EDIT_API_BASE_URL = "http://localhost:3002/familiarEdit";


const Buffer = require('buffer/').Buffer;


class FamiliaresService {

    async getFamiliarIndividual(id_familiar) {
        const response = await axios.get(`${FAMILIARES_API_BASE_URL}individual?id_familiar=${id_familiar}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliar familiaresindividual: ', error);
            });

        const familiar = response.data;

        //  convertir imagen blob a Uint8Array
        const archivoBase64 = Buffer.from(familiar[0].fotografia.data, 'binary');

        if (familiar[0].formatoFoto === "png") {
            // Uint8Array a String binario tipo PNG
            const base64String = `data:image/png;base64,${archivoBase64}`;
            // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
            const base64Data = base64String.split(',')[2];
            //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
            const imgBlob = `data:image/png;base64,${base64Data}`;
            familiar[0].fotografia.data = imgBlob;
        } else if (familiar[0].formatoFoto === "jpeg") {
            const base64String = `data:image/jpeg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
            const imgBlob = `data:image/jpeg;base64,${base64Data}`;
            familiar[0].fotografia.data = imgBlob;
        } else if (familiar[0].formatoFoto === "jpg") {
            const base64String = `data:image/jpg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];
            const imgBlob = `data:image/jpg;base64,${base64Data}`;
            familiar[0].fotografia.data = imgBlob;
        }

        return familiar;

    }

    // get Tabla familiar
    async getFamiliares() {
        const response = await axios.get(FAMILIARES_API_BASE_URL)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliares: ', error);
            });
        const familiar = response.data;
        return familiar;
    }

    // get Tabla familiar Buscador
    async getFamiliarBuscador(palabra) {
        const response = await axios.get(`${FAMILIARES_API_BASE_URL}buscador?palabra=${palabra}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliaresBuscador: ', error);
            });
        const familiar = response.data;
        return familiar;
    }

    async getFamiliarP(id_paciente_familiar) {
        const response = await axios.get(`${VPARENTESCO_API_BASE_URL}Individual?id_paciente_familiar=${id_paciente_familiar}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliarP vparentesco: ', error);
            });
        const familiar = response.data;

        //  convertir imagen blob a Uint8Array
        const archivoBase64 = Buffer.from(familiar[0].pf_fotografia.data, 'binary');

        if (familiar[0].pf_formatoFoto === "png") {
            // Uint8Array a String binario tipo PNG
            const base64String = `data:image/png;base64,${archivoBase64}`;
            // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
            const base64Data = base64String.split(',')[2];
            //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
            const imgBlob = `data:image/png;base64,${base64Data}`;
            familiar[0].pf_fotografia.data = imgBlob;
        } else if (familiar[0].pf_formatoFoto === "jpeg") {
            const base64String = `data:image/jpeg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
            const imgBlob = `data:image/jpeg;base64,${base64Data}`;
            familiar[0].pf_fotografia.data = imgBlob;
        } else if (familiar[0].pf_formatoFoto === "jpg") {
            const base64String = `data:image/jpg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];
            const imgBlob = `data:image/jpg;base64,${base64Data}`;
            familiar[0].pf_fotografia.data = imgBlob;
        }

        return familiar;
    }


    async getFamDelPaciente(id_paciente) {
        const response = await axios.get(`${FAMILIARES_API_BASE_URL}delpaciente?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamDelPaciente: ', error);
            });
        const famDelPac = response.data;
        return famDelPac;
    }

    async getFamiliarEsPaciente(id_paciente) {
        const response = await axios.get(`${VPARENTESCO_API_BASE_URL}?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamDelPaciente: Error al obtener los fam del paciente:', error);
            });
        const famDelPac = response.data;
        return famDelPac;
    }


    async createFamiliares(familiares) {
        return await axios.post(FAMILIARES_API_BASE_URL, familiares);
    }


    async EditarFamiliar(familiar) {
        const response = await axios.put(FAM_EDIT_API_BASE_URL, familiar)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('EditarFamiliar FS: ', error);
            });
        const fam = response.data;
        return fam;
    }


    async createParentesco(parentesco) {
        return await axios.post(PARENTESCO_API_BASE_URL, parentesco);
    }


    // Editar parentesco
    async editarParentesco(parentesco) {
        const response = await axios.put(EDIT_PARENT_API_BASE_URL, parentesco)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('editarParentesco FS: ', error);
            });
        const fam = response.data;
        return fam;
    }


}


export default new FamiliaresService();


