// FormularioCita.js
import React from "react";
import "../../styles/FormularioEditarCita.css";

const FormularioEditarCita = ({ pacientes, fechaF, horaI, horaF, comentario, pac, estatus }) => {
  return (
    <div className="div-editar-cita-modal">
      <table className="tabla-editar-cita-modal">
        <tr className="table-line-bottom">
          <td className="editar-cita-td-izq">
            <label className="lbl-px-editar" htmlFor="select-paciente"> Paciente: </label>
          </td>
          <td className="editar-cita-td-der">
            <select id="select-px-editar" disabled>
              <option className="selected-option" value={pac.id_paciente}>
                {pac.nombre} {pac.ap1} {pac.ap2}
              </option>
            </select>
          </td>
        </tr>
        <tr className="table-line-bottom">
          <td className="editar-cita-td-izq"><label className="lbl-fecha-editar" htmlFor="input-fecha"> Fecha: </label></td>
          <td className="editar-cita-td-der"><input type="date" id="input-fecha-editar" defaultValue={fechaF} /></td>
        </tr>
        <tr className="table-line-bottom">
          <td className="editar-cita-td-izq"><label className="lbl-horaI-editar" htmlFor="input-horaI"> Hora Inicio: </label></td>
          <td className="editar-cita-td-der"><input type="time" id="input-horaI-editar" defaultValue={horaI} /></td>
        </tr>
        <tr className="table-line-bottom">
          <td className="editar-cita-td-izq"><label className="lbl-horaF-editar" htmlFor="input-horaF"> Hora Final: </label></td>
          <td className="editar-cita-td-der"><input type="time" id="input-horaF-editar" defaultValue={horaF} /></td>
        </tr>
        <tr className="table-line-bottom">
          <td className="editar-cita-td-izq"><label className="lbl-comentario-editar" htmlFor="input-comentario"> Comentario: </label></td>
          <td className="editar-cita-td-der"><input type="text" placeholder="Deja un comentario..." id="input-comentario-editar" defaultValue={comentario} /></td>
        </tr>
      </table>
    </div>
  );
};

export default FormularioEditarCita;
