
import axios from "axios";

const PACIENTE_API_BASE_URL = "/server/paciente";
const EDIT_PACIENTE_API_BASE_URL = "/server/pacientesEditar";
const VPACIENTE_API_BASE_URL = "/server/vpacientes";
const MARCAR_PACIENTE_API_BASE_URL = "/server/pacientesEditarmarcar";


// const PACIENTE_API_BASE_URL = "http://localhost:3002/paciente";
// const EDIT_PACIENTE_API_BASE_URL = "http://localhost:3002/pacientesEditar";
// const VPACIENTE_API_BASE_URL = "http://localhost:3002/vpacientes";
// const MARCAR_PACIENTE_API_BASE_URL = "http://localhost:3002/pacientesEditarmarcar";


const Buffer = require('buffer/').Buffer;

class PacienteService {

    async getPaciente(id_usuario) {
        const response = await axios.get(`${PACIENTE_API_BASE_URL}?id_usuario=${id_usuario}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('PS getPaciente: ', error);
            });
        const paciente = response.data;
        return paciente;
    }

    async getPacienteNomUsuario(nombreUsuario) {
        const response = await axios.get(`${VPACIENTE_API_BASE_URL}NomUsuario?nom_usuario=${nombreUsuario}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error al obtener los pacientes:', error);
            });
        const paciente = response.data;
        return paciente;
    }

    async getInfoPaciente(id_paciente) {
        const response = await axios.get(`${VPACIENTE_API_BASE_URL}info?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error al obtener los pacientes:', error);
            });
        const paciente = response.data;
        
        //  convertir imagen blob a Uint8Array
        const archivoBase64 = Buffer.from(paciente[0].fotografia.data, 'binary');

        if (paciente[0].formatoFoto === "png") {
            // Uint8Array a String binario tipo PNG
            const base64String = `data:image/png;base64,${archivoBase64}`;
            // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
            const base64Data = base64String.split(',')[2];
            //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
            const imgBlob = `data:image/png;base64,${base64Data}`;
            paciente[0].fotografia.data = imgBlob;
        } else if(paciente[0].formatoFoto === "jpeg") {
            const base64String = `data:image/jpeg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
            const imgBlob = `data:image/jpeg;base64,${base64Data}`;
            paciente[0].fotografia.data = imgBlob;
        } else if(paciente[0].formatoFoto === "jpg") {
            const base64String = `data:image/jpg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];
            const imgBlob = `data:image/jpg;base64,${base64Data}`;
            paciente[0].fotografia.data = imgBlob;
        }
        
        return paciente;
    }


    // solo pacientes activos
    async getPacientesActivos() {
        const response = await axios.get(`${VPACIENTE_API_BASE_URL}activos?`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('PS getPacientesActivos: ', error);
            });
        const pac = response.data;
        return pac;
    }


    // todos los pacientes
    async getPacientes(id_psicologo) {
        const response = await axios.get(`${VPACIENTE_API_BASE_URL}?id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('PS getPacientes: ', error);
            });
        const pac = response.data;
        return pac;
    }


    async getPacientesCalendario() {
        const response = await axios.get(VPACIENTE_API_BASE_URL + 'calendario')
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error al obtener los pacientes:', error);
            });
        const pac = response.data;
        return pac;
    }

    /* {VPACIENTE_API_BASE_URL}buscador?palabra 
        buscador, complementa la ruta que está en el index del service: "/vpacientesbuscador"
        ? separa la ruta de los parámetros que recibe
    */
    async getPacientesBuscador(palabra) {
        const response = await axios.get(`${VPACIENTE_API_BASE_URL}buscador?palabra=${palabra}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error al obtener los pacientes:', error);
            });
        const pac = response.data;
        return pac;
    }

    async getPacientesEstatus(estatus) {
        const response = await axios.get(`${VPACIENTE_API_BASE_URL}estatus?estatus=${estatus}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error al obtener los pacientes:', error);
            });
        const pac = response.data;
        return pac;
    }


    // marcar paciente canalizado
    async putMarcarPacCanalizar(marcar) {
        const response = await axios.put(MARCAR_PACIENTE_API_BASE_URL, marcar)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('PS putMarcarPacCanalizar: ', error);
            })
        const marcarPaciente = response.data;
        return marcarPaciente;
    }



    eliminarPacienteL(id_paciente) {
        return axios.put(`${PACIENTE_API_BASE_URL}EliminacionL?id_paciente=${id_paciente}`);
    }


    // crear paciente
    async createPaciente(paciente) {
        const response = await axios.post(PACIENTE_API_BASE_URL, paciente)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('PS createPaciente: ', error);
            });
        const pac = response.data;
        return pac;
    }


    async editarPaciente(paciente) {
        const response = await axios.put(EDIT_PACIENTE_API_BASE_URL, paciente)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error al obtener los datos de edición del pacientes: ', error);
            })
        const editPaciente = response.data;

        return editPaciente;
    }

}

export default new PacienteService();