
import React from "react";
import Header_login from "../components/Header_login";
import Content_login from "../components/Content_login";


const Layout_login = ({ children }) => {

  return (
    <div className="Layout-login">
      <Header_login />
      <Content_login>{children}</Content_login>
    </div>
  );
};

export default Layout_login;